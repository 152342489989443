/** 默认主题相关配置 */
export const DEFAULT_THEME = {
  'Name': 'sample string 1',
  'SelectedColor': '#f0182a', // 选中颜色 主题色
  'IndexMenu': { // 底部首页菜单配置
    'IconUrl': require('@/assets/image/tabbar/home@2x.svg'),
    'SelectedIconUrl': require('@/assets/image/tabbar/home_red@2x.svg')
  },
  'ManageMenu': { // 底部管理菜单配置
    'IconUrl': require('@/assets/image/tabbar/manage@2x.svg'),
    'SelectedIconUrl': require('@/assets/image/tabbar/manage_red@2x.svg')
  },
  'CustomerServiceMenu': { // 底部客服菜单配置
    'IconUrl': require('@/assets/image/tabbar/customerService@2x.png'),
    'SelectedIconUrl': require('@/assets/image/tabbar/customerService_red@2x.png')
  },
  'MyHomsomMenu': { // 底部我的恒顺菜单配置
    'IconUrl': require('@/assets/image/tabbar/user@2x.svg'),
    'SelectedIconUrl': require('@/assets/image/tabbar/user_red@2x.svg')
  },
  'ScheduleMenu': { // 底部行程菜单配置
    'IconUrl': require('@/assets/image/tabbar/trip.svg'),
    'SelectedIconUrl': require('@/assets/image/tabbar/trip_active.svg')
  },
  'MessageServiceMenu': { // 底部消息菜单配置
    'IconUrl': require('@/assets/image/tabbar/message.svg'),
    'SelectedIconUrl': require('@/assets/image/tabbar/message_red.svg')
  },
  'FlightMoudleIconUrl': require('@/assets/image/tabbar/plane@2x.png'), // 机票模块图标地址
  'HotelMoudleIconUrl': require('@/assets/image/tabbar/hotel@2x.png'), // 酒店模块图标地址
  'TrainMoudleIconUrl': require('@/assets/image/tabbar/train@2x.png'), // 火车票模块图标地址
  'CarMoudleIconUrl': require('@/assets/image/tabbar/car.png'), // 用车模块图标地址
  'MealMoudleIconUrl': require('@/assets/image/tabbar/dinner.png'), // 用餐模块图标地址
  'BusMoudleIconUrl': require('@/assets/image/tabbar/bus.png'), // 汽车票模块图标地址
  'TripApplicationFormMoudleIconUrl': require('@/assets/image/tabbar/travelApply.png'), // 出差申请单模块图标地址
  'TravelConferenceMoudleIconUrl': require('@/assets/image/tabbar/travel.png'), // 旅游会务模块图标地址
  'IndexBackgroundImageUrl': require('@/assets/image/home/ask_bg.jpg'), // 首页背景图片地址
  'ManageBackgroundImageUrl': require('@/assets/image/management/bg.png'), // 管理背景图片地址
  'CustomerServiceBackgroundImageUrl': require('@/assets/image/our-service/bg.png'), // 客服页背景图片地址
  'MyHomsomBackgroundColorImageUrl': require('@/assets/image/personal-center/person_bg.png'), // MyHomsomBackgroundColorImageUrl

  /******************************* 最新icon ****************************************/
  'FlightNewMoudleIconUrl': require('@/assets/image/home/icons_40pt_Air_tickets@3x.png'), // 机票模块图标地址
  'HotelNewMoudleIconUrl': require('@/assets/image/home/icons_40pt_hotel@3x.png'), // 酒店模块图标地址
  'TrainNewMoudleIconUrl': require('@/assets/image/home/icons_40pt_train_ticket@3x.png'), // 火车票模块图标地址
  'CarNewMoudleIconUrl': require('@/assets/image/home/icons_40pt_car@3x.png'), // 用车模块图标地址
}
