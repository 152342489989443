// 承載router-view的components
const Start = resolve => {
  import('components/start/start').then(module => {
    resolve(module);
  });
};

export default {
  path: '/dinner',
  component: Start,
  children: [
    {
      path: 'bookDetails',
      name: '用餐订单填写',
      component: resolve =>
        require(['pages/dinner/book/book-details.vue'], resolve),
      meta: {
        keepAlive: true
      }
    },
    {
      path: 'orderConfirm',
      name: '用餐订单确认',
      component: resolve =>
        require(['pages/dinner/order/order-confirm/order-confirm.vue'], resolve)
    },
    {
      path: 'orderSuccess',
      name: '用餐预订成功页面',
      component: resolve =>
        require(['pages/dinner/order/order-success/order-success.vue'], resolve)
    },
    {
      name: 'dinnerOrderList',
      path: 'orderList',
      component: resolve =>
        require(['pages/dinner/order/order-list/order-list.vue'], resolve),
      meta: {
        keepAlive: true
      }
    },
    {
      path: 'orderDetails',
      name: 'dinnerOrderDetails',
      component: resolve =>
        require(['pages/dinner/order/order-details/order-details.vue'], resolve)
    }
  ]
}
