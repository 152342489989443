/**
 * 主题 配置
 * */
import store from '@/store'
import { DEFAULT_THEME } from '../constants/Theme'
export default function(key) {
  let Theme = store.state.Theme || {}
  /** home start */
  if (key === 'home:welcome:color') {
    return Theme.IndexMainTitleColor || ''
  } else if (key === 'home:welcome:sub-color') {
    return Theme.IndexSubTitleColor || ''
  } else if (key === 'home:welcome:bg') {
    return Theme.IndexWelcomePartBackgroundImageUrl || ''
  } else if (key === 'home:btn:bg') {
    return Theme.IndexSearchButtonBackgroundImageUrl || ''
  } else if (key === 'home:top:bg') {
    return Theme.IndexBackgroundImageUrl || DEFAULT_THEME.IndexBackgroundImageUrl
  } else if (key === 'home:enter:icon-plane') {
    return Theme.FlightNewMoudleIconUrl || DEFAULT_THEME.FlightNewMoudleIconUrl
  } else if (key === 'home:enter:icon-hotel') {
    return Theme.HotelNewMoudleIconUrl || DEFAULT_THEME.HotelNewMoudleIconUrl
  } else if (key === 'home:enter:icon-train') {
    return Theme.TrainNewMoudleIconUrl || DEFAULT_THEME.TrainNewMoudleIconUrl
  } else if (key === 'home:enter:icon-car') {
    return Theme.CarNewMoudleIconUrl || DEFAULT_THEME.CarNewMoudleIconUrl
  } else if (key === 'home:enter:icon-dinner') {
    return Theme.MealMoudleIconUrl || DEFAULT_THEME.MealMoudleIconUrl
  } else if (key === 'home:enter:icon-travel-apply') {
    return Theme.TripApplicationFormMoudleIconUrl || DEFAULT_THEME.TripApplicationFormMoudleIconUrl
  } else if (key === 'home:enter:icon-travel') {
    return Theme.TravelConferenceMoudleIconUrl || DEFAULT_THEME.TravelConferenceMoudleIconUrl
  } else if (key === 'home:enter:icon-bus') {
    return Theme.BusMoudleIconUrl || DEFAULT_THEME.BusMoudleIconUrl
    /**  ----------  */
  } else if (key === 'home:hs-tab:active-color') {
    return Theme.SelectedColor || DEFAULT_THEME.SelectedColor
    /**  ----------  */
  } else if (key === 'home:c-tabbar:icon-home') {
    return (Theme.IndexMenu ? Theme.IndexMenu.IconUrl : '') || DEFAULT_THEME.IndexMenu.IconUrl
  } else if (key === 'home:c-tabbar:icon-home-active') {
    return (Theme.IndexMenu ? Theme.IndexMenu.SelectedIconUrl : '') || DEFAULT_THEME.IndexMenu.SelectedIconUrl
  } else if (key === 'home:c-tabbar:icon-management') {
    return (Theme.ManageMenu ? Theme.ManageMenu.IconUrl : '') || DEFAULT_THEME.ManageMenu.IconUrl
  } else if (key === 'home:c-tabbar:icon-management-active') {
    return (Theme.ManageMenu ? Theme.ManageMenu.SelectedIconUrl : '') || DEFAULT_THEME.ManageMenu.SelectedIconUrl
  } else if (key === 'home:c-tabbar:icon-ourService') {
    return (Theme.CustomerServiceMenu ? Theme.CustomerServiceMenu.IconUrl : '') || DEFAULT_THEME.CustomerServiceMenu.IconUrl
  } else if (key === 'home:c-tabbar:icon-ourService-active') {
    return (Theme.CustomerServiceMenu ? Theme.CustomerServiceMenu.SelectedIconUrl : '') || DEFAULT_THEME.CustomerServiceMenu.SelectedIconUrl
  } else if (key === 'home:c-tabbar:icon-personal-center') {
    return (Theme.MyHomsomMenu ? Theme.MyHomsomMenu.IconUrl : '') || DEFAULT_THEME.MyHomsomMenu.IconUrl
  } else if (key === 'home:c-tabbar:icon-personal-center-active') {
    return (Theme.MyHomsomMenu ? Theme.MyHomsomMenu.SelectedIconUrl : '') || DEFAULT_THEME.MyHomsomMenu.SelectedIconUrl
  } else if (key === 'home:c-tabbar:icon-trip') {
    return (Theme.ScheduleMenu ? Theme.ScheduleMenu.IconUrl : '') || DEFAULT_THEME.ScheduleMenu.IconUrl
  } else if (key === 'home:c-tabbar:icon-trip-active') {
    return (Theme.ScheduleMenu ? Theme.ScheduleMenu.SelectedIconUrl : '') || DEFAULT_THEME.ScheduleMenu.SelectedIconUrl
  } else if (key === 'home:c-tabbar:icon-message') {
    return (Theme.ScheduleMenu ? Theme.MessageServiceMenu.IconUrl : '') || DEFAULT_THEME.MessageServiceMenu.IconUrl
  } else if (key === 'home:c-tabbar:icon-message-active') {
    return (Theme.ScheduleMenu ? Theme.MessageServiceMenu.SelectedIconUrl : '') || DEFAULT_THEME.MessageServiceMenu.SelectedIconUrl
  } else if (key === 'home:c-tabbar:active-color') {
    return Theme.SelectedColor || DEFAULT_THEME.SelectedColor
    /** home end */

    /** management start */
  } else if (key === 'management:top:bg') {
    return Theme.ManageBackgroundImageUrl || DEFAULT_THEME.ManageBackgroundImageUrl
    /** management end */

    /** ourService start */
  } else if (key === 'ourService:top:bg') {
    return Theme.CustomerServiceBackgroundImageUrl || DEFAULT_THEME.CustomerServiceBackgroundImageUrl
    /** ourService end */

    /** personal/center start */
  } else if (key === 'personal/center:top:bg') {
    return Theme.MyHomsomBackgroundColorImageUrl || DEFAULT_THEME.MyHomsomBackgroundColorImageUrl
    /** personal/center end */
  }
}
