<template>
  <div ref="wrapper" style="position: relative">
    <template v-if="scrollType === 'moreSlot'">
      <div ref="listWrapper">
        <slot name="default"></slot>
        <div class="hs-pullup-wrapper" v-if="pullUpLoad">
          <div class="before-trigger" v-if="!isPullUpLoad">
            <span>{{pullUpDirty ? $t('PullUpLoadMore') : $t('AllLoaded')}}</span>
          </div>
          <div class="after-trigger" v-else>
            <hs-loading></hs-loading>
          </div>
        </div>
      </div>
      <!--建议通过定位的方式实现-->
      <slot name="header"></slot>
    </template>
    <div v-else ref="listWrapper">
      <slot></slot>
      <div class="hs-pullup-wrapper" v-if="pullUpLoad">
        <div class="before-trigger" v-if="!isPullUpLoad">
          <span>{{pullUpDirty ? $t('PullUpLoadMore') : $t('AllLoaded')}}</span>
        </div>
        <div class="after-trigger" v-else>
          <hs-loading></hs-loading>
        </div>
      </div>
    </div>
    <div ref="pulldown" v-if="pullDownRefresh">
      <div class="hs-pulldown-wrapper" :style="pullDownStyle">
        <div class="before-trigger" v-show="beforePullDown">
          <hs-bubble :y="bubbleY"></hs-bubble>
        </div>
        <div class="after-trigger" v-show="!beforePullDown">
          <div v-show="isPullingDown" class="loading">
            <hs-loading></hs-loading>
          </div>
          <div v-show="!isPullingDown">
            <p class="refreshTxt">{{$t('RefreshSucceed')}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="text/ecmascript-6">
/*
 * 根据better-scroll封装的，类似于tableview的scroll组件。
 * 1.默认不开启下拉刷新，如要开启，在props.options传入
 * pullDownRefresh: {
 *   threshold: 50 // 顶部触发刷新需要下拉的距离
 * }
 * 如果开启了下拉加载功能，必须要绑定@pulling-down事件，并在事件内部触发this.$refs.[父页面refName].forceUpdate()完成刷新
 * 2.默认不开启上拉加载更多, 如要开启, 在props.options传入
 * pullUpLoad: {
 *   threshold: 50
 * }
 * 如果开启了上拉加载更多，必须要绑定@pulling-up事件，并在事件内部触发this.$refs.[父页面refName].forceUpdate()完成刷新
 * 3.要把数组data传入props.data 形如: :data="listdata"
 * 举例:
 * <hs-scroll
 *   ref="tableView"
 *   :data="list"
 *   class="datalist"
 *   :options="tableViewOptions"
 *   @pulling-down="_getData"
 *   @pulling-up="_getNextData">
 *   <ul>
 *     <li class="item" v-for="(item, index) in list" :key="index">{{index}}</li>
 *   </ul>
 * </hs-scroll>
 * tableViewOptions: {
 *   pullDownRefresh: {
 *     threshold: 50
 *   },
 *   pullUpLoad: {
 *     threshold: 50
 *   }
 * }
*/
import BScroll from 'better-scroll'
import HsBubble from 'base/hs-bubble/hs-bubble'
import HsLoading from 'base/hs-loading/hs-loading'
import {getRect} from 'common/js/dom'
const DEFAULT_OPTIONS = {
  observeDOM: true,
  click: true,
  tap: true,
  probeType: 3,
  scrollbar: false,
  pullDownRefresh: false,
  pullUpLoad: false
}
const DIRECTION_H = 'horizontal' // 横向
const DIRECTION_V = 'vertical' // 纵向

export default {
  props: {
    data: {
      type: Array,
      default() {
        return []
      }
    },
    options: {
      type: Object,
      default: function() {
        return {
          pullDownRefresh: false,
          pullUpLoad: false
        }
      }
    },
    direction: {
      type: String,
      default: DIRECTION_V
    },
    isListenScroll: {
      type: Boolean,
      default: false
    },
    scrollType: {
      type: String,
      default: ''
    }
  },
  components: {
    HsBubble,
    HsLoading
  },
  activated() {
    setTimeout(() => {
      this.forceUpdate(true)
    }, 20)
  },
  mounted() {
    this.$nextTick(() => {
      this._initScroll()
    })
  },
  beforeDestroy() {
    this.destroy()
  },
  data() {
    return {
      beforePullDown: true,
      isPullingDown: false,
      isPullUpLoad: false,
      pullUpDirty: true,
      pullDownStyle: '',
      pullDownHeight: 0,
      pullDownStop: 0,
      bubbleY: 0
    }
  },
  computed: {
    pullDownRefresh() {
      /** 根据scroll实例的options.pullDownRefresh得到组件内部的pullDownRefresh时候开启下拉刷新 */
      let res = this.options.pullDownRefresh
      if (!res) {
        return res
      }
      if (res === true) {
        res = {}
      }
      return Object.assign({stop: this.pullDownStop}, res)
    },
    pullUpLoad() {
      return this.options.pullUpLoad
    }
  },
  watch: {
    data() {
      setTimeout(() => {
        this.forceUpdate(true)
      }, 20)
    },
    pullDownRefresh: {
      handler(newVal, oldVal) {
        /** 监听组件内部是否开启下拉刷新, 如果false => true, 打开实例的下拉刷新功能并且将实例中的pullingDown和scroll事件绑定到组件内部 */
        if (newVal) {
          this.scroll.openPullDown(newVal)
          if (!oldVal) {
            this._onPullDownRefresh()
            this._calculateMinHeight()
          }
        }
        /** 如果true => false, 关闭实例中的下拉刷新功能,并且解除pullingDown和scroll事件绑定 */
        if (!newVal && oldVal) {
          this.scroll.closePullDown()
          this._offPullDownRefresh()
          this._calculateMinHeight()
        }
      }
    },
    pullUpLoad: {
      handler(newVal, oldVal) {
        if (!this.scroll) return
        if (newVal) {
          this.scroll.openPullUp(newVal)
          if (!oldVal) {
            this._onPullUpLoad()
            this._calculateMinHeight()
          }
        }
        if (!newVal && oldVal) {
          this.scroll.closePullUp()
          this._offPullUpLoad()
          this._calculateMinHeight()
        }
      },
      deep: true
    }
  },
  methods: {
    /** 第一步, 初始化一个scroll, 状态 = beforePullDown */
    _initScroll() {
      if (!this.$refs.wrapper) return
      this._calculateMinHeight()
      let options = Object.assign(DEFAULT_OPTIONS, this.options, {
        scrollY: this.direction === DIRECTION_V,
        scrollX: this.direction === DIRECTION_H
      })
      // console.log('options: ', options)
      if (!this.scroll) {
        this.scroll = new BScroll(this.$refs.wrapper, options)
      }
      if (this.isListenScroll) {
        this.scroll.on('scrollStart', () => {
          this.$emit('scrollStart')
        })
        this.scroll.on('scroll', (pos) => {
          this.$emit('scroll', pos)
        })
        this.scroll.on('touchEnd', (pos) => {
          this.$emit('touchEnd', pos)
        })
      }
      if (this.pullDownRefresh) {
        this._getPullDownEleHeight()
        this._onPullDownRefresh()
      }
      if (this.pullUpLoad) {
        this._onPullUpLoad()
      }
    },
    _getPullDownEleHeight() {
      /** 第二步2-1, 记录下拉前loading的高度, 初始化pullDownHeight的值 */
      const pulldown = this.$refs.pulldown.firstChild
      this.pullDownHeight = getRect(pulldown).height
      this.beforePullDown = false
      this.isPullingDown = true
      this.$nextTick(() => {
        /** 第二步2-2, 记录下拉过程中loading的高度, 初始化pullDownStop的值 */
        this.pullDownStop = getRect(pulldown).height
        this.beforePullDown = true
        this.isPullingDown = false
      })
    },
    _onPullDownRefresh() {
      this.scroll.on('pullingDown', this._pullDownHandle)
      this.scroll.on('scroll', this._pullDownScrollHandle)
    },
    _offPullDownRefresh() {
      this.scroll.off('pullingDown', this._pullDownHandle)
      this.scroll.off('scroll', this._pullDownScrollHandle)
    },
    _pullDownHandle() {
      /** 如果反复得拉, 旧的定时器已经存在, 就清除旧的定时器 */
      if (this.resetPullDownTimer) {
        clearTimeout(this.resetPullDownTimer)
      }
      /** 下拉事件顺序4-2 放手进入下拉进行中状态 */
      this.beforePullDown = false
      this.isPullingDown = true
      /** 下拉事件顺序4-4 $emit('pulling-down')去获取数据后强制刷新scroll */
      this.$emit('pulling-down')
    },
    _pullDownScrollHandle(pos) {
      if (this.beforePullDown) {
        /** 下拉事件顺序4-1 下拉前 */
        this.bubbleY = Math.max(0, pos.y - this.pullDownHeight)
        this.pullDownStyle = `top:${Math.min(pos.y - this.pullDownHeight, 0)}px`
      } else {
        /** 下拉事件顺序4-3 下拉中/刷新中 */
        this.bubbleY = 0
        this.pullDownStyle = `top:${Math.min(pos.y - this.pullDownStop, 0)}px`
      }
    },
    /** 下拉事件顺序4-4, 位于组件外部$emit('pulling-down')事件触发 */
    forceUpdate(dirty = false) {
      if (this.pullDownRefresh && this.isPullingDown) {
        this.isPullingDown = false // 关闭正在刷新状态
        /** 告知下拉刷新结束, 并定时回弹 */
        this._reboundPullDown(() => {
          this._afterPullDown(dirty)
        })
      } else if (this.pullUpLoad && this.isPullUpLoad) {
        this.isPullUpLoad = false
        this.scroll.finishPullUp()
        this.pullUpDirty = dirty
        dirty && this.refresh()
      } else {
        dirty && this.refresh()
      }
    },
    refresh() {
      setTimeout(() => {
        this._calculateMinHeight()
        this.scroll && this.scroll.refresh()
      }, 300)
    },
    _reboundPullDown(next) {
      /** 告诉scroll实例, 下拉刷新结束 */
      setTimeout(() => {
        this.scroll && this.scroll.finishPullDown()
        this.scroll && next()
      }, 600)
    },
    _afterPullDown(dirty) {
      /** 设置一个定时器, 在获取到数据以后, 短暂延迟恢复scroll的回弹位置 */
      this.resetPullDownTimer = setTimeout(() => {
        this.pullDownStyle = `top: -${this.pullDownHeight}px`
        this.beforePullDown = true // 恢复刷新前状态
        dirty && this.refresh()
      }, this.scroll.options.bounceTime)
    },
    /** 为了better-scroll正常显示, 内部的listWrapper要比wrapper高一点 */
    _calculateMinHeight() {
      if (this.$refs.listWrapper) {
        this.$refs.listWrapper.style.minHeight = this.pullDownRefresh || this.pullUpLoad ? `${getRect(this.$refs.wrapper).height + 1}px` : 0
      }
    },
    _onPullUpLoad() {
      this.scroll.on('pullingUp', this._pullUpHandle)
    },
    _offPullUpLoad() {
      this.scroll.off('pullingUp', this._pullUpHandle)
    },
    _pullUpHandle() {
      this.isPullUpLoad = true
      this.$emit('pulling-up')
    },
    /** 开放一些方法 */
    destroy() {
      this.scroll && this.scroll.destroy()
      this.scroll = null
    },
    scrollTo() {
      this.scroll && this.scroll.scrollTo.apply(this.scroll, arguments)
    },
    scrollToElement() {
      this.scroll && this.scroll.scrollToElement.apply(this.scroll, arguments)
    }
  }
}
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
@import '~common/stylus/base'
@import '~common/stylus/variable'
.hs-pulldown-wrapper
  @extend .flex-center
  position absolute
  width 100%
  left 0
  transition all
  .before-trigger
    height 54px
    line-height 0
    padding-top 6px
    pointer-events none
    >canvas
      pointer-events none
  .after-trigger
    .loading
      padding 8px 0
    .refreshTxt
      font-size 0.8rem
      padding 12px 0
.hs-pullup-wrapper
  @extend .flex-center
  width 100%
  .before-trigger
    padding 12px 0
    min-height 1em
    font-size 0.8rem
  .after-trigger
    padding 9px 0
</style>
