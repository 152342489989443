
const getVal = (p, keys) => {
  return keys.reduce((xs, x) => xs && (xs[x] || xs[x] === 0 || xs[x] === '0') ? xs[x] : false, p)
}

export default (PersonalSetting, key) => {
  switch (key) {
    /** ********** 国内机票 正常订单 扩展字段 start **************/
    case '国内机票配置:正常订单:扩展字段1':
      return getVal(PersonalSetting, ['FlightSettings', 'NormalSettings', 'ExtendFieldSettings1'])
    case '国内机票配置:正常订单:扩展字段2':
      return getVal(PersonalSetting, ['FlightSettings', 'NormalSettings', 'ExtendFieldSettings2'])
    case '国内机票配置:正常订单:扩展字段3':
      return getVal(PersonalSetting, ['FlightSettings', 'NormalSettings', 'ExtendFieldSettings3'])
    case '国内机票配置:正常订单:扩展字段4':
      return getVal(PersonalSetting, ['FlightSettings', 'NormalSettings', 'ExtendFieldSettings4'])
    case '国内机票配置:正常订单:扩展字段5':
      return getVal(PersonalSetting, ['FlightSettings', 'NormalSettings', 'ExtendFieldSettings5'])
    /** ********** 国内机票 正常订单 扩展字段 end **************/

    /** ********** 国内机票 改期订单 扩展字段 start **************/
    case '国内机票配置:改期订单:扩展字段1':
      return getVal(PersonalSetting, ['FlightSettings', 'ChangeSettings', 'ExtendFieldSettings1'])
    case '国内机票配置:改期订单:扩展字段2':
      return getVal(PersonalSetting, ['FlightSettings', 'ChangeSettings', 'ExtendFieldSettings2'])
    case '国内机票配置:改期订单:扩展字段3':
      return getVal(PersonalSetting, ['FlightSettings', 'ChangeSettings', 'ExtendFieldSettings3'])
    case '国内机票配置:改期订单:扩展字段4':
      return getVal(PersonalSetting, ['FlightSettings', 'ChangeSettings', 'ExtendFieldSettings4'])
    case '国内机票配置:改期订单:扩展字段5':
      return getVal(PersonalSetting, ['FlightSettings', 'ChangeSettings', 'ExtendFieldSettings5'])
    /** ********** 国内机票 改期订单 扩展字段 start **************/

    /** ********** 国内机票 退票订单 扩展字段 start **************/
    case '国内机票配置:退票订单:扩展字段1':
      return getVal(PersonalSetting, ['FlightSettings', 'RefundSettings', 'ExtendFieldSettings1'])
    case '国内机票配置:退票订单:扩展字段2':
      return getVal(PersonalSetting, ['FlightSettings', 'RefundSettings', 'ExtendFieldSettings2'])
    case '国内机票配置:退票订单:扩展字段3':
      return getVal(PersonalSetting, ['FlightSettings', 'RefundSettings', 'ExtendFieldSettings3'])
    case '国内机票配置:退票订单:扩展字段4':
      return getVal(PersonalSetting, ['FlightSettings', 'RefundSettings', 'ExtendFieldSettings4'])
    case '国内机票配置:退票订单:扩展字段5':
      return getVal(PersonalSetting, ['FlightSettings', 'RefundSettings', 'ExtendFieldSettings5'])
    /** ********** 国内机票 退票订单 扩展字段 start **************/

    /** ************************************************ 国际机票 start *******************************************************************/
    /** ********** 国内机票 正常订单 扩展字段 start **************/
    case '国际机票配置:正常订单:扩展字段1':
      return getVal(PersonalSetting, ['IntlFlightSettings', 'NormalSettings', 'ExtendFieldSettings1'])
    case '国际机票配置:正常订单:扩展字段2':
      return getVal(PersonalSetting, ['IntlFlightSettings', 'NormalSettings', 'ExtendFieldSettings2'])
    case '国际机票配置:正常订单:扩展字段3':
      return getVal(PersonalSetting, ['IntlFlightSettings', 'NormalSettings', 'ExtendFieldSettings3'])
    case '国际机票配置:正常订单:扩展字段4':
      return getVal(PersonalSetting, ['IntlFlightSettings', 'NormalSettings', 'ExtendFieldSettings4'])
    case '国际机票配置:正常订单:扩展字段5':
      return getVal(PersonalSetting, ['IntlFlightSettings', 'NormalSettings', 'ExtendFieldSettings5'])
    /** ********** 国内机票 正常订单 扩展字段 end **************/

    /** ********** 国内机票 改期订单 扩展字段 start **************/
    case '国际机票配置:改期订单:扩展字段1':
      return getVal(PersonalSetting, ['IntlFlightSettings', 'ChangeSettings', 'ExtendFieldSettings1'])
    case '国际机票配置:改期订单:扩展字段2':
      return getVal(PersonalSetting, ['IntlFlightSettings', 'ChangeSettings', 'ExtendFieldSettings2'])
    case '国际机票配置:改期订单:扩展字段3':
      return getVal(PersonalSetting, ['IntlFlightSettings', 'ChangeSettings', 'ExtendFieldSettings3'])
    case '国际机票配置:改期订单:扩展字段4':
      return getVal(PersonalSetting, ['IntlFlightSettings', 'ChangeSettings', 'ExtendFieldSettings4'])
    case '国际机票配置:改期订单:扩展字段5':
      return getVal(PersonalSetting, ['IntlFlightSettings', 'ChangeSettings', 'ExtendFieldSettings5'])
    /** ********** 国内机票 改期订单 扩展字段 start **************/

    /** ********** 国内机票 退票订单 扩展字段 start **************/
    case '国际机票配置:退票订单:扩展字段1':
      return getVal(PersonalSetting, ['IntlFlightSettings', 'RefundSettings', 'ExtendFieldSettings1'])
    case '国际机票配置:退票订单:扩展字段2':
      return getVal(PersonalSetting, ['IntlFlightSettings', 'RefundSettings', 'ExtendFieldSettings2'])
    case '国际机票配置:退票订单:扩展字段3':
      return getVal(PersonalSetting, ['IntlFlightSettings', 'RefundSettings', 'ExtendFieldSettings3'])
    case '国际机票配置:退票订单:扩展字段4':
      return getVal(PersonalSetting, ['IntlFlightSettings', 'RefundSettings', 'ExtendFieldSettings4'])
    case '国际机票配置:退票订单:扩展字段5':
      return getVal(PersonalSetting, ['IntlFlightSettings', 'RefundSettings', 'ExtendFieldSettings5'])
    /** ********** 国内机票 退票订单 扩展字段 start **************/
    /** ************************************************ 国际机票 start *******************************************************************/

    /** ************************************************** 国内酒店 start ***************************************************************************/
    case '国内酒店配置:扩展字段1':
      return getVal(PersonalSetting, ['HotelSettings', 'ExtendFieldSettings1'])
    case '国内酒店配置:扩展字段2':
      return getVal(PersonalSetting, ['HotelSettings', 'ExtendFieldSettings2'])
    case '国内酒店配置:扩展字段3':
      return getVal(PersonalSetting, ['HotelSettings', 'ExtendFieldSettings3'])
    case '国内酒店配置:扩展字段4':
      return getVal(PersonalSetting, ['HotelSettings', 'ExtendFieldSettings4'])
    case '国内酒店配置:扩展字段5':
      return getVal(PersonalSetting, ['HotelSettings', 'ExtendFieldSettings5'])
    /** ************************************************** 国内酒店 end ***************************************************************************/

    /** ************************************************** 国际酒店 start ***************************************************************************/
    case '国际酒店配置:扩展字段1':
      return getVal(PersonalSetting, ['IntlHotelSettings', 'ExtendFieldSettings1'])
    case '国际酒店配置:扩展字段2':
      return getVal(PersonalSetting, ['IntlHotelSettings', 'ExtendFieldSettings2'])
    case '国际酒店配置:扩展字段3':
      return getVal(PersonalSetting, ['IntlHotelSettings', 'ExtendFieldSettings3'])
    case '国际酒店配置:扩展字段4':
      return getVal(PersonalSetting, ['IntlHotelSettings', 'ExtendFieldSettings4'])
    case '国际酒店配置:扩展字段5':
      return getVal(PersonalSetting, ['IntlHotelSettings', 'ExtendFieldSettings5'])
    /** ************************************************** 国际酒店 end ***************************************************************************/

    /** ********** 火车票 正常订单 扩展字段 start **************/
    case '火车票配置:正常订单:扩展字段1':
      return getVal(PersonalSetting, ['TrainSettings', 'NormalSettings', 'ExtendFieldSettings1'])
    case '火车票配置:正常订单:扩展字段2':
      return getVal(PersonalSetting, ['TrainSettings', 'NormalSettings', 'ExtendFieldSettings2'])
    case '火车票配置:正常订单:扩展字段3':
      return getVal(PersonalSetting, ['TrainSettings', 'NormalSettings', 'ExtendFieldSettings3'])
    case '火车票配置:正常订单:扩展字段4':
      return getVal(PersonalSetting, ['TrainSettings', 'NormalSettings', 'ExtendFieldSettings4'])
    case '火车票配置:正常订单:扩展字段5':
      return getVal(PersonalSetting, ['TrainSettings', 'NormalSettings', 'ExtendFieldSettings5'])
    /** ********** 火车票 正常订单 扩展字段 end **************/

    /** ********** 火车票 改期订单 扩展字段 start **************/
    case '火车票配置:改期订单:扩展字段1':
      return getVal(PersonalSetting, ['TrainSettings', 'ChangeSettings', 'ExtendFieldSettings1'])
    case '火车票配置:改期订单:扩展字段2':
      return getVal(PersonalSetting, ['TrainSettings', 'ChangeSettings', 'ExtendFieldSettings2'])
    case '火车票配置:改期订单:扩展字段3':
      return getVal(PersonalSetting, ['TrainSettings', 'ChangeSettings', 'ExtendFieldSettings3'])
    case '火车票配置:改期订单:扩展字段4':
      return getVal(PersonalSetting, ['TrainSettings', 'ChangeSettings', 'ExtendFieldSettings4'])
    case '火车票配置:改期订单:扩展字段5':
      return getVal(PersonalSetting, ['TrainSettings', 'ChangeSettings', 'ExtendFieldSettings5'])
    /** ********** 火车票 改期订单 扩展字段 start **************/

    /** ********** 火车票 退票订单 扩展字段 start **************/
    case '火车票配置:退票订单:扩展字段1':
      return getVal(PersonalSetting, ['TrainSettings', 'RefundSettings', 'ExtendFieldSettings1'])
    case '火车票配置:退票订单:扩展字段2':
      return getVal(PersonalSetting, ['TrainSettings', 'RefundSettings', 'ExtendFieldSettings2'])
    case '火车票配置:退票订单:扩展字段3':
      return getVal(PersonalSetting, ['TrainSettings', 'RefundSettings', 'ExtendFieldSettings3'])
    case '火车票配置:退票订单:扩展字段4':
      return getVal(PersonalSetting, ['TrainSettings', 'RefundSettings', 'ExtendFieldSettings4'])
    case '火车票配置:退票订单:扩展字段5':
      return getVal(PersonalSetting, ['TrainSettings', 'RefundSettings', 'ExtendFieldSettings5'])
    /** ********** 火车票 退票订单 扩展字段 start **************/

    /** ************************************************** 用车 start ***************************************************************************/
    case '用车配置:扩展字段1':
      return getVal(PersonalSetting, ['CarSettings', 'ExtendFieldSettings1'])
    case '用车配置:扩展字段2':
      return getVal(PersonalSetting, ['CarSettings', 'ExtendFieldSettings2'])
    case '用车配置:扩展字段3':
      return getVal(PersonalSetting, ['CarSettings', 'ExtendFieldSettings3'])
    case '用车配置:扩展字段4':
      return getVal(PersonalSetting, ['CarSettings', 'ExtendFieldSettings4'])
    case '用车配置:扩展字段5':
      return getVal(PersonalSetting, ['CarSettings', 'ExtendFieldSettings5'])
    /** ************************************************** 用车 end ***************************************************************************/

    /** ************************************************** 用餐 start ***************************************************************************/
    case '用餐配置:扩展字段1':
      return getVal(PersonalSetting, ['DiningSettings', 'ExtendFieldSettings1'])
    case '用餐配置:扩展字段2':
      return getVal(PersonalSetting, ['DiningSettings', 'ExtendFieldSettings2'])
    case '用餐配置:扩展字段3':
      return getVal(PersonalSetting, ['DiningSettings', 'ExtendFieldSettings3'])
    case '用餐配置:扩展字段4':
      return getVal(PersonalSetting, ['DiningSettings', 'ExtendFieldSettings4'])
    case '用餐配置:扩展字段5':
      return getVal(PersonalSetting, ['DiningSettings', 'ExtendFieldSettings5'])
    /** ************************************************** 用餐 end ***************************************************************************/

    /** ********** 出差申请单 扩展字段 start **************/
    case '出差申请单配置:扩展字段1':
      return getVal(PersonalSetting, ['TripApplicationFormSettings', 'ExtendFieldSettings1'])
    case '出差申请单配置:扩展字段2':
      return getVal(PersonalSetting, ['TripApplicationFormSettings', 'ExtendFieldSettings2'])
    case '出差申请单配置:扩展字段3':
      return getVal(PersonalSetting, ['TripApplicationFormSettings', 'ExtendFieldSettings3'])
    case '出差申请单配置:扩展字段4':
      return getVal(PersonalSetting, ['TripApplicationFormSettings', 'ExtendFieldSettings4'])
    case '出差申请单配置:扩展字段5':
      return getVal(PersonalSetting, ['TripApplicationFormSettings', 'ExtendFieldSettings5'])
    /** ********** 出差申请单 扩展字段 end **************/

    /** ********** 汽车票 正常订单 扩展字段 start **************/
    case '汽车票配置:正常订单:扩展字段1':
      return getVal(PersonalSetting, ['BusSettings', 'NormalSettings', 'ExtendFieldSettings1'])
    case '汽车票配置:正常订单:扩展字段2':
      return getVal(PersonalSetting, ['BusSettings', 'NormalSettings', 'ExtendFieldSettings2'])
    case '汽车票配置:正常订单:扩展字段3':
      return getVal(PersonalSetting, ['BusSettings', 'NormalSettings', 'ExtendFieldSettings3'])
    case '汽车票配置:正常订单:扩展字段4':
      return getVal(PersonalSetting, ['BusSettings', 'NormalSettings', 'ExtendFieldSettings4'])
    case '汽车票配置:正常订单:扩展字段5':
      return getVal(PersonalSetting, ['BusSettings', 'NormalSettings', 'ExtendFieldSettings5'])
    /** ********** 汽车票 正常订单 扩展字段 end **************/

    /** ********** 员工 正常订单 扩展字段 start **************/
    case '员工配置:扩展字段1':
      return getVal(PersonalSetting, ['EmployeeSettings', 'ExtendFieldSettings1'])
    case '员工配置:扩展字段2':
      return getVal(PersonalSetting, ['EmployeeSettings', 'ExtendFieldSettings2'])
    case '员工配置:扩展字段3':
      return getVal(PersonalSetting, ['EmployeeSettings', 'ExtendFieldSettings3'])
    case '员工配置:扩展字段4':
      return getVal(PersonalSetting, ['EmployeeSettings', 'ExtendFieldSettings4'])
    case '员工配置:扩展字段5':
      return getVal(PersonalSetting, ['EmployeeSettings', 'ExtendFieldSettings5'])
    /** ********** 员工 正常订单 扩展字段 end **************/

    default:
      return null
  }
}
