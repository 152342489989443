// 承載router-view的components
const Start = resolve => {
  import('components/start/start').then(module => {
    resolve(module);
  });
};

export default {
  path: '/trip',
  component: Start,
  children: [
    {
      name: 'tripList',
      path: 'list',
      component: resolve => require(['pages/trip/list/list.vue'], resolve),
      meta: {
        keepAlive: true
      }
    },
    {
      name: 'tripConcernList',
      path: 'concern/list',
      component: resolve => require(['pages/trip/concern/list.vue'], resolve)
    }
  ]
}
