// 承載router-view的components
const Start = resolve => {
  import('components/start/start').then(module => {
    resolve(module);
  });
};

export default {
  path: '/train',
  redirect: '/train/query',
  component: Start,
  children: [
    {
      path: 'query',
      name: '火车票查询',
      component: resolve =>
        require(['pages/train/query/query.vue'], resolve),
      meta: {
        keepAlive: true
      }
    },
    {
      path: 'inforList',
      name: '火车票车次列表',
      component: resolve =>
        require(['pages/train/infor-list/infor-list.vue'], resolve),
      meta: {
        keepAlive: true
      }
    },
    {
      // 火车票订单列表
      path: 'orderInfoList',
      name: 'TrainOrderInforList',
      component: resolve =>
        require(['pages/train/order/order-list/order-list.vue'], resolve),
      meta: {
        keepAlive: true
      }
    },
    {
      // 火车票订单详情
      path: 'orderDetail',
      name: 'TrainOrderDetail',
      component: resolve =>
        require(['pages/train/order/order-detail/order-detail.vue'], resolve)
    },
    {
      // 火车票订单确认
      path: 'bookConfirm',
      name: 'trainBookConfirm',
      component: resolve =>
        require(['pages/train/book/book-confirm.vue'], resolve)
    },
    {
      path: 'book',
      name: '火车票订单填写',
      component: resolve =>
        require(['pages/train/book/book-detail.vue'], resolve)
    },
    {
      path: 'refund',
      name: '火车票退票',
      component: resolve =>
        require(['pages/train/refund/refund.vue'], resolve)
    },
    {
      path: 'change',
      name: '火车票改签',
      component: resolve =>
        require(['pages/train/change/change.vue'], resolve)
    }
  ]
}
