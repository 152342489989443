// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import "babel-polyfill";
import "common/stylus/index.styl";

import Vue from "vue";
import App from "./App";
import router from "./router";
import FastClick from "fastclick";
import store from "./store";

import Toast from "./plugins/toast";
import Loading from "./plugins/loading";
import Alert from "./plugins/alert";
import "./plugins/toast/toast.css";
import "./plugins/loading/loading.css";
import "./plugins/alert/alert.css";
import { getLanguage, getUrlKey } from "common/utility/utils";
// 工具类
import Utility from "./common/utility/index.js";

// 第三方
import Cube from "cube-ui";

// 图表
import echarts from "echarts";

// 国际化
import i18N from "./common/i18N/index.js";

// vant
import {
  Swipe,
  SwipeItem,
  Slider,
  Image as VanImage,
  List,
  Picker,
  DatetimePicker,
  Popup,
  Uploader,
  NavBar,
  Stepper,
} from "vant";

// 获取默认语言
getLanguage();
// 获取url中带的参数 设置语言 针对oa登录
let languageType = getUrlKey("LanguageType");
if (languageType) {
  let languageObj = {
    1: "CN",
    2: "EN",
    3: "TC",
    4: "JAP",
  };
  window.localStorage.language = languageObj[languageType] || "CN";
}
Vue.prototype.language = window.localStorage.language;
Vue.prototype.$t = i18N;
Vue.prototype.$echarts = echarts;
// eslint-disable-next-line
Vue.use(VueLazyload, {
  error: require("@/assets/image/base/img_error.png"),
  loading: require("@/assets/image/base/img_error.png"),
  attempt: 1,
});
// eslint-disable-next-line
import VueLazyload from "vue-lazyload";

Vue.use(Loading);
Vue.use(Toast);
Vue.use(Alert);
Vue.use(Utility);
Vue.use(Cube);
Vue.use(VueLazyload);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(VanImage);
Vue.use(List);
Vue.use(Picker);
Vue.use(DatetimePicker);
Vue.use(Popup);
Vue.use(Uploader);
Vue.use(NavBar);
Vue.use(Slider);
Vue.use(Stepper);

// 导入API注册模块
// eslint-disable-next-line
import "./common/js/register";

// 修复vh变量
require("viewport-units-buggyfill").init();

FastClick.attach(document.body);
/**
 * 当使用FastClick 时，input框在ios上点击输入调取手机自带输入键盘不灵敏，有时候甚至点不出来。而安卓上完全没问题。这个原因是因为FastClick的点击穿透。解决方法：
 */
FastClick.prototype.focus = function (targetElement) {
  let u = navigator.userAgent;
  let deviceIsIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
  let length; // Issue #160: on iOS 7, some input elements (e.g. date datetime month) throw a vague TypeError on setSelectionRange. These elements don't have an integer value for the selectionStart and selectionEnd properties, but unfortunately that can't be used for detection because accessing the properties also throws a TypeError. Just check the type instead. Filed as Apple bug #15122724.
  if (
    deviceIsIOS &&
    targetElement.setSelectionRange &&
    targetElement.type.indexOf("date") !== 0 &&
    targetElement.type !== "time" &&
    targetElement.type !== "month"
  ) {
    length = targetElement.value.length;
    targetElement.focus();
    targetElement.setSelectionRange(length, length);
  } else {
    targetElement.focus();
  }
};
/* 2.2.0 新增 | 设置 false 以阻止vue在启动时生成生产提示 */
Vue.config.productionTip = false;

/* eslint-disable no-new */
new Vue({
  el: "#app",
  router,
  store,
  render: (h) => h(App),
});
