// 承載router-view的components
const Start = resolve => {
  import('components/start/start').then(module => {
    resolve(module);
  });
};

export default {
  path: '/travelApply',
  component: Start,
  children: [
    {
      path: 'bookDetails',
      name: '出差申请单订单填写',
      component: resolve =>
        require(['pages/travel-apply/book/book-details.vue'], resolve)
    },
    {
      path: 'orderConfirm',
      name: 'travelApplyOrderConfirm',
      component: resolve =>
        require(['pages/travel-apply/order/order-confirm/order-confirm.vue'], resolve)
    },
    {
      name: 'travelApplyOrderList',
      path: 'orderList',
      component: resolve =>
        require(['pages/travel-apply/order/order-list/order-list.vue'], resolve),
      meta: {
        keepAlive: true
      }
    },
    {
      path: 'orderDetails',
      name: '出差申请单订单详情',
      component: resolve =>
        require(['pages/personal/auth/order/order-details.vue'], resolve)
    }
  ]
}
