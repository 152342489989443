const state = {
  direction: 'forward', // 路由跳转方向
  isMiniprogram: false, // 是否在小程序中
  isWechatBrowser: false, // 是否在微信浏览器中
  isEMobile7Browser: false, // 是否在泛微eMobile7浏览器中

  PersonalInfor: null, // 个人信息
  PersonalPower: null, // 权限
  PersonalSetting: null, // 配置
  Theme: null, // 主题配置
  UserManageInfo: null, //
  noticeDetails: {}, // 消息数量统计

  AMapKey: {
    key: '',
    securityJsCode: '',
  },

  /**
   * 后端ErrorCode
   */
  forceBack: false, // 航班/车次失效, 需要强制刷新
  ErrorCodeFlightExpired: 305,
  ErrorCodeIntlFlightExpired: 1110,
  ErrorCodeHotelExpired: 503,
  ErrorCodeTrainExpired: 1004,
  ErrorCodeCarExpired: 9995
}
export default state
