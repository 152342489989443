import * as types from './mutation-types'
import {UPDATE_AMAP_KEY} from "./mutation-types";

const mutations = {
  [types.UPDATE_DIRECTION](state, payload) {
    state.direction = payload.direction
  },
  [types.SET_IS_MINIPROGRAM](state, payload) {
    state.isMiniprogram = payload
  },
  [types.SET_IS_WECHAT_BROWSER](state, payload) {
    state.isWechatBrowser = payload
  },
  [types.SET_IS_EMOBILE7_BROWSER](state, payload) {
    state.isEMobile7Browser = payload
  },
  [types.SET_FORCE_BACK](state, payload) {
    state.forceBack = payload
  },
  [types.UPDATE_PERSONAL_INFOR](state, payload) {
    state.PersonalInfor = payload
  },
  [types.UPDATE_PERSONAL_POWER](state, payload) {
    state.PersonalPower = payload
  },
  [types.UPDATE_PERSONAL_SETTING](state, payload) {
    state.PersonalSetting = payload
  },
  [types.UPDATE_THEME_SETTING](state, payload) {
    state.Theme = payload
  },
  [types.SET_USER_MANAGE_INFO](state, payload) {
    state.UserManageInfo = payload
  },
  [types.UPDATE_NOTICE_DETAILS](state, payload) {
    state.noticeDetails = payload
  },
  [types.UPDATE_AMAP_KEY](state, payload) {
    state.AMapKey = payload
  }
}
export default mutations
