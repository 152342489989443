// 承載router-view的components
const Start = resolve => {
  import('components/start/start').then(module => {
    resolve(module);
  });
};

export default {
  path: '/',
  redirect: '/home',
  component: Start,
  children: [
    {
      path: 'login',
      name: '登录',
      component: resolve =>
        require(['pages/login/login.vue'], resolve)
    },
    {
      path: 'loginWayOfRecovery',
      name: '忘记密码',
      component: resolve =>
        require(['pages/login/forget-password/way-of-recovery.vue'], resolve)
    },
    {
      path: 'forgetPasswordStep1',
      name: '忘记密码_通过手机找回_1',
      component: resolve =>
        require(['pages/login/forget-password/forget-password-step1.vue'], resolve)
    },
    {
      path: 'forgetPasswordStep2',
      name: '忘记密码_通过手机找回_2',
      component: resolve =>
        require(['pages/login/forget-password/forget-password-step2.vue'], resolve)
    },
    {
      path: 'forgetPasswordStep3',
      name: '忘记密码_通过手机找回_3',
      component: resolve =>
        require(['pages/login/forget-password/forget-password-step3.vue'], resolve)
    },
    {
      path: 'forgetPasswordEmail',
      name: '忘记密码_通过邮箱找回',
      component: resolve =>
        require(['pages/login/forget-password/forget-password-email.vue'], resolve)
    },
    {
      path: 'home',
      name: '首页',
      component: resolve =>
        require(['pages/home/home.vue'], resolve)
    },
    {
      path: 'management',
      name: '管理',
      component: resolve =>
        require(['pages/management/management.vue'], resolve)
    },
    {
      path: 'ourService',
      name: '客服',
      component: resolve =>
        require(['pages/our-service/our-service.vue'], resolve)
    },
    {
      path: 'ourService/robot',
      name: '客服机器人',
      component: resolve =>
        require(['pages/our-service/robot.vue'], resolve)
    },
    {
      path: 'wechatShare',
      name: '微信关注公众号中间页',
      component: resolve =>
        require(['pages/wechat/share/share.vue'], resolve)
    }
  ]
}
