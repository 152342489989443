// getSetting
import store from '@/store'
import getSettingForExtendField from './getSetting/getSettingForExtendField'
export default function(key) {
  if (!(store && store.state && store.state.PersonalSetting)) {
    return ''
  }
  let PersonalSetting = store.state.PersonalSetting
  if (key === '常用配置:授权码名称') {
    return PersonalSetting.CommonSettings.AuthorizationCodeName
  } else if (key === '常用配置:BU名称') {
    return PersonalSetting.CommonSettings.BusinessUnitName
  } else if (key === '常用配置:自定义项') {
    return PersonalSetting.CommonSettings.CustomItemName
  } else if (key === '常用配置:员工编号') {
    return PersonalSetting.CommonSettings.IsDisplayEmployeeNo
  } else if (key === '常用配置:员工编号:必填') {
    return PersonalSetting.CommonSettings.IsRequiredEmployeeNo
  } else if (key === '常用配置:预订消息:展示') {
    return PersonalSetting.CommonSettings.IsDisplayBookMessage
  } else if (key === '常用配置:成本中心') {
    return PersonalSetting.CommonSettings.IsDisplayCostCenter
  // } else if (key === '常用配置:成本中心:添加') {
  //   return PersonalSetting.CommonSettings.IsAllowAddCostCenter
  // } else if (key === '常用配置:成本中心:编辑') {
  //   return PersonalSetting.CommonSettings.IsEnableEditCostCenter
  } else if (key === '常用配置:成本中心:必填') {
    return PersonalSetting.CommonSettings.IsRequiredCostCenter
  } else if (key === '常用配置:部门') {
    return PersonalSetting.CommonSettings.IsDisplayDepartment
  // } else if (key === '常用配置:部门:编辑') {
  //   return PersonalSetting.CommonSettings.IsEnableEditDepartment
  } else if (key === '常用配置:部门:必填') {
    return PersonalSetting.CommonSettings.IsRequiredDepartment
  } else if (key === '常用配置:BU') {
    return PersonalSetting.CommonSettings.IsDisplayBusinessUnit
  // } else if (key === '常用配置:BU:编辑') {
  //   return PersonalSetting.CommonSettings.IsEnableEditBusinessUnit
  } else if (key === '常用配置:BU:必填') {
    return PersonalSetting.CommonSettings.IsRequiredBusinessUnit
  } else if (key === '常用配置:用户隐私') {
    // return false
    return (PersonalSetting.CommonSettings && PersonalSetting.CommonSettings.EnablePrivacyProtect)
  } else if (key === '常用配置:昵称:展示') {
    return (PersonalSetting.CommonSettings && PersonalSetting.CommonSettings.IsDisplayNickName)
    // ============================================================================================
  } else if (key === '国内机票配置:正常订单:展示儿童婴儿价格') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.NormalSettings && PersonalSetting.FlightSettings.NormalSettings.SearchSettings && PersonalSetting.FlightSettings.NormalSettings.SearchSettings.IsDisplayFlightChildAndInfantPrice
  } else if (key === '国内机票配置:正常订单:授权码:展示') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.NormalSettings && PersonalSetting.FlightSettings.NormalSettings.AuthorizationCodeSettings && PersonalSetting.FlightSettings.NormalSettings.AuthorizationCodeSettings.IsDisplayAuthorizationCode
  } else if (key === '国内机票配置:正常订单:授权码名称') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.NormalSettings && PersonalSetting.FlightSettings.NormalSettings.AuthorizationCodeSettings && PersonalSetting.FlightSettings.NormalSettings.AuthorizationCodeSettings.AuthorizationCodeName
  } else if (key === '国内机票配置:正常订单:授权码:必填') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.NormalSettings && PersonalSetting.FlightSettings.NormalSettings.AuthorizationCodeSettings && PersonalSetting.FlightSettings.NormalSettings.AuthorizationCodeSettings.IsRequiredAuthorizationCode
  } else if (key === '国内机票配置:正常订单:授权码:校验') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.NormalSettings && PersonalSetting.FlightSettings.NormalSettings.AuthorizationCodeSettings && PersonalSetting.FlightSettings.NormalSettings.AuthorizationCodeSettings.IsCheckAuthorizationCode
  } else if (key === '国内机票配置:正常订单:联系人预订信息:邮箱') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.NormalSettings && PersonalSetting.FlightSettings.NormalSettings.ContactBookMessageSettings && PersonalSetting.FlightSettings.NormalSettings.ContactBookMessageSettings.IsSendEmail
  } else if (key === '国内机票配置:正常订单:联系人预订信息:短信') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.NormalSettings && PersonalSetting.FlightSettings.NormalSettings.ContactBookMessageSettings && PersonalSetting.FlightSettings.NormalSettings.ContactBookMessageSettings.IsSendSms
  } else if (key === '国内机票配置:正常订单:联系人预订信息:微信') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.NormalSettings && PersonalSetting.FlightSettings.NormalSettings.ContactBookMessageSettings && PersonalSetting.FlightSettings.NormalSettings.ContactBookMessageSettings.IsSendWechat
  } else if (key === '国内机票配置:正常订单:联系人出票信息:邮箱') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.NormalSettings && PersonalSetting.FlightSettings.NormalSettings.ContactIssuedMessageSettings && PersonalSetting.FlightSettings.NormalSettings.ContactIssuedMessageSettings.IsSendEmail
  } else if (key === '国内机票配置:正常订单:联系人出票信息:短信') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.NormalSettings && PersonalSetting.FlightSettings.NormalSettings.ContactIssuedMessageSettings && PersonalSetting.FlightSettings.NormalSettings.ContactIssuedMessageSettings.IsSendSms
  } else if (key === '国内机票配置:正常订单:联系人出票信息:微信') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.NormalSettings && PersonalSetting.FlightSettings.NormalSettings.ContactIssuedMessageSettings && PersonalSetting.FlightSettings.NormalSettings.ContactIssuedMessageSettings.IsSendWechat
  } else if (key === '国内机票配置:正常订单:自定义项:名称') {
    return PersonalSetting.FlightSettings.NormalSettings.CustomItemSettings.CustomItemName
  } else if (key === '国内机票配置:正常订单:自定义项:展示') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.NormalSettings && PersonalSetting.FlightSettings.NormalSettings.CustomItemSettings && PersonalSetting.FlightSettings.NormalSettings.CustomItemSettings.IsDisplayCustomItem
  } else if (key === '国内机票配置:正常订单:自定义项:必填') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.NormalSettings && PersonalSetting.FlightSettings.NormalSettings.CustomItemSettings && PersonalSetting.FlightSettings.NormalSettings.CustomItemSettings.IsRequiredCustomItem
  } else if (key === '国内机票配置:正常订单:保险:绑定保险') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.NormalSettings && PersonalSetting.FlightSettings.NormalSettings.InsuranceSettings && PersonalSetting.FlightSettings.NormalSettings.InsuranceSettings.IsBindAccidentInsurance
  } else if (key === '国内机票配置:正常订单:保险:赠送保险') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.NormalSettings && PersonalSetting.FlightSettings.NormalSettings.InsuranceSettings && PersonalSetting.FlightSettings.NormalSettings.InsuranceSettings.IsFreeInsurance
  // } else if (key === '国内机票配置:正常订单:最低价提醒') {
  //   return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.NormalSettings && PersonalSetting.FlightSettings.NormalSettings.LowestPriceSettings && PersonalSetting.FlightSettings.NormalSettings.InsuranceSettings.LowestPriceSettings.IsEnable
  // } else if (key === '国内机票配置:正常订单:最低价提醒:小时') {
  //   return PersonalSetting.FlightSettings.NormalSettings.LowestPriceSettings.RangeHour
  // } else if (key === '国内机票配置:正常订单:最低价提醒:价格') {
  //   return PersonalSetting.FlightSettings.NormalSettings.LowestPriceSettings.RangePrice
  } else if (key === '国内机票配置:正常订单:乘客预订信息:邮箱') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.NormalSettings && PersonalSetting.FlightSettings.NormalSettings.PassengerBookMessageSettings && PersonalSetting.FlightSettings.NormalSettings.PassengerBookMessageSettings.IsSendEmail
  } else if (key === '国内机票配置:正常订单:乘客预订信息:短信') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.NormalSettings && PersonalSetting.FlightSettings.NormalSettings.PassengerBookMessageSettings && PersonalSetting.FlightSettings.NormalSettings.PassengerBookMessageSettings.IsSendSms
  } else if (key === '国内机票配置:正常订单:乘客预订信息:微信') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.NormalSettings && PersonalSetting.FlightSettings.NormalSettings.PassengerBookMessageSettings && PersonalSetting.FlightSettings.NormalSettings.PassengerBookMessageSettings.IsSendWechat
  } else if (key === '国内机票配置:正常订单:乘客出票信息:邮箱') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.NormalSettings && PersonalSetting.FlightSettings.NormalSettings.PassengerIssuedMessageSettings && PersonalSetting.FlightSettings.NormalSettings.PassengerIssuedMessageSettings.IsSendEmail
  } else if (key === '国内机票配置:正常订单:乘客出票信息:短信') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.NormalSettings && PersonalSetting.FlightSettings.NormalSettings.PassengerIssuedMessageSettings && PersonalSetting.FlightSettings.NormalSettings.PassengerIssuedMessageSettings.IsSendSms
  } else if (key === '国内机票配置:正常订单:乘客出票信息:微信') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.NormalSettings && PersonalSetting.FlightSettings.NormalSettings.PassengerIssuedMessageSettings && PersonalSetting.FlightSettings.NormalSettings.PassengerIssuedMessageSettings.IsSendWechat
  } else if (key === '国内机票配置:正常订单:差旅目的:展示') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.NormalSettings && PersonalSetting.FlightSettings.NormalSettings.PurposeSettings && PersonalSetting.FlightSettings.NormalSettings.PurposeSettings.IsDisplayPurpose
  } else if (key === '国内机票配置:正常订单:差旅目的:必填') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.NormalSettings && PersonalSetting.FlightSettings.NormalSettings.PurposeSettings && PersonalSetting.FlightSettings.NormalSettings.PurposeSettings.IsRequiredPurpose
  } else if (key === '国内机票配置:正常订单:差旅目的:类型') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.NormalSettings && PersonalSetting.FlightSettings.NormalSettings.PurposeSettings ? Number(PersonalSetting.FlightSettings.NormalSettings.PurposeSettings.PurposeType) : null
  } else if (key === '国内机票配置:正常订单:审批:需要审批') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.NormalSettings && PersonalSetting.FlightSettings.NormalSettings.VettingSettings && PersonalSetting.FlightSettings.NormalSettings.VettingSettings.IsEnableVetting
  } else if (key === '国内机票配置:正常订单:审批:审批类型') {
    return Number(PersonalSetting.FlightSettings.NormalSettings.VettingSettings.VettingType)
  } else if (key === '国内机票配置:正常订单:上传附件:展示') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.NormalSettings &&
      PersonalSetting.FlightSettings.NormalSettings.UploadAttachFileSettings && PersonalSetting.FlightSettings.NormalSettings.UploadAttachFileSettings.IsDisplayUploadAttachFile
  } else if (key === '国内机票配置:正常订单:上传附件:名称') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.NormalSettings &&
      PersonalSetting.FlightSettings.NormalSettings.UploadAttachFileSettings && PersonalSetting.FlightSettings.NormalSettings.UploadAttachFileSettings.Name
  } else if (key === '国内机票配置:正常订单:上传附件:必填') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.NormalSettings &&
      PersonalSetting.FlightSettings.NormalSettings.UploadAttachFileSettings && PersonalSetting.FlightSettings.NormalSettings.UploadAttachFileSettings.IsRequiredUploadAttachFile
  } else if (key === '国内机票配置:正常订单:查询顺序') {
    // 0:先查询航班再选乘客 1:先选乘客再查询航班
    return Number(PersonalSetting.FlightSettings.NormalSettings.SearchSettings.SearchOrder)
  // ============================================================================================
  } else if (key === '国内机票配置:改期订单:授权码:展示') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.ChangeSettings && PersonalSetting.FlightSettings.ChangeSettings.AuthorizationCodeSettings && PersonalSetting.FlightSettings.ChangeSettings.AuthorizationCodeSettings.IsDisplayAuthorizationCode
  } else if (key === '国内机票配置:改期订单:授权码名称') {
    /** 授权码名称默认读取国内正单的配置 */
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.NormalSettings && PersonalSetting.FlightSettings.NormalSettings.AuthorizationCodeSettings && PersonalSetting.FlightSettings.NormalSettings.AuthorizationCodeSettings.AuthorizationCodeName
  } else if (key === '国内机票配置:改期订单:授权码:必填') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.ChangeSettings && PersonalSetting.FlightSettings.ChangeSettings.AuthorizationCodeSettings && PersonalSetting.FlightSettings.ChangeSettings.AuthorizationCodeSettings.IsRequiredAuthorizationCode
  } else if (key === '国内机票配置:改期订单:授权码:校验') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.ChangeSettings && PersonalSetting.FlightSettings.ChangeSettings.AuthorizationCodeSettings && PersonalSetting.FlightSettings.ChangeSettings.AuthorizationCodeSettings.IsCheckAuthorizationCode
  } else if (key === '国内机票配置:改期订单:联系人预订信息:邮箱') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.ChangeSettings && PersonalSetting.FlightSettings.ChangeSettings.ContactBookMessageSettings && PersonalSetting.FlightSettings.ChangeSettings.ContactBookMessageSettings.IsSendEmail
  } else if (key === '国内机票配置:改期订单:联系人预订信息:短信') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.ChangeSettings && PersonalSetting.FlightSettings.ChangeSettings.ContactBookMessageSettings && PersonalSetting.FlightSettings.ChangeSettings.ContactBookMessageSettings.IsSendSms
  } else if (key === '国内机票配置:改期订单:联系人预订信息:微信') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.ChangeSettings && PersonalSetting.FlightSettings.ChangeSettings.ContactBookMessageSettings && PersonalSetting.FlightSettings.ChangeSettings.ContactBookMessageSettings.IsSendWechat
  } else if (key === '国内机票配置:改期订单:联系人出票信息:邮箱') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.ChangeSettings && PersonalSetting.FlightSettings.ChangeSettings.ContactIssuedMessageSettings && PersonalSetting.FlightSettings.ChangeSettings.ContactIssuedMessageSettings.IsSendEmail
  } else if (key === '国内机票配置:改期订单:联系人出票信息:短信') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.ChangeSettings && PersonalSetting.FlightSettings.ChangeSettings.ContactIssuedMessageSettings && PersonalSetting.FlightSettings.ChangeSettings.ContactIssuedMessageSettings.IsSendSms
  } else if (key === '国内机票配置:改期订单:联系人出票信息:微信') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.ChangeSettings && PersonalSetting.FlightSettings.ChangeSettings.ContactIssuedMessageSettings && PersonalSetting.FlightSettings.ChangeSettings.ContactIssuedMessageSettings.IsSendWechat
  } else if (key === '国内机票配置:改期订单:自定义项:名称') {
    return PersonalSetting.FlightSettings.ChangeSettings.CustomItemSettings.CustomItemName
  } else if (key === '国内机票配置:改期订单:自定义项:展示') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.ChangeSettings && PersonalSetting.FlightSettings.ChangeSettings.CustomItemSettings && PersonalSetting.FlightSettings.ChangeSettings.CustomItemSettings.IsDisplayCustomItem
  } else if (key === '国内机票配置:改期订单:自定义项:必填') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.ChangeSettings && PersonalSetting.FlightSettings.ChangeSettings.CustomItemSettings && PersonalSetting.FlightSettings.ChangeSettings.CustomItemSettings.IsRequiredCustomItem
  } else if (key === '国内机票配置:改期订单:保险:绑定保险') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.ChangeSettings && PersonalSetting.FlightSettings.ChangeSettings.InsuranceSettings && PersonalSetting.FlightSettings.ChangeSettings.InsuranceSettings.IsBindAccidentInsurance
  } else if (key === '国内机票配置:改期订单:保险:赠送保险') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.ChangeSettings && PersonalSetting.FlightSettings.ChangeSettings.InsuranceSettings && PersonalSetting.FlightSettings.ChangeSettings.InsuranceSettings.IsFreeInsurance
  } else if (key === '国内机票配置:改期订单:最低价提醒') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.ChangeSettings && PersonalSetting.FlightSettings.ChangeSettings.LowestPriceSettings && PersonalSetting.FlightSettings.ChangeSettings.InsuranceSettings.LowestPriceSettings.IsEnable
  } else if (key === '国内机票配置:改期订单:最低价提醒:小时') {
    return PersonalSetting.FlightSettings.ChangeSettings.LowestPriceSettings.RangeHour
  } else if (key === '国内机票配置:改期订单:最低价提醒:价格') {
    return PersonalSetting.FlightSettings.ChangeSettings.LowestPriceSettings.RangePrice
  } else if (key === '国内机票配置:改期订单:乘客预订信息:邮箱') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.ChangeSettings && PersonalSetting.FlightSettings.ChangeSettings.PassengerBookMessageSettings && PersonalSetting.FlightSettings.ChangeSettings.PassengerBookMessageSettings.IsSendEmail
  } else if (key === '国内机票配置:改期订单:乘客预订信息:短信') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.ChangeSettings && PersonalSetting.FlightSettings.ChangeSettings.PassengerBookMessageSettings && PersonalSetting.FlightSettings.ChangeSettings.PassengerBookMessageSettings.IsSendSms
  } else if (key === '国内机票配置:改期订单:乘客预订信息:微信') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.ChangeSettings && PersonalSetting.FlightSettings.ChangeSettings.PassengerBookMessageSettings && PersonalSetting.FlightSettings.ChangeSettings.PassengerBookMessageSettings.IsSendWechat
  } else if (key === '国内机票配置:改期订单:乘客出票信息:邮箱') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.ChangeSettings && PersonalSetting.FlightSettings.ChangeSettings.PassengerIssuedMessageSettings && PersonalSetting.FlightSettings.ChangeSettings.PassengerIssuedMessageSettings.IsSendEmail
  } else if (key === '国内机票配置:改期订单:乘客出票信息:短信') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.ChangeSettings && PersonalSetting.FlightSettings.ChangeSettings.PassengerIssuedMessageSettings && PersonalSetting.FlightSettings.ChangeSettings.PassengerIssuedMessageSettings.IsSendSms
  } else if (key === '国内机票配置:改期订单:乘客出票信息:微信') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.ChangeSettings && PersonalSetting.FlightSettings.ChangeSettings.PassengerIssuedMessageSettings && PersonalSetting.FlightSettings.ChangeSettings.PassengerIssuedMessageSettings.IsSendWechat
  } else if (key === '国内机票配置:改期订单:差旅目的:展示') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.ChangeSettings && PersonalSetting.FlightSettings.ChangeSettings.PurposeSettings && PersonalSetting.FlightSettings.ChangeSettings.PurposeSettings.IsDisplayPurpose
  } else if (key === '国内机票配置:改期订单:差旅目的:必填') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.ChangeSettings && PersonalSetting.FlightSettings.ChangeSettings.PurposeSettings && PersonalSetting.FlightSettings.ChangeSettings.PurposeSettings.IsRequiredPurpose
  } else if (key === '国内机票配置:改期订单:差旅目的:类型') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.ChangeSettings && PersonalSetting.FlightSettings.ChangeSettings.PurposeSettings ? Number(PersonalSetting.FlightSettings.ChangeSettings.PurposeSettings.PurposeType) : null
  } else if (key === '国内机票配置:改期订单:审批:需要审批') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.ChangeSettings && PersonalSetting.FlightSettings.ChangeSettings.VettingSettings && PersonalSetting.FlightSettings.ChangeSettings.VettingSettings.IsEnableVetting
  } else if (key === '国内机票配置:改期订单:审批:审批类型') {
    return Number(PersonalSetting.FlightSettings.ChangeSettings.VettingSettings.VettingType)
  } else if (key === '国内机票配置:改期订单:上传附件:展示') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.ChangeSettings &&
      PersonalSetting.FlightSettings.ChangeSettings.UploadAttachFileSettings && PersonalSetting.FlightSettings.ChangeSettings.UploadAttachFileSettings.IsDisplayUploadAttachFile
  } else if (key === '国内机票配置:改期订单:上传附件:名称') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.ChangeSettings &&
      PersonalSetting.FlightSettings.ChangeSettings.UploadAttachFileSettings && PersonalSetting.FlightSettings.ChangeSettings.UploadAttachFileSettings.Name
  } else if (key === '国内机票配置:改期订单:上传附件:必填') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.ChangeSettings &&
      PersonalSetting.FlightSettings.ChangeSettings.UploadAttachFileSettings && PersonalSetting.FlightSettings.ChangeSettings.UploadAttachFileSettings.IsRequiredUploadAttachFile
  } else if (key === '国内机票配置:改期订单:改期原因:展示') {
    return PersonalSetting.FlightSettings.ChangeSettings.IsDisplayChangeReason
  } else if (key === '国内机票配置:改期订单:改期原因:必填') {
    return PersonalSetting.FlightSettings.ChangeSettings.IsRequiredChangeReason
  } else if (key === '国内机票配置:改期订单:改期原因:其他') {
    return PersonalSetting.FlightSettings.ChangeSettings.IsDisplayOtherItem
  // ============================================================================================
  } else if (key === '国内机票配置:退票订单:联系人退票信息:邮箱') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.RefundSettings && PersonalSetting.FlightSettings.RefundSettings.ContactRefundMessageSettings && PersonalSetting.FlightSettings.RefundSettings.ContactRefundMessageSettings.IsSendEmail
  } else if (key === '国内机票配置:退票订单:联系人退票信息:短信') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.RefundSettings && PersonalSetting.FlightSettings.RefundSettings.ContactRefundMessageSettings && PersonalSetting.FlightSettings.RefundSettings.ContactRefundMessageSettings.IsSendSms
  } else if (key === '国内机票配置:退票订单:联系人退票信息:微信') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.RefundSettings && PersonalSetting.FlightSettings.RefundSettings.ContactRefundMessageSettings && PersonalSetting.FlightSettings.RefundSettings.ContactRefundMessageSettings.IsSendWechat
  } else if (key === '国内机票配置:退票订单:乘客退票信息:邮箱') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.RefundSettings && PersonalSetting.FlightSettings.RefundSettings.PassengerRefundMessageSettings && PersonalSetting.FlightSettings.RefundSettings.PassengerRefundMessageSettings.IsSendEmail
  } else if (key === '国内机票配置:退票订单:乘客退票信息:短信') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.RefundSettings && PersonalSetting.FlightSettings.RefundSettings.PassengerRefundMessageSettings && PersonalSetting.FlightSettings.RefundSettings.PassengerRefundMessageSettings.IsSendSms
  } else if (key === '国内机票配置:退票订单:乘客退票信息:微信') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.RefundSettings && PersonalSetting.FlightSettings.RefundSettings.PassengerRefundMessageSettings && PersonalSetting.FlightSettings.RefundSettings.PassengerRefundMessageSettings.IsSendWechat
  } else if (key === '国内机票配置:退票订单:审批:需要审批') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.RefundSettings && PersonalSetting.FlightSettings.RefundSettings.VettingSettings && PersonalSetting.FlightSettings.RefundSettings.VettingSettings.IsEnableVetting
  } else if (key === '国内机票配置:退票订单:审批:审批类型') {
    return Number(PersonalSetting.FlightSettings.RefundSettings.VettingSettings.VettingType)
  } else if (key === '国内机票配置:退票订单:退票原因:展示') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.RefundSettings && PersonalSetting.FlightSettings.RefundSettings.IsDisplayRefundReason
  } else if (key === '国内机票配置:退票订单:退票原因:必填') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.RefundSettings && PersonalSetting.FlightSettings.RefundSettings.IsRequiredRefundReason
  } else if (key === '国内机票配置:退票订单:退票原因:其他') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.RefundSettings && PersonalSetting.FlightSettings.RefundSettings.IsDisplayOtherItem
  } else if (key === '国内机票配置:退票订单:授权码名称') {
    /** 授权码名称默认读取国内正单的配置 */
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.NormalSettings && PersonalSetting.FlightSettings.NormalSettings.AuthorizationCodeSettings && PersonalSetting.FlightSettings.NormalSettings.AuthorizationCodeSettings.AuthorizationCodeName
  } else if (key === '国内机票配置:退票订单:授权码:展示') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.RefundSettings && PersonalSetting.FlightSettings.RefundSettings.AuthorizationCodeSettings && PersonalSetting.FlightSettings.RefundSettings.AuthorizationCodeSettings.IsDisplayAuthorizationCode
  } else if (key === '国内机票配置:退票订单:授权码:必填') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.RefundSettings && PersonalSetting.FlightSettings.RefundSettings.AuthorizationCodeSettings && PersonalSetting.FlightSettings.RefundSettings.AuthorizationCodeSettings.IsRequiredAuthorizationCode
  } else if (key === '国内机票配置:退票订单:授权码:校验') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.RefundSettings && PersonalSetting.FlightSettings.RefundSettings.AuthorizationCodeSettings && PersonalSetting.FlightSettings.RefundSettings.AuthorizationCodeSettings.IsCheckAuthorizationCode
  } else if (key === '国内机票配置:退票订单:上传附件:名称') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.RefundSettings && PersonalSetting.FlightSettings.RefundSettings.UploadAttachFileSettings && PersonalSetting.FlightSettings.RefundSettings.UploadAttachFileSettings.Name
  } else if (key === '国内机票配置:退票订单:上传附件:展示') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.RefundSettings && PersonalSetting.FlightSettings.RefundSettings.UploadAttachFileSettings && PersonalSetting.FlightSettings.RefundSettings.UploadAttachFileSettings.IsDisplayUploadAttachFile
  } else if (key === '国内机票配置:退票订单:上传附件:必填') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.RefundSettings && PersonalSetting.FlightSettings.RefundSettings.UploadAttachFileSettings && PersonalSetting.FlightSettings.RefundSettings.UploadAttachFileSettings.IsRequiredUploadAttachFile
  } else if (key === '国内机票配置:展示票号') {
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.IsDisplayTicketNo
  // ============================================================================================
  } else if (key === '国内酒店配置:授权码名称') {
    /** 授权码名称默认读取国内正单的配置 */
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.NormalSettings && PersonalSetting.FlightSettings.NormalSettings.AuthorizationCodeSettings && PersonalSetting.FlightSettings.NormalSettings.AuthorizationCodeSettings.AuthorizationCodeName
  } else if (key === '国内酒店配置:授权码:展示') {
    return PersonalSetting.HotelSettings && PersonalSetting.HotelSettings.AuthorizationCodeSettings && PersonalSetting.HotelSettings.AuthorizationCodeSettings.IsDisplayAuthorizationCode
  } else if (key === '国内酒店配置:授权码:必填') {
    return PersonalSetting.HotelSettings && PersonalSetting.HotelSettings.AuthorizationCodeSettings && PersonalSetting.HotelSettings.AuthorizationCodeSettings.IsRequiredAuthorizationCode
  } else if (key === '国内酒店配置:授权码:校验') {
    return PersonalSetting.HotelSettings && PersonalSetting.HotelSettings.AuthorizationCodeSettings && PersonalSetting.HotelSettings.AuthorizationCodeSettings.IsCheckAuthorizationCode
  } else if (key === '国内酒店配置:联系人确认信息:邮箱') {
    return PersonalSetting.HotelSettings && PersonalSetting.HotelSettings.ContactConfirmMessageSettings && PersonalSetting.HotelSettings.ContactConfirmMessageSettings.IsSendEmail
  } else if (key === '国内酒店配置:联系人确认信息:短信') {
    return PersonalSetting.HotelSettings && PersonalSetting.HotelSettings.ContactConfirmMessageSettings && PersonalSetting.HotelSettings.ContactConfirmMessageSettings.IsSendSms
  } else if (key === '国内酒店配置:联系人确认信息:微信') {
    return PersonalSetting.HotelSettings && PersonalSetting.HotelSettings.ContactConfirmMessageSettings && PersonalSetting.HotelSettings.ContactConfirmMessageSettings.IsSendWechat
  } else if (key === '国内酒店配置:自定义项:名称') {
    return PersonalSetting.HotelSettings.CustomItemSettings.CustomItemName
  } else if (key === '国内酒店配置:自定义项:展示') {
    return PersonalSetting.HotelSettings && PersonalSetting.HotelSettings.CustomItemSettings && PersonalSetting.HotelSettings.CustomItemSettings.IsDisplayCustomItem
  } else if (key === '国内酒店配置:自定义项:必填') {
    return PersonalSetting.HotelSettings && PersonalSetting.HotelSettings.CustomItemSettings && PersonalSetting.HotelSettings.CustomItemSettings.IsRequiredCustomItem
  } else if (key === '国内酒店配置:入住人确认信息:邮箱') {
    return PersonalSetting.HotelSettings && PersonalSetting.HotelSettings.GuestConfirmMessageSettings && PersonalSetting.HotelSettings.GuestConfirmMessageSettings.IsSendEmail
  } else if (key === '国内酒店配置:入住人确认信息:短信') {
    return PersonalSetting.HotelSettings && PersonalSetting.HotelSettings.GuestConfirmMessageSettings && PersonalSetting.HotelSettings.GuestConfirmMessageSettings.IsSendSms
  } else if (key === '国内酒店配置:入住人确认信息:微信') {
    return PersonalSetting.HotelSettings && PersonalSetting.HotelSettings.GuestConfirmMessageSettings && PersonalSetting.HotelSettings.GuestConfirmMessageSettings.IsSendWechat
  } else if (key === '国内酒店配置:差旅目的:展示') {
    return PersonalSetting.HotelSettings && PersonalSetting.HotelSettings.PurposeSettings && PersonalSetting.HotelSettings.PurposeSettings.IsDisplayPurpose
  } else if (key === '国内酒店配置:差旅目的:必填') {
    return PersonalSetting.HotelSettings && PersonalSetting.HotelSettings.PurposeSettings && PersonalSetting.HotelSettings.PurposeSettings.IsRequiredPurpose
  } else if (key === '国内酒店配置:差旅目的:类型') {
    return PersonalSetting.HotelSettings && PersonalSetting.HotelSettings.PurposeSettings ? Number(PersonalSetting.HotelSettings.PurposeSettings.PurposeType) : null
  } else if (key === '国内酒店配置:审批:需要审批') {
    return PersonalSetting.HotelSettings && PersonalSetting.HotelSettings.VettingSettings && PersonalSetting.HotelSettings.VettingSettings.IsEnableVetting
  } else if (key === '国内酒店配置:审批:审批类型') {
    return Number(PersonalSetting.HotelSettings.VettingSettings.VettingType)
  } else if (key === '国内酒店配置:上传附件:展示') {
    return PersonalSetting.HotelSettings && PersonalSetting.HotelSettings.UploadAttachFileSettings && PersonalSetting.HotelSettings.UploadAttachFileSettings.IsDisplayUploadAttachFile
  } else if (key === '国内酒店配置:上传附件:名称') {
    return PersonalSetting.HotelSettings && PersonalSetting.HotelSettings.UploadAttachFileSettings && PersonalSetting.HotelSettings.UploadAttachFileSettings.Name
  } else if (key === '国内酒店配置:上传附件:必填') {
    return PersonalSetting.HotelSettings && PersonalSetting.HotelSettings.UploadAttachFileSettings && PersonalSetting.HotelSettings.UploadAttachFileSettings.IsRequiredUploadAttachFile
  } else if (key === '国内酒店配置:正常订单:查询顺序') {
    return Number(PersonalSetting.HotelSettings.SearchSettings.SearchOrder)
    // return 1
  // ============================================================================================
  } else if (key === '国际机票配置:正常订单:授权码名称') {
    /** 授权码名称默认读取国内正单的配置 */
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.NormalSettings && PersonalSetting.FlightSettings.NormalSettings.AuthorizationCodeSettings && PersonalSetting.FlightSettings.NormalSettings.AuthorizationCodeSettings.AuthorizationCodeName
  } else if (key === '国际机票配置:正常订单:授权码:展示') {
    return PersonalSetting.IntlFlightSettings && PersonalSetting.IntlFlightSettings.NormalSettings && PersonalSetting.IntlFlightSettings.NormalSettings.AuthorizationCodeSettings && PersonalSetting.IntlFlightSettings.NormalSettings.AuthorizationCodeSettings.IsDisplayAuthorizationCode
  } else if (key === '国际机票配置:正常订单:授权码:必填') {
    return PersonalSetting.IntlFlightSettings && PersonalSetting.IntlFlightSettings.NormalSettings && PersonalSetting.IntlFlightSettings.NormalSettings.AuthorizationCodeSettings && PersonalSetting.IntlFlightSettings.NormalSettings.AuthorizationCodeSettings.IsRequiredAuthorizationCode
  } else if (key === '国际机票配置:正常订单:授权码:校验') {
    return PersonalSetting.IntlFlightSettings && PersonalSetting.IntlFlightSettings.NormalSettings && PersonalSetting.IntlFlightSettings.NormalSettings.AuthorizationCodeSettings && PersonalSetting.IntlFlightSettings.NormalSettings.AuthorizationCodeSettings.IsCheckAuthorizationCode
  } else if (key === '国际机票配置:正常订单:联系人出票信息:邮箱') {
    return PersonalSetting.IntlFlightSettings && PersonalSetting.IntlFlightSettings.NormalSettings && PersonalSetting.IntlFlightSettings.NormalSettings.ContactIssuedMessageSettings && PersonalSetting.IntlFlightSettings.NormalSettings.ContactIssuedMessageSettings.IsSendEmail
  } else if (key === '国际机票配置:正常订单:联系人出票信息:短信') {
    return PersonalSetting.IntlFlightSettings && PersonalSetting.IntlFlightSettings.NormalSettings && PersonalSetting.IntlFlightSettings.NormalSettings.ContactIssuedMessageSettings && PersonalSetting.IntlFlightSettings.NormalSettings.ContactIssuedMessageSettings.IsSendSms
  } else if (key === '国际机票配置:正常订单:联系人出票信息:微信') {
    return PersonalSetting.IntlFlightSettings && PersonalSetting.IntlFlightSettings.NormalSettings && PersonalSetting.IntlFlightSettings.NormalSettings.ContactIssuedMessageSettings && PersonalSetting.IntlFlightSettings.NormalSettings.ContactIssuedMessageSettings.IsSendWechat
  } else if (key === '国际机票配置:正常订单:自定义项:名称') {
    return PersonalSetting.IntlFlightSettings.NormalSettings.CustomItemSettings.CustomItemName
  } else if (key === '国际机票配置:正常订单:自定义项:展示') {
    return PersonalSetting.IntlFlightSettings && PersonalSetting.IntlFlightSettings.NormalSettings && PersonalSetting.IntlFlightSettings.NormalSettings.CustomItemSettings && PersonalSetting.IntlFlightSettings.NormalSettings.CustomItemSettings.IsDisplayCustomItem
  } else if (key === '国际机票配置:正常订单:自定义项:必填') {
    return PersonalSetting.IntlFlightSettings && PersonalSetting.IntlFlightSettings.NormalSettings && PersonalSetting.IntlFlightSettings.NormalSettings.CustomItemSettings && PersonalSetting.IntlFlightSettings.NormalSettings.CustomItemSettings.IsRequiredCustomItem
  } else if (key === '国际机票配置:正常订单:保险:绑定保险') {
    return PersonalSetting.IntlFlightSettings && PersonalSetting.IntlFlightSettings.NormalSettings && PersonalSetting.IntlFlightSettings.NormalSettings.InsuranceSettings && PersonalSetting.IntlFlightSettings.NormalSettings.InsuranceSettings.IsBindAccidentInsurance
  } else if (key === '国际机票配置:正常订单:保险:赠送保险') {
    return PersonalSetting.IntlFlightSettings && PersonalSetting.IntlFlightSettings.NormalSettings && PersonalSetting.IntlFlightSettings.NormalSettings.InsuranceSettings && PersonalSetting.IntlFlightSettings.NormalSettings.InsuranceSettings.IsFreeInsurance
  } else if (key === '国际机票配置:正常订单:乘客出票信息:邮箱') {
    return PersonalSetting.IntlFlightSettings && PersonalSetting.IntlFlightSettings.NormalSettings && PersonalSetting.IntlFlightSettings.NormalSettings.PassengerIssuedMessageSettings && PersonalSetting.IntlFlightSettings.NormalSettings.PassengerIssuedMessageSettings.IsSendEmail
  } else if (key === '国际机票配置:正常订单:乘客出票信息:短信') {
    return PersonalSetting.IntlFlightSettings && PersonalSetting.IntlFlightSettings.NormalSettings && PersonalSetting.IntlFlightSettings.NormalSettings.PassengerIssuedMessageSettings && PersonalSetting.IntlFlightSettings.NormalSettings.PassengerIssuedMessageSettings.IsSendSms
  } else if (key === '国际机票配置:正常订单:乘客出票信息:微信') {
    return PersonalSetting.IntlFlightSettings && PersonalSetting.IntlFlightSettings.NormalSettings && PersonalSetting.IntlFlightSettings.NormalSettings.PassengerIssuedMessageSettings && PersonalSetting.IntlFlightSettings.NormalSettings.PassengerIssuedMessageSettings.IsSendWechat
  } else if (key === '国际机票配置:正常订单:差旅目的:展示') {
    return PersonalSetting.IntlFlightSettings && PersonalSetting.IntlFlightSettings.NormalSettings && PersonalSetting.IntlFlightSettings.NormalSettings.PurposeSettings && PersonalSetting.IntlFlightSettings.NormalSettings.PurposeSettings.IsDisplayPurpose
  } else if (key === '国际机票配置:正常订单:差旅目的:必填') {
    return PersonalSetting.IntlFlightSettings && PersonalSetting.IntlFlightSettings.NormalSettings && PersonalSetting.IntlFlightSettings.NormalSettings.PurposeSettings && PersonalSetting.IntlFlightSettings.NormalSettings.PurposeSettings.IsRequiredPurpose
  } else if (key === '国际机票配置:正常订单:差旅目的:类型') {
    return PersonalSetting.IntlFlightSettings && PersonalSetting.IntlFlightSettings.NormalSettings && PersonalSetting.IntlFlightSettings.NormalSettings.PurposeSettings ? Number(PersonalSetting.IntlFlightSettings.NormalSettings.PurposeSettings.PurposeType) : null
  } else if (key === '国际机票配置:正常订单:审批:需要审批') {
    return PersonalSetting.IntlFlightSettings && PersonalSetting.IntlFlightSettings.NormalSettings &&
      PersonalSetting.IntlFlightSettings.NormalSettings.VettingSettings && PersonalSetting.IntlFlightSettings.NormalSettings.VettingSettings.IsEnableVetting
  } else if (key === '国际机票配置:正常订单:上传附件:展示') {
    return PersonalSetting.IntlFlightSettings && PersonalSetting.IntlFlightSettings.NormalSettings &&
      PersonalSetting.IntlFlightSettings.NormalSettings.UploadAttachFileSettings && PersonalSetting.IntlFlightSettings.NormalSettings.UploadAttachFileSettings.IsDisplayUploadAttachFile
  } else if (key === '国际机票配置:正常订单:上传附件:名称') {
    return PersonalSetting.IntlFlightSettings && PersonalSetting.IntlFlightSettings.NormalSettings &&
      PersonalSetting.IntlFlightSettings.NormalSettings.UploadAttachFileSettings && PersonalSetting.IntlFlightSettings.NormalSettings.UploadAttachFileSettings.Name
  } else if (key === '国际机票配置:正常订单:上传附件:必填') {
    return PersonalSetting.IntlFlightSettings && PersonalSetting.IntlFlightSettings.NormalSettings &&
      PersonalSetting.IntlFlightSettings.NormalSettings.UploadAttachFileSettings && PersonalSetting.IntlFlightSettings.NormalSettings.UploadAttachFileSettings.IsRequiredUploadAttachFile
  } else if (key === '国际机票配置:正常订单:审批:审批类型') {
    return Number(PersonalSetting.IntlFlightSettings.NormalSettings.VettingSettings.VettingType)
  } else if (key === '国际机票配置:正常订单:查询顺序') {
    return Number(PersonalSetting.IntlFlightSettings.NormalSettings.SearchSettings.SearchOrder)
  // ============================================================================================
  } else if (key === '国际机票配置:改期订单:授权码名称') {
    /** 授权码名称默认读取国内正单的配置 */
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.NormalSettings && PersonalSetting.FlightSettings.NormalSettings.AuthorizationCodeSettings && PersonalSetting.FlightSettings.NormalSettings.AuthorizationCodeSettings.AuthorizationCodeName
  } else if (key === '国际机票配置:改期订单:授权码:展示') {
    return PersonalSetting.IntlFlightSettings && PersonalSetting.IntlFlightSettings.ChangeSettings && PersonalSetting.IntlFlightSettings.ChangeSettings.AuthorizationCodeSettings && PersonalSetting.IntlFlightSettings.ChangeSettings.AuthorizationCodeSettings.IsDisplayAuthorizationCode
  } else if (key === '国际机票配置:改期订单:授权码:必填') {
    return PersonalSetting.IntlFlightSettings && PersonalSetting.IntlFlightSettings.ChangeSettings && PersonalSetting.IntlFlightSettings.ChangeSettings.AuthorizationCodeSettings && PersonalSetting.IntlFlightSettings.ChangeSettings.AuthorizationCodeSettings.IsRequiredAuthorizationCode
  } else if (key === '国际机票配置:改期订单:授权码:校验') {
    return PersonalSetting.IntlFlightSettings && PersonalSetting.IntlFlightSettings.ChangeSettings && PersonalSetting.IntlFlightSettings.ChangeSettings.AuthorizationCodeSettings && PersonalSetting.IntlFlightSettings.ChangeSettings.AuthorizationCodeSettings.IsCheckAuthorizationCode
  } else if (key === '国际机票配置:改期订单:联系人出票信息:邮箱') {
    return PersonalSetting.IntlFlightSettings && PersonalSetting.IntlFlightSettings.ChangeSettings && PersonalSetting.IntlFlightSettings.ChangeSettings.ContactIssuedMessageSettings && PersonalSetting.IntlFlightSettings.ChangeSettings.ContactIssuedMessageSettings.IsSendEmail
  } else if (key === '国际机票配置:改期订单:联系人出票信息:短信') {
    return PersonalSetting.IntlFlightSettings && PersonalSetting.IntlFlightSettings.ChangeSettings && PersonalSetting.IntlFlightSettings.ChangeSettings.ContactIssuedMessageSettings && PersonalSetting.IntlFlightSettings.ChangeSettings.ContactIssuedMessageSettings.IsSendSms
  } else if (key === '国际机票配置:改期订单:联系人出票信息:微信') {
    return PersonalSetting.IntlFlightSettings && PersonalSetting.IntlFlightSettings.ChangeSettings && PersonalSetting.IntlFlightSettings.ChangeSettings.ContactIssuedMessageSettings && PersonalSetting.IntlFlightSettings.ChangeSettings.ContactIssuedMessageSettings.IsSendWechat
  } else if (key === '国际机票配置:改期订单:自定义项:名称') {
    return PersonalSetting.IntlFlightSettings.ChangeSettings.CustomItemSettings.CustomItemName
  } else if (key === '国际机票配置:改期订单:自定义项:展示') {
    return PersonalSetting.IntlFlightSettings && PersonalSetting.IntlFlightSettings.ChangeSettings && PersonalSetting.IntlFlightSettings.ChangeSettings.CustomItemSettings && PersonalSetting.IntlFlightSettings.ChangeSettings.CustomItemSettings.IsDisplayCustomItem
  } else if (key === '国际机票配置:改期订单:自定义项:必填') {
    return PersonalSetting.IntlFlightSettings && PersonalSetting.IntlFlightSettings.ChangeSettings && PersonalSetting.IntlFlightSettings.ChangeSettings.CustomItemSettings && PersonalSetting.IntlFlightSettings.ChangeSettings.CustomItemSettings.IsRequiredCustomItem
  } else if (key === '国际机票配置:改期订单:乘客出票信息:邮箱') {
    return PersonalSetting.IntlFlightSettings && PersonalSetting.IntlFlightSettings.ChangeSettings && PersonalSetting.IntlFlightSettings.ChangeSettings.PassengerIssuedMessageSettings && PersonalSetting.IntlFlightSettings.ChangeSettings.PassengerIssuedMessageSettings.IsSendEmail
  } else if (key === '国际机票配置:改期订单:乘客出票信息:短信') {
    return PersonalSetting.IntlFlightSettings && PersonalSetting.IntlFlightSettings.ChangeSettings && PersonalSetting.IntlFlightSettings.ChangeSettings.PassengerIssuedMessageSettings && PersonalSetting.IntlFlightSettings.ChangeSettings.PassengerIssuedMessageSettings.IsSendSms
  } else if (key === '国际机票配置:改期订单:乘客出票信息:微信') {
    return PersonalSetting.IntlFlightSettings && PersonalSetting.IntlFlightSettings.ChangeSettings && PersonalSetting.IntlFlightSettings.ChangeSettings.PassengerIssuedMessageSettings && PersonalSetting.IntlFlightSettings.ChangeSettings.PassengerIssuedMessageSettings.IsSendWechat
  } else if (key === '国际机票配置:改期订单:差旅目的:展示') {
    return PersonalSetting.IntlFlightSettings && PersonalSetting.IntlFlightSettings.ChangeSettings && PersonalSetting.IntlFlightSettings.ChangeSettings.PurposeSettings && PersonalSetting.IntlFlightSettings.ChangeSettings.PurposeSettings.IsDisplayPurpose
  } else if (key === '国际机票配置:改期订单:差旅目的:必填') {
    return PersonalSetting.IntlFlightSettings && PersonalSetting.IntlFlightSettings.ChangeSettings && PersonalSetting.IntlFlightSettings.ChangeSettings.PurposeSettings && PersonalSetting.IntlFlightSettings.ChangeSettings.PurposeSettings.IsRequiredPurpose
  } else if (key === '国际机票配置:改期订单:差旅目的:类型') {
    return PersonalSetting.IntlFlightSettings && PersonalSetting.IntlFlightSettings.ChangeSettings &&
      PersonalSetting.IntlFlightSettings.ChangeSettings.PurposeSettings ? Number(PersonalSetting.IntlFlightSettings.ChangeSettings.PurposeSettings.PurposeType) : null
  } else if (key === '国际机票配置:改期订单:改期原因:展示') {
    return PersonalSetting.IntlFlightSettings && PersonalSetting.IntlFlightSettings.ChangeSettings && PersonalSetting.IntlFlightSettings.ChangeSettings.IsDisplayChangeReason
  } else if (key === '国际机票配置:改期订单:改期原因:必填') {
    return PersonalSetting.IntlFlightSettings && PersonalSetting.IntlFlightSettings.ChangeSettings && PersonalSetting.IntlFlightSettings.ChangeSettings.IsRequiredChangeReason
  } else if (key === '国际机票配置:改期订单:改期原因:其他') {
    return PersonalSetting.IntlFlightSettings && PersonalSetting.IntlFlightSettings.ChangeSettings && PersonalSetting.IntlFlightSettings.ChangeSettings.IsDisplayOtherItem
  } else if (key === '国际机票配置:改期订单:审批:需要审批') {
    return PersonalSetting.IntlFlightSettings && PersonalSetting.IntlFlightSettings.ChangeSettings && PersonalSetting.IntlFlightSettings.ChangeSettings.VettingSettings && PersonalSetting.IntlFlightSettings.ChangeSettings.VettingSettings.IsEnableVetting
  } else if (key === '国际机票配置:改期订单:上传附件:展示') {
    return PersonalSetting.IntlFlightSettings && PersonalSetting.IntlFlightSettings.ChangeSettings &&
      PersonalSetting.IntlFlightSettings.ChangeSettings.UploadAttachFileSettings && PersonalSetting.IntlFlightSettings.ChangeSettings.UploadAttachFileSettings.IsDisplayUploadAttachFile
  } else if (key === '国际机票配置:改期订单:上传附件:名称') {
    return PersonalSetting.IntlFlightSettings && PersonalSetting.IntlFlightSettings.ChangeSettings &&
      PersonalSetting.IntlFlightSettings.ChangeSettings.UploadAttachFileSettings && PersonalSetting.IntlFlightSettings.ChangeSettings.UploadAttachFileSettings.Name
  } else if (key === '国际机票配置:改期订单:上传附件:必填') {
    return PersonalSetting.IntlFlightSettings && PersonalSetting.IntlFlightSettings.ChangeSettings &&
      PersonalSetting.IntlFlightSettings.ChangeSettings.UploadAttachFileSettings && PersonalSetting.IntlFlightSettings.ChangeSettings.UploadAttachFileSettings.IsRequiredUploadAttachFile
  } else if (key === '国际机票配置:改期订单:审批:审批类型') {
    return Number(PersonalSetting.IntlFlightSettings.ChangeSettings.VettingSettings.VettingType)
  // ============================================================================================
  } else if (key === '国际机票配置:退票订单:联系人退票信息:邮箱') {
    return PersonalSetting.IntlFlightSettings && PersonalSetting.IntlFlightSettings.RefundSettings && PersonalSetting.IntlFlightSettings.RefundSettings.ContactRefundMessageSettings && PersonalSetting.IntlFlightSettings.RefundSettings.ContactRefundMessageSettings.IsSendEmail
  } else if (key === '国际机票配置:退票订单:联系人退票信息:短信') {
    return PersonalSetting.IntlFlightSettings && PersonalSetting.IntlFlightSettings.RefundSettings && PersonalSetting.IntlFlightSettings.RefundSettings.ContactRefundMessageSettings && PersonalSetting.IntlFlightSettings.RefundSettings.ContactRefundMessageSettings.IsSendSms
  } else if (key === '国际机票配置:退票订单:联系人退票信息:微信') {
    return PersonalSetting.IntlFlightSettings && PersonalSetting.IntlFlightSettings.RefundSettings && PersonalSetting.IntlFlightSettings.RefundSettings.ContactRefundMessageSettings && PersonalSetting.IntlFlightSettings.RefundSettings.ContactRefundMessageSettings.IsSendWechat
  } else if (key === '国际机票配置:退票订单:乘客退票信息:邮箱') {
    return PersonalSetting.IntlFlightSettings && PersonalSetting.IntlFlightSettings.RefundSettings && PersonalSetting.IntlFlightSettings.RefundSettings.PassengerRefundMessageSettings && PersonalSetting.IntlFlightSettings.RefundSettings.PassengerRefundMessageSettings.IsSendEmail
  } else if (key === '国际机票配置:退票订单:乘客退票信息:短信') {
    return PersonalSetting.IntlFlightSettings && PersonalSetting.IntlFlightSettings.RefundSettings && PersonalSetting.IntlFlightSettings.RefundSettings.PassengerRefundMessageSettings && PersonalSetting.IntlFlightSettings.RefundSettings.PassengerRefundMessageSettings.IsSendSms
  } else if (key === '国际机票配置:退票订单:乘客退票信息:微信') {
    return PersonalSetting.IntlFlightSettings && PersonalSetting.IntlFlightSettings.RefundSettings && PersonalSetting.IntlFlightSettings.RefundSettings.PassengerRefundMessageSettings && PersonalSetting.IntlFlightSettings.RefundSettings.PassengerRefundMessageSettings.IsSendWechat
  } else if (key === '国际机票配置:退票订单:审批:需要审批') {
    return PersonalSetting.IntlFlightSettings && PersonalSetting.IntlFlightSettings.RefundSettings && PersonalSetting.IntlFlightSettings.RefundSettings.VettingSettings && PersonalSetting.IntlFlightSettings.RefundSettings.VettingSettings.IsEnableVetting
  } else if (key === '国际机票配置:退票订单:审批:审批类型') {
    return Number(PersonalSetting.IntlFlightSettings.RefundSettings.VettingSettings.VettingType)
  } else if (key === '国际机票配置:退票订单:退票原因:展示') {
    return PersonalSetting.IntlFlightSettings &&
      PersonalSetting.IntlFlightSettings.RefundSettings &&
      PersonalSetting.IntlFlightSettings.RefundSettings.IsDisplayRefundReason
  } else if (key === '国际机票配置:退票订单:退票原因:必填') {
    return PersonalSetting.IntlFlightSettings &&
      PersonalSetting.IntlFlightSettings.RefundSettings &&
      PersonalSetting.IntlFlightSettings.RefundSettings.IsRequiredRefundReason
  } else if (key === '国际机票配置:退票订单:退票原因:其他') {
    return PersonalSetting.IntlFlightSettings &&
      PersonalSetting.IntlFlightSettings.RefundSettings &&
      PersonalSetting.IntlFlightSettings.RefundSettings.IsDisplayOtherItem
  } else if (key === '国际机票配置:退票订单:授权码:名称') {
    /** 授权码名称默认读取国内正单的配置 */
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.NormalSettings && PersonalSetting.FlightSettings.NormalSettings.AuthorizationCodeSettings && PersonalSetting.FlightSettings.NormalSettings.AuthorizationCodeSettings.AuthorizationCodeName
  } else if (key === '国际机票配置:退票订单:授权码:展示') {
    return PersonalSetting.IntlFlightSettings &&
      PersonalSetting.IntlFlightSettings.RefundSettings &&
      PersonalSetting.IntlFlightSettings.RefundSettings.AuthorizationCodeSettings &&
      PersonalSetting.IntlFlightSettings.RefundSettings.AuthorizationCodeSettings.IsDisplayAuthorizationCode
  } else if (key === '国际机票配置:退票订单:授权码:必填') {
    return PersonalSetting.IntlFlightSettings &&
      PersonalSetting.IntlFlightSettings.RefundSettings &&
      PersonalSetting.IntlFlightSettings.RefundSettings.AuthorizationCodeSettings &&
      PersonalSetting.IntlFlightSettings.RefundSettings.AuthorizationCodeSettings.IsRequiredAuthorizationCode
  } else if (key === '国际机票配置:退票订单:授权码:校验') {
    return PersonalSetting.IntlFlightSettings &&
      PersonalSetting.IntlFlightSettings.RefundSettings &&
      PersonalSetting.IntlFlightSettings.RefundSettings.AuthorizationCodeSettings &&
      PersonalSetting.IntlFlightSettings.RefundSettings.AuthorizationCodeSettings.IsCheckAuthorizationCode
  } else if (key === '国际机票配置:退票订单:上传附件:名称') {
    return PersonalSetting.IntlFlightSettings &&
      PersonalSetting.IntlFlightSettings.RefundSettings &&
      PersonalSetting.IntlFlightSettings.RefundSettings.UploadAttachFileSettings &&
      PersonalSetting.IntlFlightSettings.RefundSettings.UploadAttachFileSettings.Name
  } else if (key === '国际机票配置:退票订单:上传附件:展示') {
    return PersonalSetting.IntlFlightSettings &&
      PersonalSetting.IntlFlightSettings.RefundSettings &&
      PersonalSetting.IntlFlightSettings.RefundSettings.UploadAttachFileSettings &&
      PersonalSetting.IntlFlightSettings.RefundSettings.UploadAttachFileSettings.IsDisplayUploadAttachFile
  } else if (key === '国际机票配置:退票订单:上传附件:必填') {
    return PersonalSetting.IntlFlightSettings &&
      PersonalSetting.IntlFlightSettings.RefundSettings &&
      PersonalSetting.IntlFlightSettings.RefundSettings.UploadAttachFileSettings &&
      PersonalSetting.IntlFlightSettings.RefundSettings.UploadAttachFileSettings.IsRequiredUploadAttachFile
  } else if (key === '国际机票配置:展示票号') {
    return PersonalSetting.IntlFlightSettings && PersonalSetting.IntlFlightSettings.IsDisplayTicketNo
  // ============================================================================================
  } else if (key === '国际酒店配置:授权码名称') {
    /** 授权码名称默认读取国内正单的配置 */
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.NormalSettings && PersonalSetting.FlightSettings.NormalSettings.AuthorizationCodeSettings && PersonalSetting.FlightSettings.NormalSettings.AuthorizationCodeSettings.AuthorizationCodeName
  } else if (key === '国际酒店配置:授权码:展示') {
    return PersonalSetting.IntlHotelSettings && PersonalSetting.IntlHotelSettings.AuthorizationCodeSettings && PersonalSetting.IntlHotelSettings.AuthorizationCodeSettings.IsDisplayAuthorizationCode
  } else if (key === '国际酒店配置:授权码:必填') {
    return PersonalSetting.IntlHotelSettings && PersonalSetting.IntlHotelSettings.AuthorizationCodeSettings && PersonalSetting.IntlHotelSettings.AuthorizationCodeSettings.IsRequiredAuthorizationCode
  } else if (key === '国际酒店配置:授权码:校验') {
    return PersonalSetting.IntlHotelSettings && PersonalSetting.IntlHotelSettings.AuthorizationCodeSettings && PersonalSetting.IntlHotelSettings.AuthorizationCodeSettings.IsCheckAuthorizationCode
  } else if (key === '国际酒店配置:联系人确认信息:邮箱') {
    return PersonalSetting.IntlHotelSettings && PersonalSetting.IntlHotelSettings.ContactConfirmMessageSettings && PersonalSetting.IntlHotelSettings.ContactConfirmMessageSettings.IsSendEmail
  } else if (key === '国际酒店配置:联系人确认信息:短信') {
    return PersonalSetting.IntlHotelSettings && PersonalSetting.IntlHotelSettings.ContactConfirmMessageSettings && PersonalSetting.IntlHotelSettings.ContactConfirmMessageSettings.IsSendSms
  } else if (key === '国际酒店配置:联系人确认信息:微信') {
    return PersonalSetting.IntlHotelSettings && PersonalSetting.IntlHotelSettings.ContactConfirmMessageSettings && PersonalSetting.IntlHotelSettings.ContactConfirmMessageSettings.IsSendWechat
  } else if (key === '国际酒店配置:自定义项:名称') {
    return PersonalSetting.IntlHotelSettings.CustomItemSettings.CustomItemName
  } else if (key === '国际酒店配置:自定义项:展示') {
    return PersonalSetting.IntlHotelSettings && PersonalSetting.IntlHotelSettings.CustomItemSettings && PersonalSetting.IntlHotelSettings.CustomItemSettings.IsDisplayCustomItem
  } else if (key === '国际酒店配置:自定义项:必填') {
    return PersonalSetting.IntlHotelSettings && PersonalSetting.IntlHotelSettings.CustomItemSettings && PersonalSetting.IntlHotelSettings.CustomItemSettings.IsRequiredCustomItem
  } else if (key === '国际酒店配置:入住人确认信息:邮箱') {
    return PersonalSetting.IntlHotelSettings && PersonalSetting.IntlHotelSettings.GuestConfirmMessageSettings && PersonalSetting.IntlHotelSettings.GuestConfirmMessageSettings.IsSendEmail
  } else if (key === '国际酒店配置:入住人确认信息:短信') {
    return PersonalSetting.IntlHotelSettings && PersonalSetting.IntlHotelSettings.GuestConfirmMessageSettings && PersonalSetting.IntlHotelSettings.GuestConfirmMessageSettings.IsSendSms
  } else if (key === '国际酒店配置:入住人确认信息:微信') {
    return PersonalSetting.IntlHotelSettings && PersonalSetting.IntlHotelSettings.GuestConfirmMessageSettings && PersonalSetting.IntlHotelSettings.GuestConfirmMessageSettings.IsSendWechat
  } else if (key === '国际酒店配置:差旅目的:展示') {
    return PersonalSetting.IntlHotelSettings && PersonalSetting.IntlHotelSettings.PurposeSettings && PersonalSetting.IntlHotelSettings.PurposeSettings.IsDisplayPurpose
  } else if (key === '国际酒店配置:差旅目的:必填') {
    return PersonalSetting.IntlHotelSettings && PersonalSetting.IntlHotelSettings.PurposeSettings && PersonalSetting.IntlHotelSettings.PurposeSettings.IsRequiredPurpose
  } else if (key === '国际酒店配置:差旅目的:类型') {
    return PersonalSetting.IntlHotelSettings && PersonalSetting.IntlHotelSettings.PurposeSettings ? Number(PersonalSetting.IntlHotelSettings.PurposeSettings.PurposeType) : null
  } else if (key === '国际酒店配置:审批:需要审批') {
    return PersonalSetting.IntlHotelSettings && PersonalSetting.IntlHotelSettings.VettingSettings && PersonalSetting.IntlHotelSettings.VettingSettings.IsEnableVetting
  } else if (key === '国际酒店配置:上传附件:展示') {
    return PersonalSetting.IntlHotelSettings && PersonalSetting.IntlHotelSettings.UploadAttachFileSettings && PersonalSetting.IntlHotelSettings.UploadAttachFileSettings.IsDisplayUploadAttachFile
  } else if (key === '国际酒店配置:上传附件:名称') {
    return PersonalSetting.IntlHotelSettings && PersonalSetting.IntlHotelSettings.UploadAttachFileSettings && PersonalSetting.IntlHotelSettings.UploadAttachFileSettings.Name
  } else if (key === '国际酒店配置:上传附件:必填') {
    return PersonalSetting.IntlHotelSettings && PersonalSetting.IntlHotelSettings.UploadAttachFileSettings && PersonalSetting.IntlHotelSettings.UploadAttachFileSettings.IsRequiredUploadAttachFile
  } else if (key === '国际酒店配置:审批:审批类型') {
    return Number(PersonalSetting.IntlHotelSettings.VettingSettings.VettingType)
  } else if (key === '国际酒店配置:正常订单:查询顺序') {
    return Number(PersonalSetting.IntlHotelSettings.SearchSettings.SearchOrder)
    // return 1
  // ============================================================================================
  } else if (key === '火车票配置:正常订单:授权码名称') {
    /** 授权码名称默认读取国内正单的配置 */
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.NormalSettings && PersonalSetting.FlightSettings.NormalSettings.AuthorizationCodeSettings && PersonalSetting.FlightSettings.NormalSettings.AuthorizationCodeSettings.AuthorizationCodeName
  } else if (key === '火车票配置:正常订单:授权码:展示') {
    return PersonalSetting.TrainSettings && PersonalSetting.TrainSettings.NormalSettings && PersonalSetting.TrainSettings.NormalSettings.AuthorizationCodeSettings && PersonalSetting.TrainSettings.NormalSettings.AuthorizationCodeSettings.IsDisplayAuthorizationCode
  } else if (key === '火车票配置:正常订单:授权码:必填') {
    return PersonalSetting.TrainSettings && PersonalSetting.TrainSettings.NormalSettings && PersonalSetting.TrainSettings.NormalSettings.AuthorizationCodeSettings && PersonalSetting.TrainSettings.NormalSettings.AuthorizationCodeSettings.IsRequiredAuthorizationCode
  } else if (key === '火车票配置:正常订单:授权码:校验') {
    return PersonalSetting.TrainSettings && PersonalSetting.TrainSettings.NormalSettings && PersonalSetting.TrainSettings.NormalSettings.AuthorizationCodeSettings && PersonalSetting.TrainSettings.NormalSettings.AuthorizationCodeSettings.IsCheckAuthorizationCode
  } else if (key === '火车票配置:正常订单:联系人出票信息:邮箱') {
    return PersonalSetting.TrainSettings && PersonalSetting.TrainSettings.NormalSettings && PersonalSetting.TrainSettings.NormalSettings.ContactIssuedMessageSettings && PersonalSetting.TrainSettings.NormalSettings.ContactIssuedMessageSettings.IsSendEmail
  } else if (key === '火车票配置:正常订单:联系人出票信息:短信') {
    return PersonalSetting.TrainSettings && PersonalSetting.TrainSettings.NormalSettings && PersonalSetting.TrainSettings.NormalSettings.ContactIssuedMessageSettings && PersonalSetting.TrainSettings.NormalSettings.ContactIssuedMessageSettings.IsSendSms
  } else if (key === '火车票配置:正常订单:联系人出票信息:微信') {
    return PersonalSetting.TrainSettings && PersonalSetting.TrainSettings.NormalSettings && PersonalSetting.TrainSettings.NormalSettings.ContactIssuedMessageSettings && PersonalSetting.TrainSettings.NormalSettings.ContactIssuedMessageSettings.IsSendWechat
  } else if (key === '火车票配置:正常订单:乘客出票信息:邮箱') {
    return PersonalSetting.TrainSettings && PersonalSetting.TrainSettings.NormalSettings && PersonalSetting.TrainSettings.NormalSettings.PassengerIssuedMessageSettings && PersonalSetting.TrainSettings.NormalSettings.PassengerIssuedMessageSettings.IsSendEmail
  } else if (key === '火车票配置:正常订单:乘客出票信息:短信') {
    return PersonalSetting.TrainSettings && PersonalSetting.TrainSettings.NormalSettings && PersonalSetting.TrainSettings.NormalSettings.PassengerIssuedMessageSettings && PersonalSetting.TrainSettings.NormalSettings.PassengerIssuedMessageSettings.IsSendSms
  } else if (key === '火车票配置:正常订单:乘客出票信息:微信') {
    return PersonalSetting.TrainSettings && PersonalSetting.TrainSettings.NormalSettings && PersonalSetting.TrainSettings.NormalSettings.PassengerIssuedMessageSettings && PersonalSetting.TrainSettings.NormalSettings.PassengerIssuedMessageSettings.IsSendWechat
  } else if (key === '火车票配置:正常订单:自定义项:名称') {
    return PersonalSetting.TrainSettings.NormalSettings.CustomItemSettings.CustomItemName
  } else if (key === '火车票配置:正常订单:自定义项:展示') {
    return PersonalSetting.TrainSettings && PersonalSetting.TrainSettings.NormalSettings && PersonalSetting.TrainSettings.NormalSettings.CustomItemSettings && PersonalSetting.TrainSettings.NormalSettings.CustomItemSettings.IsDisplayCustomItem
  } else if (key === '火车票配置:正常订单:自定义项:必填') {
    return PersonalSetting.TrainSettings && PersonalSetting.TrainSettings.NormalSettings && PersonalSetting.TrainSettings.NormalSettings.CustomItemSettings && PersonalSetting.TrainSettings.NormalSettings.CustomItemSettings.IsRequiredCustomItem
  } else if (key === '火车票配置:正常订单:差旅目的:展示') {
    return PersonalSetting.TrainSettings && PersonalSetting.TrainSettings.NormalSettings && PersonalSetting.TrainSettings.NormalSettings.PurposeSettings && PersonalSetting.TrainSettings.NormalSettings.PurposeSettings.IsDisplayPurpose
  } else if (key === '火车票配置:正常订单:差旅目的:必填') {
    return PersonalSetting.TrainSettings && PersonalSetting.TrainSettings.NormalSettings && PersonalSetting.TrainSettings.NormalSettings.PurposeSettings && PersonalSetting.TrainSettings.NormalSettings.PurposeSettings.IsRequiredPurpose
  } else if (key === '火车票配置:正常订单:差旅目的:类型') {
    return PersonalSetting.TrainSettings && PersonalSetting.TrainSettings.NormalSettings && PersonalSetting.TrainSettings.NormalSettings.PurposeSettings ? Number(PersonalSetting.TrainSettings.NormalSettings.PurposeSettings.PurposeType) : null
  } else if (key === '火车票配置:正常订单:审批:需要审批') {
    return PersonalSetting.TrainSettings && PersonalSetting.TrainSettings.NormalSettings &&
      PersonalSetting.TrainSettings.NormalSettings.VettingSettings && PersonalSetting.TrainSettings.NormalSettings.VettingSettings.IsEnableVetting
  } else if (key === '火车票配置:正常订单:上传附件:展示') {
    return PersonalSetting.TrainSettings && PersonalSetting.TrainSettings.NormalSettings &&
      PersonalSetting.TrainSettings.NormalSettings.UploadAttachFileSettings && PersonalSetting.TrainSettings.NormalSettings.UploadAttachFileSettings.IsDisplayUploadAttachFile
  } else if (key === '火车票配置:正常订单:上传附件:名称') {
    return PersonalSetting.TrainSettings && PersonalSetting.TrainSettings.NormalSettings &&
      PersonalSetting.TrainSettings.NormalSettings.UploadAttachFileSettings && PersonalSetting.TrainSettings.NormalSettings.UploadAttachFileSettings.Name
  } else if (key === '火车票配置:正常订单:上传附件:必填') {
    return PersonalSetting.TrainSettings && PersonalSetting.TrainSettings.NormalSettings &&
      PersonalSetting.TrainSettings.NormalSettings.UploadAttachFileSettings && PersonalSetting.TrainSettings.NormalSettings.UploadAttachFileSettings.IsRequiredUploadAttachFile
  } else if (key === '火车票配置:正常订单:审批:审批类型') {
    return Number(PersonalSetting.TrainSettings.NormalSettings.VettingSettings.VettingType)
  } else if (key === '火车票配置:正常订单:查询顺序') {
    return Number(PersonalSetting.TrainSettings.NormalSettings.SearchSettings.SearchOrder)
  // ============================================================================================
  } else if (key === '火车票配置:改期订单:授权码名称') {
    /** 授权码名称默认读取国内正单的配置 */
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.NormalSettings && PersonalSetting.FlightSettings.NormalSettings.AuthorizationCodeSettings && PersonalSetting.FlightSettings.NormalSettings.AuthorizationCodeSettings.AuthorizationCodeName
  } else if (key === '火车票配置:改期订单:授权码:展示') {
    return PersonalSetting.TrainSettings && PersonalSetting.TrainSettings.ChangeSettings && PersonalSetting.TrainSettings.ChangeSettings.AuthorizationCodeSettings && PersonalSetting.TrainSettings.ChangeSettings.AuthorizationCodeSettings.IsDisplayAuthorizationCode
  } else if (key === '火车票配置:改期订单:授权码:必填') {
    return PersonalSetting.TrainSettings && PersonalSetting.TrainSettings.ChangeSettings && PersonalSetting.TrainSettings.ChangeSettings.AuthorizationCodeSettings && PersonalSetting.TrainSettings.ChangeSettings.AuthorizationCodeSettings.IsRequiredAuthorizationCode
  } else if (key === '火车票配置:改期订单:授权码:校验') {
    return PersonalSetting.TrainSettings && PersonalSetting.TrainSettings.ChangeSettings && PersonalSetting.TrainSettings.ChangeSettings.AuthorizationCodeSettings && PersonalSetting.TrainSettings.ChangeSettings.AuthorizationCodeSettings.IsCheckAuthorizationCode
  } else if (key === '火车票配置:改期订单:联系人出票信息:邮箱') {
    return PersonalSetting.TrainSettings && PersonalSetting.TrainSettings.ChangeSettings && PersonalSetting.TrainSettings.ChangeSettings.ContactIssuedMessageSettings && PersonalSetting.TrainSettings.ChangeSettings.ContactIssuedMessageSettings.IsSendEmail
  } else if (key === '火车票配置:改期订单:联系人出票信息:短信') {
    return PersonalSetting.TrainSettings && PersonalSetting.TrainSettings.ChangeSettings && PersonalSetting.TrainSettings.ChangeSettings.ContactIssuedMessageSettings && PersonalSetting.TrainSettings.ChangeSettings.ContactIssuedMessageSettings.IsSendSms
  } else if (key === '火车票配置:改期订单:联系人出票信息:微信') {
    return PersonalSetting.TrainSettings && PersonalSetting.TrainSettings.ChangeSettings && PersonalSetting.TrainSettings.ChangeSettings.ContactIssuedMessageSettings && PersonalSetting.TrainSettings.ChangeSettings.ContactIssuedMessageSettings.IsSendWechat
  } else if (key === '火车票配置:改期订单:乘客出票信息:邮箱') {
    return PersonalSetting.TrainSettings && PersonalSetting.TrainSettings.ChangeSettings && PersonalSetting.TrainSettings.ChangeSettings.PassengerIssuedMessageSettings && PersonalSetting.TrainSettings.ChangeSettings.PassengerIssuedMessageSettings.IsSendEmail
  } else if (key === '火车票配置:改期订单:乘客出票信息:短信') {
    return PersonalSetting.TrainSettings && PersonalSetting.TrainSettings.ChangeSettings && PersonalSetting.TrainSettings.ChangeSettings.PassengerIssuedMessageSettings && PersonalSetting.TrainSettings.ChangeSettings.PassengerIssuedMessageSettings.IsSendSms
  } else if (key === '火车票配置:改期订单:乘客出票信息:微信') {
    return PersonalSetting.TrainSettings && PersonalSetting.TrainSettings.ChangeSettings && PersonalSetting.TrainSettings.ChangeSettings.PassengerIssuedMessageSettings && PersonalSetting.TrainSettings.ChangeSettings.PassengerIssuedMessageSettings.IsSendWechat
  } else if (key === '火车票配置:改期订单:自定义项:名称') {
    return PersonalSetting.TrainSettings.ChangeSettings.CustomItemSettings.CustomItemName
  } else if (key === '火车票配置:改期订单:自定义项:展示') {
    return PersonalSetting.TrainSettings && PersonalSetting.TrainSettings.ChangeSettings && PersonalSetting.TrainSettings.ChangeSettings.CustomItemSettings && PersonalSetting.TrainSettings.ChangeSettings.CustomItemSettings.IsDisplayCustomItem
  } else if (key === '火车票配置:改期订单:自定义项:必填') {
    return PersonalSetting.TrainSettings && PersonalSetting.TrainSettings.ChangeSettings && PersonalSetting.TrainSettings.ChangeSettings.CustomItemSettings && PersonalSetting.TrainSettings.ChangeSettings.CustomItemSettings.IsRequiredCustomItem
  } else if (key === '火车票配置:改期订单:差旅目的:展示') {
    return PersonalSetting.TrainSettings && PersonalSetting.TrainSettings.ChangeSettings && PersonalSetting.TrainSettings.ChangeSettings.PurposeSettings && PersonalSetting.TrainSettings.ChangeSettings.PurposeSettings.IsDisplayPurpose
  } else if (key === '火车票配置:改期订单:差旅目的:必填') {
    return PersonalSetting.TrainSettings && PersonalSetting.TrainSettings.ChangeSettings && PersonalSetting.TrainSettings.ChangeSettings.PurposeSettings && PersonalSetting.TrainSettings.ChangeSettings.PurposeSettings.IsRequiredPurpose
  } else if (key === '火车票配置:改期订单:差旅目的:类型') {
    return PersonalSetting.TrainSettings && PersonalSetting.TrainSettings.ChangeSettings && PersonalSetting.TrainSettings.ChangeSettings.PurposeSettings ? Number(PersonalSetting.TrainSettings.ChangeSettings.PurposeSettings.PurposeType) : null
  } else if (key === '火车票配置:改期订单:审批:需要审批') {
    return PersonalSetting.TrainSettings && PersonalSetting.TrainSettings.ChangeSettings && PersonalSetting.TrainSettings.ChangeSettings.VettingSettings && PersonalSetting.TrainSettings.ChangeSettings.VettingSettings.IsEnableVetting
  } else if (key === '火车票配置:改期订单:审批:审批类型') {
    return Number(PersonalSetting.TrainSettings.ChangeSettings.VettingSettings.VettingType)
  // ============================================================================================
  } else if (key === '火车票配置:退票订单:联系人退票信息:邮箱') {
    return PersonalSetting.TrainSettings && PersonalSetting.TrainSettings.RefundSettings && PersonalSetting.TrainSettings.RefundSettings.ContactRefundMessageSettings && PersonalSetting.TrainSettings.RefundSettings.ContactRefundMessageSettings.IsSendEmail
  } else if (key === '火车票配置:退票订单:联系人退票信息:短信') {
    return PersonalSetting.TrainSettings && PersonalSetting.TrainSettings.RefundSettings && PersonalSetting.TrainSettings.RefundSettings.ContactRefundMessageSettings && PersonalSetting.TrainSettings.RefundSettings.ContactRefundMessageSettings.IsSendSms
  } else if (key === '火车票配置:退票订单:联系人退票信息:微信') {
    return PersonalSetting.TrainSettings && PersonalSetting.TrainSettings.RefundSettings && PersonalSetting.TrainSettings.RefundSettings.ContactRefundMessageSettings && PersonalSetting.TrainSettings.RefundSettings.ContactRefundMessageSettings.IsSendWechat
  } else if (key === '火车票配置:退票订单:乘客退票信息:邮箱') {
    return PersonalSetting.TrainSettings && PersonalSetting.TrainSettings.RefundSettings && PersonalSetting.TrainSettings.RefundSettings.PassengerRefundMessageSettings && PersonalSetting.TrainSettings.RefundSettings.PassengerRefundMessageSettings.IsSendWechat
  } else if (key === '火车票配置:退票订单:乘客退票信息:短信') {
    return PersonalSetting.TrainSettings && PersonalSetting.TrainSettings.RefundSettings && PersonalSetting.TrainSettings.RefundSettings.PassengerRefundMessageSettings && PersonalSetting.TrainSettings.RefundSettings.PassengerRefundMessageSettings.IsSendWechat
  } else if (key === '火车票配置:退票订单:乘客退票信息:微信') {
    return PersonalSetting.TrainSettings && PersonalSetting.TrainSettings.RefundSettings && PersonalSetting.TrainSettings.RefundSettings.PassengerRefundMessageSettings && PersonalSetting.TrainSettings.RefundSettings.PassengerRefundMessageSettings.IsSendWechat
  } else if (key === '火车票配置:退票订单:审批:需要审批') {
    return PersonalSetting.TrainSettings && PersonalSetting.TrainSettings.RefundSettings && PersonalSetting.TrainSettings.RefundSettings.VettingSettings && PersonalSetting.TrainSettings.RefundSettings.VettingSettings.IsEnableVetting
  } else if (key === '火车票配置:退票订单:审批:审批类型') {
    return Number(PersonalSetting.TrainSettings.RefundSettings.VettingSettings.VettingType)
  // ============================================================================================
  } else if (key === '用车配置:差旅目的:展示') {
    return false // 用车暂时没有差旅目的 默认写死
    // return PersonalSetting.CarSettings && PersonalSetting.CarSettings.PurposeSettings && PersonalSetting.CarSettings.PurposeSettings.IsDisplayPurpose
  } else if (key === '用车配置:差旅目的:必填') {
    return false // 用车暂时没有差旅目的 默认写死
    // return PersonalSetting.CarSettings && PersonalSetting.CarSettings.PurposeSettings && PersonalSetting.CarSettings.PurposeSettings.IsRequiredPurpose
  } else if (key === '用车配置:差旅目的:类型') {
    return PersonalSetting.CarSettings && PersonalSetting.CarSettings.PurposeSettings ? Number(PersonalSetting.CarSettings.PurposeSettings.PurposeType) : null
  } else if (key === '用车配置:自定义项:展示') {
    return PersonalSetting.CarSettings && PersonalSetting.CarSettings.CustomItemSettings && PersonalSetting.CarSettings.CustomItemSettings.IsDisplayCustomItem
  } else if (key === '用车配置:自定义项:名称') {
    return PersonalSetting.CarSettings && PersonalSetting.CarSettings.CustomItemSettings && PersonalSetting.CarSettings.CustomItemSettings.CustomItemName
  } else if (key === '用车配置:自定义项:必填') {
    return PersonalSetting.CarSettings && PersonalSetting.CarSettings.CustomItemSettings && PersonalSetting.CarSettings.CustomItemSettings.IsRequiredCustomItem
  } else if (key === '用车配置:授权码名称') {
    /** 授权码名称默认读取国内正单的配置 */
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.NormalSettings && PersonalSetting.FlightSettings.NormalSettings.AuthorizationCodeSettings && PersonalSetting.FlightSettings.NormalSettings.AuthorizationCodeSettings.AuthorizationCodeName
  } else if (key === '用车配置:授权码:展示') {
    return PersonalSetting.CarSettings && PersonalSetting.CarSettings.AuthorizationCodeSettings && PersonalSetting.CarSettings.AuthorizationCodeSettings.IsDisplayAuthorizationCode
  } else if (key === '用车配置:授权码:必填') {
    return PersonalSetting.CarSettings && PersonalSetting.CarSettings.AuthorizationCodeSettings && PersonalSetting.CarSettings.AuthorizationCodeSettings.IsRequiredAuthorizationCode
  } else if (key === '用车配置:乘客确认消息:邮箱') {
    return PersonalSetting.CarSettings && PersonalSetting.CarSettings.PassengerConfirmMessageSettings && PersonalSetting.CarSettings.PassengerConfirmMessageSettings.IsSendEmail
  } else if (key === '用车配置:乘客确认消息:短信') {
    return PersonalSetting.CarSettings && PersonalSetting.CarSettings.PassengerConfirmMessageSettings && PersonalSetting.CarSettings.PassengerConfirmMessageSettings.IsSendSms
  } else if (key === '用车配置:联系人确认消息:邮箱') {
    return PersonalSetting.CarSettings && PersonalSetting.CarSettings.ContactConfirmMessageSettings && PersonalSetting.CarSettings.ContactConfirmMessageSettings.IsSendEmail
  } else if (key === '用车配置:联系人确认消息:短信') {
    return PersonalSetting.CarSettings && PersonalSetting.CarSettings.ContactConfirmMessageSettings && PersonalSetting.CarSettings.ContactConfirmMessageSettings.IsSendSms
  // ============================================================================================
  } else if (key === '用餐配置:差旅目的:展示') {
    return PersonalSetting.DiningSettings && PersonalSetting.DiningSettings.PurposeSettings && PersonalSetting.DiningSettings.PurposeSettings.IsDisplayPurpose
  } else if (key === '用餐配置:差旅目的:必填') {
    return PersonalSetting.DiningSettings && PersonalSetting.DiningSettings.PurposeSettings && PersonalSetting.DiningSettings.PurposeSettings.IsRequiredPurpose
  } else if (key === '用餐配置:差旅目的:类型') {
    return PersonalSetting.DiningSettings && PersonalSetting.DiningSettings.PurposeSettings ? Number(PersonalSetting.DiningSettings.PurposeSettings.PurposeType) : null
  } else if (key === '用餐配置:自定义项:展示') {
    return PersonalSetting.DiningSettings && PersonalSetting.DiningSettings.CustomItemSettings && PersonalSetting.DiningSettings.CustomItemSettings.IsDisplayCustomItem
  } else if (key === '用餐配置:自定义项:名称') {
    return PersonalSetting.DiningSettings && PersonalSetting.DiningSettings.CustomItemSettings && PersonalSetting.DiningSettings.CustomItemSettings.CustomItemName
  } else if (key === '用餐配置:自定义项:必填') {
    return PersonalSetting.DiningSettings && PersonalSetting.DiningSettings.CustomItemSettings && PersonalSetting.DiningSettings.CustomItemSettings.IsRequiredCustomItem
  } else if (key === '用餐配置:授权码名称') {
    /** 授权码名称默认读取国内正单的配置 */
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.NormalSettings && PersonalSetting.FlightSettings.NormalSettings.AuthorizationCodeSettings && PersonalSetting.FlightSettings.NormalSettings.AuthorizationCodeSettings.AuthorizationCodeName
  } else if (key === '用餐配置:授权码:展示') {
    return PersonalSetting.DiningSettings && PersonalSetting.DiningSettings.AuthorizationCodeSettings && PersonalSetting.DiningSettings.AuthorizationCodeSettings.IsDisplayAuthorizationCode
  } else if (key === '用餐配置:授权码:必填') {
    return PersonalSetting.DiningSettings && PersonalSetting.DiningSettings.AuthorizationCodeSettings && PersonalSetting.DiningSettings.AuthorizationCodeSettings.IsRequiredAuthorizationCode
  } else if (key === '用餐配置:乘客确认消息:邮箱') {
    return PersonalSetting.DiningSettings && PersonalSetting.DiningSettings.PassengerConfirmMessageSettings && PersonalSetting.DiningSettings.PassengerConfirmMessageSettings.IsSendEmail
  } else if (key === '用餐配置:乘客确认消息:短信') {
    return PersonalSetting.DiningSettings && PersonalSetting.DiningSettings.PassengerConfirmMessageSettings && PersonalSetting.DiningSettings.PassengerConfirmMessageSettings.IsSendSms
  } else if (key === '用餐配置:联系人确认消息:邮箱') {
    return PersonalSetting.DiningSettings && PersonalSetting.DiningSettings.ContactConfirmMessageSettings && PersonalSetting.DiningSettings.ContactConfirmMessageSettings.IsSendEmail
  } else if (key === '用餐配置:联系人确认消息:短信') {
    return PersonalSetting.DiningSettings && PersonalSetting.DiningSettings.ContactConfirmMessageSettings && PersonalSetting.DiningSettings.ContactConfirmMessageSettings.IsSendSms
    // ============================================================================================
  } else if (key === '出差申请单配置:审核类型') {
    return PersonalSetting.TripApplicationFormSettings && PersonalSetting.TripApplicationFormSettings.TripApplicationFormReviewType
  } else if (key === '出差申请单配置:上传附件:名称') {
    return PersonalSetting.TripApplicationFormSettings && PersonalSetting.TripApplicationFormSettings.UploadAttachFileSettings && PersonalSetting.TripApplicationFormSettings.UploadAttachFileSettings.Name
  } else if (key === '出差申请单配置:上传附件:展示') {
    return PersonalSetting.TripApplicationFormSettings && PersonalSetting.TripApplicationFormSettings.UploadAttachFileSettings && PersonalSetting.TripApplicationFormSettings.UploadAttachFileSettings.IsDisplayUploadAttachFile
  } else if (key === '出差申请单配置:上传附件:必填') {
    return PersonalSetting.TripApplicationFormSettings && PersonalSetting.TripApplicationFormSettings.UploadAttachFileSettings && PersonalSetting.TripApplicationFormSettings.UploadAttachFileSettings.IsRequiredUploadAttachFile
  } else if (key === '出差申请单配置:自定义项:名称') {
    return PersonalSetting.TripApplicationFormSettings && PersonalSetting.TripApplicationFormSettings.CustomItemSettings && PersonalSetting.TripApplicationFormSettings.CustomItemSettings.CustomItemName
  } else if (key === '出差申请单配置:自定义项:展示') {
    return PersonalSetting.TripApplicationFormSettings && PersonalSetting.TripApplicationFormSettings.CustomItemSettings && PersonalSetting.TripApplicationFormSettings.CustomItemSettings.IsDisplayCustomItem
  } else if (key === '出差申请单配置:自定义项:必填') {
    return PersonalSetting.TripApplicationFormSettings && PersonalSetting.TripApplicationFormSettings.CustomItemSettings && PersonalSetting.TripApplicationFormSettings.CustomItemSettings.IsRequiredCustomItem
  } else if (key === '出差申请单配置:审批:需要审批') {
    return PersonalSetting.TripApplicationFormSettings && PersonalSetting.TripApplicationFormSettings.VettingSettings && PersonalSetting.TripApplicationFormSettings.VettingSettings.IsEnableVetting
  } else if (key === '出差申请单配置:审批:审批类型') {
    return PersonalSetting.TripApplicationFormSettings && PersonalSetting.TripApplicationFormSettings.VettingSettings && PersonalSetting.TripApplicationFormSettings.VettingSettings.VettingType
  } else if (key === '出差申请单配置:总预算:展示') {
    return PersonalSetting.TripApplicationFormSettings && PersonalSetting.TripApplicationFormSettings.TotalBudgetSettings && PersonalSetting.TripApplicationFormSettings.TotalBudgetSettings.IsDisplayTotalBudget
  } else if (key === '出差申请单配置:总预算:必填') {
    return PersonalSetting.TripApplicationFormSettings && PersonalSetting.TripApplicationFormSettings.TotalBudgetSettings && PersonalSetting.TripApplicationFormSettings.TotalBudgetSettings.IsRequiredTotalBudget
  } else if (key === '出差申请单配置:业务预算:展示') {
    return PersonalSetting.TripApplicationFormSettings && PersonalSetting.TripApplicationFormSettings.BusinessBudgetSettings && PersonalSetting.TripApplicationFormSettings.BusinessBudgetSettings.IsDisplayBusinessBudget
  } else if (key === '出差申请单配置:业务预算:必填') {
    return PersonalSetting.TripApplicationFormSettings && PersonalSetting.TripApplicationFormSettings.BusinessBudgetSettings && PersonalSetting.TripApplicationFormSettings.BusinessBudgetSettings.IsRequiredBusinessBudget
    // ============================================================================================
    /** 汽车票配置 */
  } else if (key === '汽车票配置:乘客预订消息:短信') {
    return PersonalSetting.BusSettings && PersonalSetting.BusSettings.NormalSettings && PersonalSetting.BusSettings.NormalSettings.PassengerBookMessageSettings && PersonalSetting.BusSettings.NormalSettings.PassengerBookMessageSettings.IsSendSms
  } else if (key === '汽车票配置:乘客预订消息:邮箱') {
    return PersonalSetting.BusSettings && PersonalSetting.BusSettings.NormalSettings && PersonalSetting.BusSettings.NormalSettings.PassengerBookMessageSettings && PersonalSetting.BusSettings.NormalSettings.PassengerBookMessageSettings.IsSendEmail
  } else if (key === '汽车票配置:乘客预订消息:微信') {
    return PersonalSetting.BusSettings && PersonalSetting.BusSettings.NormalSettings && PersonalSetting.BusSettings.NormalSettings.PassengerBookMessageSettings && PersonalSetting.BusSettings.NormalSettings.PassengerBookMessageSettings.IsSendWechat
  } else if (key === '汽车票配置:乘客出票消息:短信') {
    return PersonalSetting.BusSettings && PersonalSetting.BusSettings.NormalSettings && PersonalSetting.BusSettings.NormalSettings.PassengerIssuedMessageSettings && PersonalSetting.BusSettings.NormalSettings.PassengerIssuedMessageSettings.IsSendSms
  } else if (key === '汽车票配置:乘客出票消息:邮箱') {
    return PersonalSetting.BusSettings && PersonalSetting.BusSettings.NormalSettings && PersonalSetting.BusSettings.NormalSettings.PassengerIssuedMessageSettings && PersonalSetting.BusSettings.NormalSettings.PassengerIssuedMessageSettings.IsSendEmail
  } else if (key === '汽车票配置:乘客出票消息:微信') {
    return PersonalSetting.BusSettings && PersonalSetting.BusSettings.NormalSettings && PersonalSetting.BusSettings.NormalSettings.PassengerIssuedMessageSettings && PersonalSetting.BusSettings.NormalSettings.PassengerIssuedMessageSettings.IsSendWechat
  } else if (key === '汽车票配置:联系人预订消息:短信') {
    return PersonalSetting.BusSettings && PersonalSetting.BusSettings.NormalSettings && PersonalSetting.BusSettings.NormalSettings.ContactBookMessageSettings && PersonalSetting.BusSettings.NormalSettings.ContactBookMessageSettings.IsSendSms
  } else if (key === '汽车票配置:联系人预订消息:邮箱') {
    return PersonalSetting.BusSettings && PersonalSetting.BusSettings.NormalSettings && PersonalSetting.BusSettings.NormalSettings.ContactBookMessageSettings && PersonalSetting.BusSettings.NormalSettings.ContactBookMessageSettings.IsSendEmail
  } else if (key === '汽车票配置:联系人预订消息:微信') {
    return PersonalSetting.BusSettings && PersonalSetting.BusSettings.NormalSettings && PersonalSetting.BusSettings.NormalSettings.ContactBookMessageSettings && PersonalSetting.BusSettings.NormalSettings.ContactBookMessageSettings.IsSendWechat
  } else if (key === '汽车票配置:联系人出票消息:短信') {
    return PersonalSetting.BusSettings && PersonalSetting.BusSettings.NormalSettings && PersonalSetting.BusSettings.NormalSettings.ContactIssuedMessageSettings && PersonalSetting.BusSettings.NormalSettings.ContactIssuedMessageSettings.IsSendSms
  } else if (key === '汽车票配置:联系人出票消息:邮箱') {
    return PersonalSetting.BusSettings && PersonalSetting.BusSettings.NormalSettings && PersonalSetting.BusSettings.NormalSettings.ContactIssuedMessageSettings && PersonalSetting.BusSettings.NormalSettings.ContactIssuedMessageSettings.IsSendEmail
  } else if (key === '汽车票配置:联系人出票消息:微信') {
    return PersonalSetting.BusSettings && PersonalSetting.BusSettings.NormalSettings && PersonalSetting.BusSettings.NormalSettings.ContactIssuedMessageSettings && PersonalSetting.BusSettings.NormalSettings.ContactIssuedMessageSettings.IsSendWechat
  } else if (key === '汽车票配置:审批:需要审批') {
    return PersonalSetting.BusSettings && PersonalSetting.BusSettings.NormalSettings && PersonalSetting.BusSettings.NormalSettings.VettingSettings && PersonalSetting.BusSettings.NormalSettings.VettingSettings.IsEnableVetting
  } else if (key === '汽车票配置:审批:审批类型') {
    return PersonalSetting.BusSettings && PersonalSetting.BusSettings.NormalSettings && PersonalSetting.BusSettings.NormalSettings.VettingSettings && PersonalSetting.BusSettings.NormalSettings.VettingSettings.VettingType
  } else if (key === '汽车票配置:授权码:名称') {
    /** 授权码名称默认读取国内正单的配置 */
    return PersonalSetting.FlightSettings && PersonalSetting.FlightSettings.NormalSettings && PersonalSetting.FlightSettings.NormalSettings.AuthorizationCodeSettings && PersonalSetting.FlightSettings.NormalSettings.AuthorizationCodeSettings.AuthorizationCodeName
  } else if (key === '汽车票配置:授权码:显示') {
    return PersonalSetting.BusSettings && PersonalSetting.BusSettings.NormalSettings && PersonalSetting.BusSettings.NormalSettings.AuthorizationCodeSettings && PersonalSetting.BusSettings.NormalSettings.AuthorizationCodeSettings.IsDisplayAuthorizationCode
  } else if (key === '汽车票配置:授权码:必填') {
    return PersonalSetting.BusSettings && PersonalSetting.BusSettings.NormalSettings && PersonalSetting.BusSettings.NormalSettings.AuthorizationCodeSettings && PersonalSetting.BusSettings.NormalSettings.AuthorizationCodeSettings.IsRequiredAuthorizationCode
  } else if (key === '汽车票配置:授权码:校验') {
    return PersonalSetting.BusSettings && PersonalSetting.BusSettings.NormalSettings && PersonalSetting.BusSettings.NormalSettings.AuthorizationCodeSettings && PersonalSetting.BusSettings.NormalSettings.AuthorizationCodeSettings.IsCheckAuthorizationCode
  } else if (key === '汽车票配置:差旅目的:展示') {
    return PersonalSetting.BusSettings && PersonalSetting.BusSettings.NormalSettings && PersonalSetting.BusSettings.NormalSettings.PurposeSettings && PersonalSetting.BusSettings.NormalSettings.PurposeSettings.IsDisplayPurpose
  } else if (key === '汽车票配置:差旅目的:必填') {
    return PersonalSetting.BusSettings && PersonalSetting.BusSettings.NormalSettings && PersonalSetting.BusSettings.NormalSettings.PurposeSettings && PersonalSetting.BusSettings.NormalSettings.PurposeSettings.IsRequiredPurpose
  } else if (key === '汽车票配置:差旅目的:类型') {
    return PersonalSetting.BusSettings && PersonalSetting.BusSettings.NormalSettings && PersonalSetting.BusSettings.NormalSettings.PurposeSettings ? Number(PersonalSetting.BusSettings.NormalSettings.PurposeSettings.PurposeType) : null
  } else if (key === '汽车票配置:自定义项:名称') {
    return PersonalSetting.BusSettings && PersonalSetting.BusSettings.NormalSettings && PersonalSetting.BusSettings.NormalSettings.CustomItemSettings && PersonalSetting.BusSettings.NormalSettings.CustomItemSettings.CustomItemName
  } else if (key === '汽车票配置:自定义项:展示') {
    return PersonalSetting.BusSettings && PersonalSetting.BusSettings.NormalSettings && PersonalSetting.BusSettings.NormalSettings.CustomItemSettings && PersonalSetting.BusSettings.NormalSettings.CustomItemSettings.IsDisplayCustomItem
  } else if (key === '汽车票配置:自定义项:必填') {
    return PersonalSetting.BusSettings && PersonalSetting.BusSettings.NormalSettings && PersonalSetting.BusSettings.NormalSettings.CustomItemSettings && PersonalSetting.BusSettings.NormalSettings.CustomItemSettings.IsRequiredCustomItem
  } else if (key === '汽车票配置:正常订单:查询顺序') {
    return PersonalSetting.BusSettings && PersonalSetting.BusSettings.NormalSettings && PersonalSetting.BusSettings.NormalSettings.SearchSettings && PersonalSetting.BusSettings.NormalSettings.SearchSettings.SearchOrder
  } else if (key === '汽车票配置:上传附件:名称') {
    return PersonalSetting.BusSettings && PersonalSetting.BusSettings.NormalSettings && PersonalSetting.BusSettings.NormalSettings.UploadAttachFileSettings && PersonalSetting.BusSettings.NormalSettings.UploadAttachFileSettings.Name
  } else if (key === '汽车票配置:上传附件:显示') {
    return PersonalSetting.BusSettings && PersonalSetting.BusSettings.NormalSettings && PersonalSetting.BusSettings.NormalSettings.UploadAttachFileSettings && PersonalSetting.BusSettings.NormalSettings.UploadAttachFileSettings.IsDisplayUploadAttachFile
  } else if (key === '汽车票配置:上传附件:必填') {
    return PersonalSetting.BusSettings && PersonalSetting.BusSettings.NormalSettings && PersonalSetting.BusSettings.NormalSettings.UploadAttachFileSettings && PersonalSetting.BusSettings.NormalSettings.UploadAttachFileSettings.IsRequiredUploadAttachFile
  } else {
    return getSettingForExtendField(PersonalSetting, key)
  }
}
