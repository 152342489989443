var Alert = {}
var showAlert = false // 存储toast显示状态
var alertVM = null // 存储toast vm
Alert.install = function (Vue) {
  Vue.prototype.$alert = function (options) {
    /** 默认配置 */
    let defaultOptions = {
      type: '',
      title: Vue.prototype.$t('Tips'),
      content: '',
      confirmTxt: Vue.prototype.$t('Confirm'),
      cancelTxt: undefined,
      confirmFn: undefined,
      cancelFn: undefined,
      maskClose: false
    }
    /** 如果有配置信息传入, 替代默认配置项 */
    if (options) {
      for (let key in options) {
        defaultOptions[key] = options[key]
      }
    }
    /********************************************************************************************************/
    if (showAlert) return
    if (!alertVM) {
      var AlertTpl = Vue.extend({
        data: function () {
          return {
            type: '', // type new 时 没有logo和标题，样式为新样式
            show: showAlert,
            title: defaultOptions.title,
            content: defaultOptions.content,
            confirmTxt: defaultOptions.confirmTxt,
            cancelTxt: defaultOptions.cancelTxt,
            maskClose: defaultOptions.maskClose
          }
        },
        methods: {
          _close() {
            this.show = showAlert = false
          },
          _confirmFn() {
            defaultOptions.confirmFn && defaultOptions.confirmFn()
          },
          _cancelFn() {
            defaultOptions.cancelFn && defaultOptions.cancelFn()
          }
        },
        template: `
          <div v-show="show" class="m-alert" style="animation: dialog-fadein .4s;">
            <!--添加遮罩层 解决alert隐藏时遮罩层没有延迟问题-->
            <van-popup v-model="show" get-container="body" :overlay-style="{'background-color': 'rgba(37, 38, 45, 0)'}"></van-popup>
            <div class="mask" @click.self.stop="maskClose ? _close() : null">
              <div class="main" :type="type" style="animation: dialog-zoom .4s;">
                <div class="logo"></div>
                <div class="title">{{title}}</div>
                <div class="content">
                  <p v-html="content"></p>
                </div>
                <div class="footer" @click="_close">
                  <p v-if="cancelTxt" :style="{color: type === 'new' ? '#5A4F4F' : '#212121'}" @click="_cancelFn">{{cancelTxt}}</p>
                  <p @click="_confirmFn">{{confirmTxt}}</p>
                </div>
              </div>
            </div>
          </div>
        `
      })
      alertVM = new AlertTpl()
      var tpl = alertVM.$mount().$el
      document.body.appendChild(tpl)
    }
    /** 更新 */
    alertVM.type = defaultOptions.type
    alertVM.title = defaultOptions.title
    alertVM.content = defaultOptions.content
    alertVM.confirmTxt = defaultOptions.confirmTxt
    alertVM.cancelTxt = defaultOptions.cancelTxt
    alertVM.maskClose = defaultOptions.maskClose
    alertVM._confirmFn = () => {
      defaultOptions.confirmFn && defaultOptions.confirmFn()
    }
    alertVM._cancelFn = () => {
      defaultOptions.cancelFn && defaultOptions.cancelFn()
    }
    alertVM.show = showAlert = true
    /***********************************************************************************************************/
  }

  Vue.prototype.$alert.close = function () {
    alertVM && (alertVM.show = showAlert = false)
  }

  Vue.prototype.$alert.isShow = function () {
    return alertVM && alertVM.show
  }
}
module.exports = Alert
