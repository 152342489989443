// 承載router-view的components
const Start = resolve => {
  import('components/start/start').then(module => {
    resolve(module);
  });
};

export default {
  path: '/bus',
  component: Start,
  children: [
    {
      path: 'query',
      name: '汽车票查询',
      component: resolve => require(['pages/bus/query/query.vue'], resolve),
      meta: {
        keepAlive: true
      }
    },
    {
      path: 'inforList',
      name: '汽车票查询列表',
      component: resolve => require(['pages/bus/infor-list/infor-list.vue'], resolve),
      meta: {
        keepAlive: true
      }
    },
    {
      path: 'bookDetails',
      name: '汽车票订单填写',
      component: resolve => require(['pages/bus/book/book-detail.vue'], resolve)
    },
    {
      path: 'orderConfirm',
      name: 'busOrderConfirm',
      component: resolve =>
        require(['pages/bus/order/order-confirm/order-confirm.vue'], resolve)
    },
    {
      name: 'busOrderList',
      path: 'orderList',
      component: resolve =>
        require(['pages/bus/order/order-list/order-list.vue'], resolve),
      meta: {
        keepAlive: true
      }
    },
    {
      path: 'orderDetails',
      name: '汽车票订单详情',
      component: resolve =>
        require(['pages/bus/order/order-details/order-details.vue'], resolve)
    },
    {
      path: 'toRefund',
      name: '汽车票去退票',
      component: resolve =>
        require(['pages/bus/refund/to-refund/to-refund.vue'], resolve),
      meta: {
        keepAlive: true
      }
    },
    {
      path: 'refundConfirm',
      name: '汽车票确认退票',
      component: resolve =>
        require(['pages/bus/refund/to-refund/refund-confirm.vue'], resolve)
    },
    {
      path: 'refundDetails',
      name: '汽车票退票详情',
      component: resolve =>
        require(['pages/bus/refund/refund-details/refund-details.vue'], resolve)
    }
  ]
}
