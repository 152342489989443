// Config API文档 https://www.kancloud.cn/yunye/axios/234845
import {languageTypeInit} from 'common/utility/utils'
import {VERSION} from "@/common/constants/common";
const BASE_URL = '/api'
function getHeaders() {
  return {}
}

function getBaseUrl(url) {
  let BaseUrl = BASE_URL
  if (
    url.includes('mealsapi/') ||
    url.includes('tripformapi/') ||
    url.includes('lvxingdouapi/') ||
    url.includes('intlDemandApi/') ||
    url.includes('trip_dynamic/') ||
    url.includes('openapi/') ||
    url.includes('pay_wallet/') ||
    url.includes('invoiceApply/') ||
    url.includes('customer/mobile/')
  ) {
    BaseUrl = ''
  }
  return BaseUrl
}

// POSTFILE请求配置文件
export function postFile (url, file, filePath = '') {
  let RequestSource = 'Web' // Wechat: 微信 WechatMiniProgram: 小程序 Web: 网页 Android: 安卓 Apple: ios
  let ua = window.navigator.userAgent.toLowerCase() // 获取判断用的对象
  if (window.__wxjs_environment === 'miniprogram') {
    // RequestSource = 'WechatMiniProgram'
    RequestSource = 'Wechat'
  } else if (/micromessenger/.test(ua)) {
    RequestSource = 'Wechat'
  }
  return {
    url: url,
    method: 'post', // 默认是 get
    baseURL: getBaseUrl(url),
    // `transformRequest` 允许在向服务器发送前，修改请求数据
    // 只能用在 'PUT', 'POST' 和 'PATCH' 这几个请求方法
    // 后面数组中的函数必须返回一个字符串，或 ArrayBuffer，或 Stream
    transformRequest: [function (data) {
      // 对 data 进行任意转换处理
      // return data
      return data
    }],
    // `transformResponse` 在传递给 then/catch 前，允许修改响应数据
    transformResponse: [function (data) {
      // 对 data 进行任意转换处理
      return data
    }],
    headers: {
      'Accept': 'application/json',
      'RequestSource': encodeURI(RequestSource),
      'SoftwareVersion': encodeURI(VERSION),
      'DeviceInformation': encodeURI(ua),
      'BrowserUrl': encodeURI(window.location.href),
      'LanguageType': languageTypeInit(window.localStorage.language),
      'CurrencyType': encodeURI(window.localStorage.currency || 0),
      'Token': encodeURI(window.localStorage.HomsomFAFKLDIGLDA ? window.localStorage.HomsomFAFKLDIGLDA : '')
    },
    // `params` 是即将与请求一起发送的 URL 参数
    // 必须是一个无格式对象(plain object)或 URLSearchParams 对象
    params: Object.assign(getHeaders(), {filePath: encodeURIComponent(`${filePath}/`)}),
    // `data` 是作为请求主体被发送的数据
    // 只适用于这些请求方法 'PUT', 'POST', 和 'PATCH'
    // 在没有设置 `transformRequest` 时，必须是以下类型之一：
    // - string, plain object, ArrayBuffer, ArrayBufferView, URLSearchParams
    // - 浏览器专属：FormData, File, Blob
    // - Node 专属： Stream
    data: file,
    // `timeout` 指定请求超时的毫秒数(0 表示无超时时间)
    // 如果请求话费了超过 `timeout` 的时间，请求将被中断
    timeout: 120000,
    // `withCredentials` 表示跨域请求时是否需要使用凭证
    withCredentials: true, // 默认的
    // `responseType` 表示服务器响应的数据类型，可以是 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream'
    responseType: 'json', // 默认的
    // `xsrfCookieName` 是用作 xsrf token 的值的cookie的名称
    xsrfCookieName: 'XSRF-TOKEN', // default
    // `xsrfHeaderName` 是承载 xsrf token 的值的 HTTP 头的名称
    xsrfHeaderName: 'X-XSRF-TOKEN', // 默认的
    // `onUploadProgress` 允许为上传处理进度事件
    // onUploadProgress: function (progressEvent) {
    //   // 对原生进度事件的处理
    // },
    // // `onDownloadProgress` 允许为下载处理进度事件
    // onDownloadProgress: function (progressEvent) {
    //   // 对原生进度事件的处理
    // },
    // `maxContentLength` 定义允许的响应内容的最大尺寸
    // maxContentLength: 2000,
    // `validateStatus` 定义对于给定的HTTP 响应状态码是 resolve 或 reject  promise 。如果 `validateStatus` 返回 `true` (或者设置为 `null` 或 `undefined`)，promise 将被 resolve; 否则，promise 将被 rejecte
    validateStatus: function (status) {
      return status >= 200 && status < 300 // 默认的
    },
    // `maxRedirects` 定义在 node.js 中 follow 的最大重定向数目
    // 如果设置为0，将不会 follow 任何重定向
    maxRedirects: 5 // 默认的
  }
}

// POST请求配置文件
export function commonRequest (url, method, params, data, cancelToken) {
  let RequestSource = 'Web' // Wechat: 微信 WechatMiniProgram: 小程序 Web: 网页 Android: 安卓 Apple: ios
  let ua = window.navigator.userAgent.toLowerCase() // 获取判断用的对象
  if (window.__wxjs_environment === 'miniprogram') {
    // RequestSource = 'WechatMiniProgram'
    RequestSource = 'Wechat'
  } else if (/micromessenger/.test(ua)) {
    RequestSource = 'Wechat'
  }
  return {
    // `url` 是用于请求的服务器 URL
    url: url,
    // `method` 是创建请求时使用的方法
    method: method, // 默认是 get
    // `baseURL` 将自动加在 `url` 前面，除非 `url` 是一个绝对 URL。
    // 它可以通过设置一个 `baseURL` 便于为 axios 实例的方法传递相对 URL
    baseURL: getBaseUrl(url), // 针对用餐接口 更换 api标识
    // `transformRequest` 允许在向服务器发送前，修改请求数据
    // 只能用在 'PUT', 'POST' 和 'PATCH' 这几个请求方法
    // 后面数组中的函数必须返回一个字符串，或 ArrayBuffer，或 Stream
    transformRequest: [function (data) {
      // 对 data 进行任意转换处理
      // return data
      return JSON.stringify(data)
    }],
    // `transformResponse` 在传递给 then/catch 前，允许修改响应数据
    transformResponse: [function (data) {
      // 对 data 进行任意转换处理
      return (typeof (data) === 'string') ? JSON.parse(data) : data
    }],
    headers: {
      'Accept': 'application/json;charset=UTF-8',
      'Content-Type': 'application/json',
      'RequestSource': encodeURI(RequestSource),
      'SoftwareVersion': encodeURI(VERSION),
      'DeviceInformation': encodeURI(ua),
      'BrowserUrl': encodeURI(window.location.href),
      'LanguageType': languageTypeInit(window.localStorage.language),
      'CurrencyType': encodeURI(window.localStorage.currency || 0),
      'Token': encodeURI(window.localStorage.HomsomFAFKLDIGLDA ? window.localStorage.HomsomFAFKLDIGLDA : '')
    },
    // `params` 是即将与请求一起发送的 URL 参数
    // 必须是一个无格式对象(plain object)或 URLSearchParams 对象
    params: Object.assign(getHeaders(), params),
    // params: params,
    // `data` 是作为请求主体被发送的数据
    // 只适用于这些请求方法 'PUT', 'POST', 和 'PATCH'
    // 在没有设置 `transformRequest` 时，必须是以下类型之一：
    // - string, plain object, ArrayBuffer, ArrayBufferView, URLSearchParams
    // - 浏览器专属：FormData, File, Blob
    // - Node 专属： Stream
    data: data,
    // `timeout` 指定请求超时的毫秒数(0 表示无超时时间)
    // 如果请求话费了超过 `timeout` 的时间，请求将被中断
    timeout: 60000,
    // `withCredentials` 表示跨域请求时是否需要使用凭证
    withCredentials: true, // 默认的false
    // `responseType` 表示服务器响应的数据类型，可以是 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream'
    responseType: 'json', // 默认的
    // `xsrfCookieName` 是用作 xsrf token 的值的cookie的名称
    xsrfCookieName: 'XSRF-TOKEN', // default
    // `xsrfHeaderName` 是承载 xsrf token 的值的 HTTP 头的名称
    xsrfHeaderName: 'X-XSRF-TOKEN', // 默认的
    // `onUploadProgress` 允许为上传处理进度事件
    onUploadProgress: function (progressEvent) {
      // 对原生进度事件的处理
    },
    // `onDownloadProgress` 允许为下载处理进度事件
    onDownloadProgress: function (progressEvent) {
      // 对原生进度事件的处理
    },
    // `maxContentLength` 定义允许的响应内容的最大尺寸
    maxContentLength: 2000,
    // `validateStatus` 定义对于给定的HTTP 响应状态码是 resolve 或 reject  promise 。如果 `validateStatus` 返回 `true` (或者设置为 `null` 或 `undefined`)，promise 将被 resolve; 否则，promise 将被 rejecte
    validateStatus: function (status) {
      return status >= 200 && status < 300 // 默认的
    },
    // `maxRedirects` 定义在 node.js 中 follow 的最大重定向数目
    // 如果设置为0，将不会 follow 任何重定向
    maxRedirects: 5, // 默认的
    cancelToken: cancelToken
  }
}
