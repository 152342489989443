// 承載router-view的components
const Start = resolve => {
  import('components/start/start').then(module => {
    resolve(module);
  });
};

export default {
  path: '/hotel',
  redirect: '/hotel/query',
  component: Start,
  children: [
    {
      // 国内酒店订单列表
      name: 'hotelOrderList',
      path: 'orderList',
      component: resolve =>
        require(['pages/hotel/order/order-list/order-list.vue'], resolve),
      meta: {
        keepAlive: true
      }
    },
    {
      path: 'orderDetails',
      name: '国内酒店订单详情',
      component: resolve =>
        require(['pages/hotel/order/order-details/order-details.vue'], resolve)
    },
    {
      // 国内酒店订单确认
      name: 'hotelOrderConfirm',
      path: 'orderConfirm',
      component: resolve =>
        require(['pages/hotel/order/order-confirm/order-confirm.vue'], resolve)
    },
    {
      path: 'query',
      name: 'hotelQuery',
      component: resolve =>
        require(['pages/hotel/query/query.vue'], resolve),
      meta: {
        keepAlive: true
      }
    },
    {
      // 国内酒店列表
      name: 'hotelInforList',
      path: 'inforList',
      component: resolve =>
        require(['pages/hotel/infor-list/infor-list.vue'], resolve),
      meta: {
        keepAlive: true
      }
    },
    {
      path: 'inforDetails',
      name: '国内酒店详情',
      component: resolve =>
        require(['pages/hotel/infor-details/infor-details.vue'], resolve),
      meta: {
        keepAlive: true
      }
    },
    {
      // 国内酒店担保填写页
      name: 'hotelBookGuarantee',
      path: 'bookGuarantee',
      component: resolve =>
        require(['pages/hotel/book/book-guarantee/book-guarantee.vue'], resolve)
    },
    {
      // 国内酒店填写
      name: 'hotelBookDetails',
      path: 'bookDetails',
      component: resolve =>
        require(['pages/hotel/book/book-details.vue'], resolve)
    },
    {
      // 国内酒店退单
      name: 'refund',
      path: 'refund',
      component: resolve =>
        require(['pages/hotel/refund/refund.vue'], resolve)
    },
    {
      // 国内酒店退单 详情接口
      name: 'refundDetails',
      path: 'refundDetails',
      component: resolve =>
        require(['pages/hotel/refund/refund-details.vue'], resolve)
    },
    {
      // 酒店我的收藏
      name: 'hotelCollection',
      path: 'collection',
      component: resolve =>
        require(['pages/hotel/collection/collection.vue'], resolve),
      meta: {
        keepAlive: true
      }
    }
  ]
}
