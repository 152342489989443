<template>
  <hs-dialog v-model="showSortDialog" style="z-index:300">
    <div class="sortDialog" :class="{native: type === 'native'}">
      <!--<hs-cell-1 key="title" :showLogo="true" logoName="c-picker-close.png"><div class="title">{{title}}</div></hs-cell-1>-->
      <hs-cell-1 class="hs-picker-title" key="title" :showLogo="false" :showSeparator="false" logoName="c-picker-close.png"><div class="title">{{title}}</div></hs-cell-1>
      <hs-scroll
        ref="tableView"
        class="tableView"
        :data="data"
      >
        <div class="select-list">
          <hs-cell-1 v-for="(item, i) in currentData"  :showLogo="false" :key="item.text"  @click.native="() =>_getDatalist(item,i)">
            <div class="custom-cell" :class="{active: showSelect && sort === i}">{{item.text}}</div>
            <img v-if="showSelect && sort === i" class="icon-select" src="@/assets/image/hotel/success.png" alt="">
          </hs-cell-1>
          <hs-cell-1 :showLogo="false" key="empty" >
            <div class="custom-cell">{{' '}}</div>
          </hs-cell-1>
        </div>
      </hs-scroll>
    </div>
  </hs-dialog>
</template>
<script type="text/ecmascript-6">
import HsScroll from 'base/hs-scroll/hs-scroll'
import {cloneObject} from '../../common/utility/utils';
export default {
  name: 'hs-picker',
  props: {
    title: {
      type: String,
      default: ''
    },
    data: {
      type: Array,
      default: () => {
        return []
      }
    },
    type: {
      type: String,
      default: ''
    },
    showSelect: {
      type: Boolean,
      default: true
    },
    onSelect: {
      type: Function,
      default: () => {
        return () => {}
      }
    },
    selectedIndex: {
      type: [Number, String],
      default: 0
    }
  },
  computed: {},
  created() {
    this.sort = this.selectedIndex
  },
  watch: {
    showSortDialog(val) {
      if (val) {
        setTimeout(() => {
          this.currentData = cloneObject(this.data)
        }, 200)
      }
    },
    selectedIndex() {
      this.sort = this.selectedIndex
    }
  },
  data() {
    return {
      showSortDialog: false,
      // selected: {
      //   text: '',
      //   value: ''
      // },
      sort: 0,
      currentData: []
      // title: ''
    }
  },
  methods: {
    show() {
      this.sort = this.selectedIndex
      this.showSortDialog = true
    },
    close() {
      this.showSortDialog = false
    },
    _getDatalist(item, i) {
      let val = [item.value]
      let index = [i]
      let text = [item.text]
      this.sort = i
      this.onSelect(val, index, text);
      this.showSortDialog = false
    },
    // 获取弹框是否展开
    getIsOpen() {
      if (this.showSortDialog) {
        this.close()
        return true
      }
      return false
    }
  },
  components: {
    HsScroll,
    HsCell1: () => import('base/hs-tableview/hs-cell-1/hs-cell-1'),
    HsDialog: () => import('base/hs-dialog/hs-dialog')
  }
}
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
@import '~common/stylus/base'
@import '~common/stylus/variable'
.sortDialog
  @extend .flex-column
  @extend .flex-end
  position absolute
  width 100%
  left 0
  bottom 0
  background-color $color_white
  border-radius 10px 10px 0 0
  max-height 60vh
  .hs-picker-title
    border-bottom 1px solid $color_gray_layer
  >>> .hs-cell-1 >.details >.logo
    width 15px
    height 15px
  .tableView
    max-height calc(60vh - 45px)
    width 100%
    overflow hidden
  .select-list
    width 100%
  .title
    display flex
    justify-content center
    font-size 1rem
    font-weight bold
    width 100%
    // padding-left 20px
  .custom-cell
    @extend .flex-start
    width 100%
    height 40px
    font-size 0.8rem
    color $color_black_text_0
  .icon-select
    width 25px
    height 25px
  .active
    color $color_theme
.native
  .hs-picker-title
    min-height 50px
  .custom-cell
    min-height 50px
    text-align center
    justify-content center
</style>
