// 承載router-view的components
const Start = resolve => {
  import('components/start/start').then(module => {
    resolve(module);
  });
};

export default {
  path: '/personal',
  component: Start,
  children: [
    {
      path: 'center',
      name: '个人中心',
      component: resolve =>
        require(['pages/personal/center/center.vue'], resolve)
    },
    {
      path: 'setting',
      name: '设置',
      component: resolve =>
        require(['pages/personal/setting/setting.vue'], resolve)
    },
    {
      path: 'linkCellPhone',
      name: '绑定手机',
      component: resolve =>
        require(['pages/personal/link-cellphone/link-cellphone.vue'], resolve)
    },
    {
      path: 'aboutUs',
      name: '关于我们',
      component: resolve =>
        require(['pages/personal/about-us/about-us.vue'], resolve)
    },
    {
      path: 'passengers',
      name: '常旅客管理',
      component: resolve =>
        // require(['pages/personal/passengers/passengers.vue'], resolve),
        require(['pages/personal/passengers/passengers.vue'], resolve),
      meta: {
        // keepAlive: true
      }
    },
    {
      path: 'refundList',
      name: '退票列表',
      component: resolve =>
        require(['pages/personal/refund/refund-list/refund-list.vue'], resolve),
      meta: {
        keepAlive: true
      }
    },
    {
      path: 'refundDetails',
      name: '退票详情',
      component: resolve =>
        require(['pages/personal/refund/refund-details/refund-details.vue'], resolve)
    },
    {
      path: 'frequentContacts',
      name: '常用联系人管理',
      component: resolve =>
        require(['pages/personal/frequent-contacts/frequent-contacts.vue'], resolve)
    },
    {
      // 从常旅客列表中新增常用联系人
      name: 'personalAddContacts',
      path: 'addContacts',
      component: resolve =>
        require(['pages/personal/frequent-contacts/add-contacts.vue'], resolve)
    },
    {
      path: 'newContact',
      name: '添加自定义常用联系人',
      component: resolve =>
        require(['pages/personal/frequent-contacts/new-contact.vue'], resolve)
    },
    {
      path: 'frequentContactDetails',
      name: '编辑常用联系人',
      component: resolve =>
        require(['pages/personal/frequent-contacts/frequent-contact-details.vue'], resolve)
    },
    {
      path: 'authOrderList',
      name: '出差申请单',
      component: resolve =>
        require(['pages/personal/auth/order/order-list.vue'], resolve),
      meta: {
        keepAlive: true
      }
    },
    {
      path: 'authOrderDetails',
      name: '出差申请单详情',
      component: resolve =>
        require(['pages/personal/auth/order/order-details.vue'], resolve)
    },
    {
      path: 'suggestion',
      name: '投诉与建议',
      component: resolve =>
        require(['pages/personal/suggestion/suggestion.vue'], resolve)
    },
    {
      path: 'jointBinding',
      name: '联名绑定',
      component: resolve =>
        require(['pages/personal/joint-binding/joint-binding.vue'], resolve)
    },
    {
      path: 'cTripBind',
      name: '携程账号绑定',
      component: resolve =>
        require(['pages/personal/joint-binding/c-trip-bind.vue'], resolve)
    },
    {
      path: 'huaZhuBind',
      name: '华住账号绑定',
      component: resolve =>
        require(['pages/personal/joint-binding/huazhu-bind.vue'], resolve)
    },
    {
      path: 'meituanBind',
      name: '美团账号绑定',
      component: resolve =>
        require(['pages/personal/joint-binding/meituan-bind.vue'], resolve)
    },
    {
      path: 'accountAssociated',
      name: '账号关联',
      component: resolve =>
        require(['pages/personal/account-associated/account-associated.vue'], resolve)
    },
    {
      path: 'wallet',
      redirect: 'wallet/center',
      component: Start,
      children: [
        {
          path: 'center',
          name: '消费记录',
          component: resolve => require(['pages/personal/wallet/center/center.vue'], resolve),
          meta: {
            keepAlive: true
          }
        },
        {
          path: 'recharge',
          name: '充值',
          component: resolve => require(['pages/personal/wallet/recharge/recharge.vue'], resolve)
        }
      ]
    },
    {
      path: 'voucher/list',
      name: '我的抵用券',
      component: resolve => require(['pages/personal/voucher/list/list.vue'], resolve)
    },
    {
      path: 'voucher/history',
      name: '抵用券历史记录',
      component: resolve => require(['pages/personal/voucher/history/history.vue'], resolve)
    },
    {
      path: 'invoice/list',
      name: '发票列表',
      component: resolve => require(['pages/personal/invoice/list/list.vue'], resolve),
      meta: {
        keepAlive: true
      }
    },
    {
      path: 'invoice/details',
      name: '发票详情',
      component: resolve => require(['pages/personal/invoice/details/details.vue'], resolve)
    }
  ]
}
