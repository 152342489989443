import Vue from 'vue'
import {LOGIN_OVERDUE} from "@/common/constants/googleAnalytics";
import store from "@/store";
import {dispatchAMapKey} from "@/store/actions";
/**
 * 登录密码校验  必须包含数字字母 false 校验失败  true 校验成果
 * */
export const checkPassword = (str) => {
  let reg = /[0-9]/
  let reg1 = /[a-zA-z]/
  if (!reg.test(str) || !reg1.test(str)) {
    return false
  }
  return true
}

/** 语言类型 对应枚举 */
export const languageTypeInit = (language) => {
  let type = {
    CN: 1,
    EN: 2,
    TC: 3,
    JAP: 5,
    HK: 4
  }
  return type[language] || 1
}

export const getLanguage = () => {
  // 默认跟随浏览器语言
  if (window.navigator.language && !window.localStorage.language) {
    let language = window.navigator.language
    window.localStorage.FollowTheSystemLanguage = true
    language = language.toLowerCase()
    let NewLanguage = 'CN'
    if (language === 'zh-cn') {
      NewLanguage = 'CN'
    } else if (language === 'zh-tw') {
      NewLanguage = 'TC'
    } else if (language === 'zh-hk') {
      NewLanguage = 'TC'
    } else if (language === 'ja') {
      NewLanguage = 'JAP'
    } else if (language === 'en' || language === 'en-us') {
      NewLanguage = 'EN'
    } else {
      window.localStorage.FollowTheSystemLanguage = null
      NewLanguage = 'CN'
    }
    window.localStorage.language = NewLanguage
  } else {
    window.localStorage.language = window.localStorage.language ? window.localStorage.language : 'CN'
  }
}

/** 颜色转rgba */
export const colorRgba = (sHex, alpha = 1) => {
  // 十六进制颜色值的正则表达式
  let reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/
  /* 16进制颜色转为RGB格式 */
  if (!sHex) {
    return sHex
  }
  let sColor = sHex.toLowerCase()
  if (sColor && reg.test(sColor)) {
    if (sColor.length === 4) {
      let sColorNew = '#'
      for (let i = 1; i < 4; i += 1) {
        sColorNew += sColor.slice(i, i + 1).concat(sColor.slice(i, i + 1))
      }
      sColor = sColorNew
    }
    //  处理六位的颜色值
    let sColorChange = []
    for (let i = 1; i < 7; i += 2) {
      sColorChange.push(parseInt('0x' + sColor.slice(i, i + 2)))
    }
    // return sColorChange.join(',')
    // 或
    return 'rgba(' + sColorChange.join(',') + ',' + alpha + ')'
  } else {
    return sColor
  }
}

/** 克隆对象的简单方法 */
export const cloneObject = (val) => {
  if (!val) {
    return val
  }
  return JSON.parse(JSON.stringify(val))
}

/**
 * 小与 14天
 * 14天（包含） - 2周岁（不包含） 婴儿
 * 2周岁（包含） - 12周岁（不包含） 儿童
 *
 * PassengerType;//乘客类型 0-未知 1-成人 2-儿童 3-婴儿
 * */
export const getPassengerType = (Birthday, lastSegmentTakeOffTime) => {
  if (!Birthday || !lastSegmentTakeOffTime) {
    return 1
  }
  let getNewDate = Vue.prototype.getNewDate
  let childrenBirthday = getNewDate(lastSegmentTakeOffTime, -12, 0, 0)
  let babyBirthday = getNewDate(lastSegmentTakeOffTime, -2, 0, 0)
  // let smallBirthday = getNewDate(lastSegmentTakeOffTime, 0, 0, -14)
  if (Birthday && Birthday > childrenBirthday && Birthday <= babyBirthday) {
    // return 'CHD'
    return 2
  } else if (Birthday && Birthday > babyBirthday) {
    // return 'BB'
    return 3
  } else {
    return 1
  }
}
/**
 * 婴儿是否小与 14天
 * */
export const getIsBabySmallFourteen = (Birthday, lastSegmentTakeOffTime) => {
  if (!Birthday || !lastSegmentTakeOffTime) {
    return null
  }
  let getNewDate = Vue.prototype.getNewDate
  let smallBirthday = getNewDate(lastSegmentTakeOffTime, 0, 0, -14)
  if (Birthday && Birthday > smallBirthday) {
    return true
  }
  return false
}

/**
 * 是否是小于18岁的成人
 * */
export const getIsAdultSmallEighteen = (Birthday, lastSegmentTakeOffTime) => {
  if (!Birthday || !lastSegmentTakeOffTime) {
    return null
  }
  let getNewDate = Vue.prototype.getNewDate
  let EighteenBirthday = getNewDate(lastSegmentTakeOffTime, -18, 0, 0)
  // let childrenBirthday = getNewDate(lastSegmentTakeOffTime, -12, 0, 0)
  if (Birthday && Birthday >= EighteenBirthday) {
    return true
  }
  return false
}

/** 文件后缀名判断 文件类型 */
export const getFileType = (fileName) => {
  // 后缀获取
  let suffix = '';
  // 获取类型结果
  let result = '';
  try {
    const flieArr = fileName.split('.');
    suffix = flieArr[flieArr.length - 1];
  } catch (err) {
    suffix = '';
  }
  // fileName无后缀返回 false
  if (!suffix) { return 'other' }
  suffix = suffix.toLocaleLowerCase();
  // 图片格式
  const imglist = ['png', 'jpg', 'jpeg', 'bmp', 'gif', 'svg'];
  // 进行图片匹配
  result = imglist.find(item => item === suffix);
  if (result) {
    return 'image';
  }
  // 匹配txt
  // const txtlist = ['txt'];
  // result = txtlist.find(item => item === suffix);
  // if (result) {
  //   return 'txt';
  // }
  // 匹配 excel
  const excelist = ['xls', 'xlsx'];
  result = excelist.find(item => item === suffix);
  if (result) {
    return 'excel';
  }
  // 匹配 word
  const wordlist = ['doc', 'docx'];
  result = wordlist.find(item => item === suffix);
  if (result) {
    return 'word';
  }
  // 匹配 pdf
  const pdflist = ['pdf'];
  result = pdflist.find(item => item === suffix);
  if (result) {
    return 'pdf';
  }
  // 匹配 ppt
  const pptlist = ['ppt', 'pptx'];
  result = pptlist.find(item => item === suffix);
  if (result) {
    return 'ppt';
  }
  // 匹配 视频
  // const videolist = ['mp4', 'm2v', 'mkv', 'rmvb', 'wmv', 'avi', 'flv', 'mov', 'm4v'];
  // result = videolist.find(item => item === suffix);
  // if (result) {
  //   return 'video';
  // }
  // // 匹配 音频
  // const radiolist = ['mp3', 'wav', 'wmv'];
  // result = radiolist.find(item => item === suffix);
  // if (result) {
  //   return 'radio';
  // }
  // 其他 文件类型
  return suffix || 'other';
}

/** 登录时效或超时清空 token (缓存的key 是 HomsomFAFKLDIGLDA) */
export const clearToken = () => {
  window.localStorage.HomsomFAFKLDIGLDA = null
}

export const getUrlKey = function (name) {
  return decodeURIComponent((new RegExp(`[?|&]${name}=([^&;]+?)(&|#|;|$)`).exec(location.href) || [undefined, ''])[1].replace(/\+/g, '%20')) || null
}

/** 短信验证码重复处理方法 */
export const identifyingCodeHandle = (newVal, oldVal, callback) => {
  let val = `${newVal}`
  let len = val.length
  if (val.length >= 6 && len % 2 === 0) {
    let half = Math.floor(len / 2)
    let val1 = val.slice(0, half)
    let val2 = val.slice(half, len)
    if (val1 === val2) {
      if (callback && typeof callback === 'function') {
        callback(val1)
      }
    }
  }
}

/** 是否在app内 */
export const getIsApp = () => {
  let userAgent = navigator.userAgent.toLowerCase();//获取UA信息
  if (userAgent.indexOf('homsom') !== -1){//判断ua中是否含有和app端约定好的标识
    return true
  }
  return false;
}

/** 调用app原生方法通知登录时效 */
export const sendMessageToApp = (type = LOGIN_OVERDUE) => {
  let u = navigator.userAgent;
  let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 // android终端
  let isIos = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) // ios终端

  const IsApp = getIsApp()
  if (IsApp && isAndroid) {
    window.homsom && window.homsom.sendMessageToParent(JSON.stringify({
      type: type,
      url: ''
    }))
  } else if (IsApp && isIos) {
    window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.sendMessageToParent && window.webkit.messageHandlers.sendMessageToParent.postMessage({
      type: type,
      url: ''
    })
  }
}

export const getRoutePath = (options) => {
  let path = options.path
  let query = options.query || {}
  let origin = window.location.origin
  let keys = query ? Object.keys(query).map(v => `${v}=${query[v]}`).join('&') : ''
  if (origin === 'http://localhost:8080') {
    origin = 'http://localhost:5173'
    return `${origin}${path}${keys ? '?' + keys : ''}`
  }
  return `${origin}/module/hotel${path}${keys ? '?' + keys : ''}`
}

/** 跳转新版酒店 公用路由方法 */
export const hotelRoutePush = (url, type) => {
  if (type === 'replace') {
    window.location.replace(url)
  } else {
    window.location.href = url
  }
}

/** 地图初始化 */
export const initAMapKey = async () => {
  let AMapKey = store.state.AMapKey
  let key = AMapKey.key || '812d007d67a03fcf8cf358cba7ed198f'
  let securityJsCode = AMapKey.securityJsCode || '0d5fb7bc1460c9b4bd3191e683652bd3'
  return new Promise((resolve, reject) => {
    if (window.AMap) {
      resolve(window.AMap)
    } else {
      window._AMapSecurityConfig = {
        securityJsCode: securityJsCode,
      };
      window.AMapLoader.load({
        key: key, //申请好的Web端开发者 Key，调用 load 时必填
        version: "2.0", //指定要加载的 JS API 的版本，缺省时默认为 1.4.15
      }).then((AMap) => {
        window.AMap = AMap
        resolve(window.AMap)
      }).catch((e) => {
        console.error(e); //加载错误提示
        reject(e)
      });
    }
  })
}
