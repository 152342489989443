export const direction = state => state.direction
// export const unHandleVettingCount = state => state.unHandleVettingCount
export const isMiniprogram = state => state.isMiniprogram
export const isWechatBrowser = state => state.isWechatBrowser
export const isEMobile7Browser = state => state.isEMobile7Browser
export const PersonalInfor = state => state.PersonalInfor
export const PersonalPower = state => state.PersonalPower
export const PersonalSetting = state => state.PersonalSetting
export const noticeDetails = state => state.noticeDetails
export const forceBack = state => state.forceBack
export const Theme = state => state.Theme
export const UserManageInfo = state => state.UserManageInfo
