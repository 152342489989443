// 承載router-view的components
const Start = resolve => {
  import('components/start/start').then(module => {
    resolve(module);
  });
};

export default {
  path: '/car',
  component: Start,
  children: [
    {
      path: 'query',
      name: '用车查询',
      component: resolve =>
        require(['pages/car/query/query.vue'], resolve),
      meta: {
        keepAlive: true
      }
    },
    {
      path: 'inforList',
      name: '用车查询列表',
      component: resolve =>
        require(['pages/car/infor-list/infor-list.vue'], resolve)
    },
    {
      path: 'bookDetails',
      name: '用车订单填写',
      component: resolve =>
        require(['pages/car/book/book-details.vue'], resolve)
    },
    {
      path: 'orderConfirm',
      name: 'carOrderConfirm',
      component: resolve =>
        require(['pages/car/order/order-confirm/order-confirm.vue'], resolve)
    },
    {
      path: 'orderSuccess',
      name: '用车预订成功页面',
      component: resolve =>
        require(['pages/car/order/order-success/order-success.vue'], resolve)
    },
    {
      name: 'carOrderList',
      path: 'orderList',
      component: resolve =>
        require(['pages/car/order/order-list/order-list.vue'], resolve),
      meta: {
        keepAlive: true
      }
    },
    {
      path: 'orderDetails',
      name: '用车订单详情',
      component: resolve =>
        require(['pages/car/order/order-details/order-details.vue'], resolve)
    },
    {
      path: 'orderDetailsDriving',
      name: '用车进行中订单详情',
      component: resolve =>
        require(['pages/car/order/order-details-driving/order-details-driving.vue'], resolve)
    }
  ]
}
