import * as types from './mutation-types'
// import Vue from 'vue'
import {
  kpiUserGetUserCenterInitInfo,
  kpiUserGetPermissions,
  kpiCustomerGetSettings,
  kpiThemeCurrent
} from 'api'
import {kpiGetAMapKey} from "@/api";
import {UPDATE_AMAP_KEY} from "./mutation-types";

/** 个人信息 */
export const dispatchPersonalInfo = function({commit, state}) {
  let promise = new Promise(function(resolve, reject) {
    kpiUserGetUserCenterInitInfo({}).then((res) => {
      commit(types.UPDATE_PERSONAL_INFOR, res.ResultData)
      resolve(state.PersonalInfor)
    }).catch((err) => {
      reject(err)
    })
  })
  return promise
}

/** 权限 */
export const dispatchPersonalPower = function({commit, state}) {
  let promise = new Promise(function(resolve, reject) {
    kpiUserGetPermissions({}).then((res) => {
      commit(types.UPDATE_PERSONAL_POWER, res.ResultData)
      resolve(state.PersonalPower)
    }).catch((err) => {
      reject(err)
    })
  })
  return promise
}

/** 设置 */
export const dispatchPersonalSetting = function({commit, state}) {
  let promise = new Promise(function(resolve, reject) {
    kpiCustomerGetSettings({}).then((res) => {
      commit(types.UPDATE_PERSONAL_SETTING, res.ResultData)
      resolve(state.PersonalSetting)
    }).catch((err) => {
      reject(err)
    })
  })
  return promise
}

/** 获取主题 */
export const dispatchTheme = function({commit, state}) {
  let promise = new Promise(function(resolve, reject) {
    kpiThemeCurrent({}).then((res) => {
      console.log('------获取主题------', res)
      commit(types.UPDATE_THEME_SETTING, res.ResultData)
      resolve(state.Theme)
    }).catch((err) => {
      reject(err)
    })
  })
  return promise
}

export const dispatchAMapKey = function({commit, state}) {
  let promise = new Promise(function(resolve, reject) {
    if (state.AMapKey.key) {
      resolve(state.AMapKey)
    } else {
      kpiGetAMapKey().then((res) => {
        let data = res.ResultData && res.ResultData.MapKey ? res.ResultData.MapKey : {}
        commit(types.UPDATE_AMAP_KEY, {
          key: data.Key,
          securityJsCode: data.Secret,
        })
        resolve(state.AMapKey)
      }).catch((err) => {
        reject(err)
      })
    }
  })
  return promise
}
