export const MANAGE_EMPLOYEE = 'Manage_Goto_Employee' // 员工管理
export const MANAGE_NON_EMPLOYEE = 'Manage_Goto_NonEmployee' // 非员工管理
export const MANAGE_ROLE = 'Manage_Goto_Role' // 角色管理
export const MANAGE_WORK_WE_CHAT = 'Manage_Goto_WorkWeChat' // 企业微信员工绑定
export const MANAGE_DING_TALK = 'Manage_Goto_DingTalk' // 钉钉员工绑定
export const MANAGE_FEI_SHU = 'Manage_Goto_FeiShu' // 飞书员工绑定


export const MANAGE_COMPANY_DETAIL = 'Manage_Goto_CompanyDetail' // 基本信息
export const MANAGE_DEPARTMENTS = 'Manage_Goto_Departments' // 组织架构
export const MANAGE_COST_CENTER = 'Manage_Goto_CostCenter' // 成本中心
export const MANAGE_BUSINESS_UNIT = 'Manage_Goto_BusinessUnit' // BU
export const MANAGE_CUSTOM_ITEM = 'Manage_Goto_CustomItem' // 自定义项
export const MANAGE_LEVEL = 'Manage_Goto_TravelCriteria' // 职级管理
export const MANAGE_RC = 'Manage_Goto_RCManagement' // RC管理
export const MANAGE_FA_PIAO_DETAIL = 'Manage_Goto_FapiaoDetail' // 开票信息
export const MANAGE_TRAVEL_REPORTS = 'Manage_Goto_TravelReports' // 报表
export const MANAGE_BILL= 'Manage_Goto_Bill' // 云账单


export const MANAGE_APPROVAL_MANAGE= 'Manage_Goto_ApprovalManage' // 审批配置
export const MANAGE_APPROVAL_WHITE= 'Manage_Goto_ApprovalWhiteList' // 审批白名单

export const MANAGE_PREPAYMENT = 'Manage_prepayment' // 预付款
export const LOGIN_OVERDUE = 'Login_overdue' // 预付款
export const INVITE_STAFF = 'Invite_stall' // 邀请员工

export const TRAVEL_CARD = 'TravelCard_Goto_Detail' // 我的行程


/** 消息中心 */
export const MESSAGE_CENTER_APPROVAL_MESSAGE = 'MessageCenter_Goto_ApprovalMessage' // 审批信息
export const MESSAGE_CENTER_ORDER_MESSAGE = 'MessageCenter_Goto_OrderMessage' // 订单通知
export const MESSAGE_CENTER_FLIGHT_STATUS_MESSAGE = 'MessageCenter_Goto_FlightStatusMessage' // 航班动态
export const MESSAGE_CENTER_ACTIVITY_MESSAGE = 'MessageCenter_Goto_ActivityMessage' // 活动通知

/** 我的客服 */
export const PERSONAL_CUSTOM_SERVICE = 'Personal_Goto_CustomService' // 客服
