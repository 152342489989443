export const UPDATE_DIRECTION = 'UPDATE_DIRECTION'
export const SET_IS_MINIPROGRAM = 'SET_IS_MINIPROGRAM'
export const SET_IS_WECHAT_BROWSER = 'SET_IS_WECHAT_BROWSER'
export const SET_IS_EMOBILE7_BROWSER = 'SET_IS_EMOBILE7_BROWSER'
export const SET_FORCE_BACK = 'SET_FORCE_BACK'
export const UPDATE_PERSONAL_INFOR = 'UPDATE_PERSONAL_INFOR'
export const UPDATE_PERSONAL_POWER = 'UPDATE_PERSONAL_POWER'
export const UPDATE_NOTICE_DETAILS = 'UPDATE_NOTICE_DETAILS'
export const UPDATE_PERSONAL_SETTING = 'UPDATE_PERSONAL_SETTING'
export const UPDATE_THEME_SETTING = 'UPDATE_THEME_SETTING'
export const SET_USER_MANAGE_INFO = 'SET_USER_MANAGE_INFO'
export const UPDATE_AMAP_KEY = 'UPDATE_AMAP_KEY'
