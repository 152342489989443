import store from '@/store'
export default function(key) {
  if (!(store && store.state && store.state.PersonalInfor)) {
    return ''
  }
  let PersonalInfor = store.state.PersonalInfor
  if (key === '用户信息:公司名称') {
    return PersonalInfor.UserInfo.CompanyName
  } else if (key === '用户信息:UserID') {
    return PersonalInfor.UserInfo.UserID
  }
}
