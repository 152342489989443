// 承載router-view的components
const Start = resolve => {
  import('components/start/start').then(module => {
    resolve(module);
  });
};

export default {
  path: '/inter-hotel',
  component: Start,
  children: [
    {
      // 国际酒店订单列表
      name: 'interHotelOrderList',
      path: 'orderList',
      component: resolve =>
        require(['pages/inter-hotel/order/order-list/order-list.vue'], resolve),
      meta: {
        keepAlive: true
      }
    },
    {
      path: 'orderDetails',
      name: '国际酒店订单详情',
      component: resolve =>
        require(['pages/inter-hotel/order/order-details/order-details.vue'], resolve)
    },
    {
      // 国际酒店列表
      name: 'interHotelInforList',
      path: 'inforList',
      component: resolve =>
        require(['pages/inter-hotel/infor-list/infor-list.vue'], resolve),
      meta: {
        keepAlive: true
      }
    },
    {
      path: 'inforDetails',
      name: '国际酒店详情',
      component: resolve =>
        require(['pages/inter-hotel/infor-details/infor-details.vue'], resolve),
      meta: {
        keepAlive: true
      }
    },
    {
      path: 'interHotelInforMap4App',
      name: '开放给app使用的谷歌地图页面',
      component: resolve =>
        require(['pages/inter-hotel/infor-details/infor-details-map4app.vue'], resolve)
    },
    {
      // 国际酒店订单填写
      name: 'interHotelBookDetails',
      path: 'bookDetails',
      component: resolve =>
        require(['pages/inter-hotel/book/book-details.vue'], resolve)
    },
    {
      // 国际酒店订单确认
      name: 'interHotelConfirm',
      path: 'orderConfirm',
      component: resolve =>
        require(['pages/inter-hotel/order/order-confirm/order-confirm.vue'], resolve)
    },
    {
      // 国内酒店退单
      name: 'interRefund',
      path: 'refund',
      component: resolve =>
        require(['pages/inter-hotel/refund/refund.vue'], resolve)
    },
    {
      // 国内酒店退单 详情接口
      name: 'interRefundDetails',
      path: 'refundDetails',
      component: resolve =>
        require(['pages/inter-hotel/refund/refund-details.vue'], resolve)
    }
  ]
}
