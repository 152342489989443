// 承載router-view的components
const Start = resolve => {
  import('components/start/start').then(module => {
    resolve(module);
  });
};

export default {
  path: '/business',
  component: Start,
  children: [
    {
      path: 'staff',
      name: '员工管理',
      component: resolve => require(['pages/management/staff/staff/staff.vue'], resolve),
      meta: {
        keepAlive: true
      }
    },
    {
      path: 'staff/viewAndBooking',
      name: '查看预订权限',
      component: resolve => require(['pages/management/staff/staff/viewAndBooking/viewAndBooking.vue'], resolve)
    },
    {
      path: 'unstaff',
      name: '非员工管理',
      component: resolve =>
        require(['pages/management/staff/unstaff/unstaff.vue'], resolve)
    },
    {
      path: 'wechatStaff',
      name: '企业微信员工绑定',
      component: resolve =>
        require(['pages/management/staff/wechatStaff/wechatStaff.vue'], resolve)
    },
    {
      path: 'nailStaff',
      name: '钉钉员工绑定',
      component: resolve =>
        require(['pages/management/staff/nailStaff/nailStaff.vue'], resolve)
    },
    {
      path: 'larkStaff',
      name: '飞书员工绑定',
      component: resolve =>
        require(['pages/management/staff/larkStaff/larkStaff.vue'], resolve)
    },
    {
      path: 'role',
      name: '角色管理',
      component: resolve =>
        require(['pages/management/staff/role/role.vue'], resolve)
    },
    {
      path: 'info',
      name: '基本信息',
      component: resolve =>
        require(['pages/management/company/info/info.vue'], resolve)
    },
    {
      path: 'department',
      name: '组织架构',
      component: resolve =>
        require(['pages/management/company/department/department.vue'], resolve)
    },
    {
      path: 'cost',
      name: '成本中心',
      component: resolve =>
        require(['pages/management/company/cost/cost.vue'], resolve)
    },
    {
      path: 'bu',
      name: 'BU管理',
      component: resolve =>
        require(['pages/management/company/BU/BU.vue'], resolve)
    },
    {
      path: 'custom',
      name: '自定义项',
      component: resolve =>
        require(['pages/management/company/custom/custom.vue'], resolve)
    },
    {
      path: 'level',
      name: '差标管理',
      component: resolve =>
        require(['pages/management/company/level/level.vue'], resolve)
    },
    {
      path: 'rc',
      name: 'RC管理',
      component: resolve =>
        require(['pages/management/company/RC/RC.vue'], resolve)
    },
    {
      path: 'invoice',
      name: '开票信息',
      component: resolve =>
        require(['pages/management/company/invoice/invoice.vue'], resolve)
    },
    {
      path: 'setting',
      name: '审批配置',
      component: resolve =>
        require(['pages/management/approval/setting/setting.vue'], resolve)
    },
    {
      path: 'setting/approvers',
      name: '审批配置_指定审批人',
      component: resolve =>
        require(['pages/management/approval/setting/approvers.vue'], resolve)
    },
    {
      path: 'setting/templates',
      name: '审批配置_审批模板列表',
      component: resolve =>
        require(['pages/management/approval/setting/templates.vue'], resolve)
    },
    {
      path: 'white',
      name: '审批白名单',
      component: resolve =>
        require(['pages/management/approval/white/white.vue'], resolve)
    }
  ]
}
