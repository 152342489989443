<template>
  <div id="app" :class="{'ios-safe-bottom': !isEMobile7Browser}">
    <div ref="fixPositionBugInIphone7"></div>
    <!-- <transition :name="`vux-pop-${direction === 'forward' ? 'in' : 'out'}`"> -->
    <router-view/>
    <!-- </transition> -->
  </div>
</template>

<script type="text/ecmascript-6">
import {mapGetters, mapMutations} from 'vuex'
import {useDebounceFn, useSwipe} from "@vueuse/core";
import {kpiSystemCollect} from "@/api";
export default {
  name: 'App',
  computed: {
    ...mapGetters([
      'direction',
      'isEMobile7Browser'
    ])
  },
  created() {
    let ua = window.navigator.userAgent.toLowerCase() // 获取判断用的对象
    this.SET_IS_EMOBILE7_BROWSER(/e-mobile7/.test(ua))
  },
  watch: {
    '$route.path'(to, from) {
      kpiSystemCollect({ED: '', ET: 3, FP: from, TP: to})
    }
  },
  mounted() {
    window.onresize = () => {
      this.scroll && this.scroll.forceUpdate()
    }
    /**
     * 移动端布局使用fixed布局的时候, 键盘弹起会将fixed元素变为absolute, 收起键盘的时候变为fixed
     * 但是在iphone7下,这部分没有被很好地处理,所以会出现整个页面逻辑错位的情况
     * 参照网上的方法,监听键盘弹起人工去实现position属性fixed<=>absolute也没有起到作用
     * 无意中发现,在这里添加一个fixPositionBugInIphone7 DIV,监听键盘弹起和收起, 改变这块多余的DIV的高度, 可以触发页面的渲染恢复
     * 故这段代码,主要是修复这个问题
     */
    document.body.addEventListener('focusin', () => {
      this.$refs.fixPositionBugInIphone7.style.height = '1px'
      document.body.style.position = 'absolute'
    })
    document.body.addEventListener('focusout', () => {
      this.$refs.fixPositionBugInIphone7.style.height = '0px'
      document.body.style.position = 'fixed'
      document.body.style.bottom = '0'
    })
    // ================================================================

    this.touchstartFn = useDebounceFn((e, path) => {
      kpiSystemCollect({ED: this.getED(e.target), ET: 1, TP: path})
    }, 500)

    // 全局点击事件监听
    document.body.removeEventListener('touchstart', this.touchstart)
    document.body.addEventListener('touchstart', this.touchstart)

    let _this = this
    const { direction, isSwiping, lengthX, lengthY } = useSwipe(document.body, {
      onSwipeEnd(e) {
        kpiSystemCollect({ED: _this.getED(e.target), ET: 2, TP: _this.$route.path})
      },
    })
    console.log(direction.value, isSwiping.value, lengthX.value, lengthY.value)
  },
  destroyed() {
    document.body.removeEventListener('touchstart', this.touchstart)
  },
  methods: {
    ...mapMutations([
      'SET_IS_EMOBILE7_BROWSER'
    ]),
    touchstart(e) {
      this.touchstartFn(e, this.$route.path)
    },
    touchstartFn() {},
    getED (data) {
      if (!data) {
        return ''
      }

      if (data.outerText && data.outerText.length <= 200) {
        return data.outerText
      }

      if (data.outerHTML && data.outerHTML.length <= 200) {
        return data.outerHTML
      }
      return ''
    }
  }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
#app
  width 100%
  /** 兼容ios底部横条 */
  height 100%
  position relative
  &.ios-safe-bottom
    height calc( 100% - constant(safe-area-inset-bottom))
    height calc( 100% - env(safe-area-inset-bottom))
</style>
