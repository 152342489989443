export default function(StartDate, EndDate, isQuarter = false) {
  if (StartDate && EndDate) {
    let year = StartDate.split('-')[0]
    let startMonth = StartDate.split('-')[1]
    let endMonth = EndDate.split('-')[1]
    if (isQuarter) {
      let list = [`${year - 1}.1季度`, `${year - 1}.2季度`, `${year - 1}.3季度`, `${year - 1}.4季度`, `${year}.1季度`, `${year}.2季度`, `${year}.3季度`, `${year}.4季度`]
      if (Number(endMonth) <= 3) {
        list = list.slice(0, 6)
      } else if (Number(endMonth) <= 6) {
        list = list.slice(1, 7)
      } else if (Number(endMonth) <= 9) {
        list = list.slice(2, 8)
      } else {
        list = list.slice(3, 9)
      }
      return list
    } else {
      let list = [`${year}-01`, `${year}-02`, `${year}-03`, `${year}-04`, `${year}-05`, `${year}-06`, `${year}-07`, `${year}-08`, `${year}-09`, `${year}-10`, `${year}-11`, `${year}-12`]
      list = list.filter(v => {
        let month = v.split('-')[1]
        return Number(month) >= Number(startMonth) && Number(month) <= Number(endMonth)
      })
      return list
    }
  } else {
    return []
  }
}
