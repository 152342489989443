// hasPower
import store from '@/store'
export default function(key) {
  if (!(store && store.state && store.state.PersonalPower)) {
    return false
  }
  let PersonalPower = store.state.PersonalPower
  if (key === '单位管理') {
    return (PersonalPower.ManagePermission && PersonalPower.ManagePermission.CompanyManagePermission && PersonalPower.ManagePermission.CompanyManagePermission.IsEnable)
  } else if (key === '单位管理:员工信息') {
    return (PersonalPower.ManagePermission && PersonalPower.ManagePermission.CompanyManagePermission && PersonalPower.ManagePermission.CompanyManagePermission.UnitPersonInfoPermission && PersonalPower.ManagePermission.CompanyManagePermission.UnitPersonInfoPermission.IsEnable)
  } else if (key === '单位管理:员工信息:员工管理') {
    return (PersonalPower.ManagePermission && PersonalPower.ManagePermission.CompanyManagePermission && PersonalPower.ManagePermission.CompanyManagePermission.UnitPersonInfoPermission && PersonalPower.ManagePermission.CompanyManagePermission.UnitPersonInfoPermission.IsEnableUnitPersonManage)
  } else if (key === '单位管理:员工信息:非员工管理') {
    return (PersonalPower.ManagePermission && PersonalPower.ManagePermission.CompanyManagePermission && PersonalPower.ManagePermission.CompanyManagePermission.UnitPersonInfoPermission && PersonalPower.ManagePermission.CompanyManagePermission.UnitPersonInfoPermission.IsEnableNupUnitPersonManage)
  } else if (key === '单位管理:员工信息:角色管理') {
    return (PersonalPower.ManagePermission && PersonalPower.ManagePermission.CompanyManagePermission && PersonalPower.ManagePermission.CompanyManagePermission.UnitPersonInfoPermission && PersonalPower.ManagePermission.CompanyManagePermission.UnitPersonInfoPermission.IsEnableRoleManage)
  } else if (key === '单位管理:员工信息:企业微信员工绑定') {
    return (PersonalPower.ManagePermission && PersonalPower.ManagePermission.CompanyManagePermission && PersonalPower.ManagePermission.CompanyManagePermission.UnitPersonInfoPermission && PersonalPower.ManagePermission.CompanyManagePermission.UnitPersonInfoPermission.IsEnableWechatUnitPersonBind)
  } else if (key === '单位管理:员工信息:钉钉员工绑定') {
    return (PersonalPower.ManagePermission && PersonalPower.ManagePermission.CompanyManagePermission && PersonalPower.ManagePermission.CompanyManagePermission.UnitPersonInfoPermission && PersonalPower.ManagePermission.CompanyManagePermission.UnitPersonInfoPermission.IsEnableDingTalkPersonBind)
  } else if (key === '单位管理:员工信息:飞书员工绑定') {
    return (PersonalPower.ManagePermission && PersonalPower.ManagePermission.CompanyManagePermission && PersonalPower.ManagePermission.CompanyManagePermission.UnitPersonInfoPermission && PersonalPower.ManagePermission.CompanyManagePermission.UnitPersonInfoPermission.IsEnableFeiShuPersonBind)
  } else if (key === '单位管理:单位信息') {
    return (PersonalPower.ManagePermission && PersonalPower.ManagePermission.CompanyManagePermission && PersonalPower.ManagePermission.CompanyManagePermission.CompanyInfoPermission && PersonalPower.ManagePermission.CompanyManagePermission.CompanyInfoPermission.IsEnable)
  } else if (key === '单位管理:单位信息:基本信息') {
    return (PersonalPower.ManagePermission && PersonalPower.ManagePermission.CompanyManagePermission && PersonalPower.ManagePermission.CompanyManagePermission.CompanyInfoPermission && PersonalPower.ManagePermission.CompanyManagePermission.CompanyInfoPermission.IsEnableCompanyBaseInfo)
  } else if (key === '单位管理:单位信息:组织架构') {
    return (PersonalPower.ManagePermission && PersonalPower.ManagePermission.CompanyManagePermission && PersonalPower.ManagePermission.CompanyManagePermission.CompanyInfoPermission && PersonalPower.ManagePermission.CompanyManagePermission.CompanyInfoPermission.IsEnableDepartmentManage)
  } else if (key === '单位管理:单位信息:成本中心') {
    return (PersonalPower.ManagePermission && PersonalPower.ManagePermission.CompanyManagePermission && PersonalPower.ManagePermission.CompanyManagePermission.CompanyInfoPermission && PersonalPower.ManagePermission.CompanyManagePermission.CompanyInfoPermission.IsEnableCostCenterManage)
  } else if (key === '单位管理:单位信息:BU管理') {
    return (PersonalPower.ManagePermission && PersonalPower.ManagePermission.CompanyManagePermission && PersonalPower.ManagePermission.CompanyManagePermission.CompanyInfoPermission && PersonalPower.ManagePermission.CompanyManagePermission.CompanyInfoPermission.IsEnableBUManage)
  } else if (key === '单位管理:单位信息:自定义项') {
    return (PersonalPower.ManagePermission && PersonalPower.ManagePermission.CompanyManagePermission && PersonalPower.ManagePermission.CompanyManagePermission.CompanyInfoPermission && PersonalPower.ManagePermission.CompanyManagePermission.CompanyInfoPermission.IsEnableCustomizationManage)
  } else if (key === '单位管理:单位信息:职级管理') {
    return (PersonalPower.ManagePermission && PersonalPower.ManagePermission.CompanyManagePermission && PersonalPower.ManagePermission.CompanyManagePermission.CompanyInfoPermission && PersonalPower.ManagePermission.CompanyManagePermission.CompanyInfoPermission.IsEnableRankManage)
  } else if (key === '单位管理:单位信息:职级管理:机票') {
    return (PersonalPower.ManagePermission && PersonalPower.ManagePermission.CompanyManagePermission && PersonalPower.ManagePermission.CompanyManagePermission.CompanyInfoPermission && PersonalPower.ManagePermission.CompanyManagePermission.CompanyInfoPermission.IsEnableFlightRankManage)
  } else if (key === '单位管理:单位信息:职级管理:国际机票') {
    return (PersonalPower.ManagePermission && PersonalPower.ManagePermission.CompanyManagePermission && PersonalPower.ManagePermission.CompanyManagePermission.CompanyInfoPermission && PersonalPower.ManagePermission.CompanyManagePermission.CompanyInfoPermission.IsEnableIntlFlightRankManage)
  } else if (key === '单位管理:单位信息:职级管理:国内酒店') {
    return (PersonalPower.ManagePermission && PersonalPower.ManagePermission.CompanyManagePermission && PersonalPower.ManagePermission.CompanyManagePermission.CompanyInfoPermission && PersonalPower.ManagePermission.CompanyManagePermission.CompanyInfoPermission.IsEnableHotelRankManage)
  } else if (key === '单位管理:单位信息:职级管理:国际酒店') {
    return (PersonalPower.ManagePermission && PersonalPower.ManagePermission.CompanyManagePermission && PersonalPower.ManagePermission.CompanyManagePermission.CompanyInfoPermission && PersonalPower.ManagePermission.CompanyManagePermission.CompanyInfoPermission.IsEnableIntlHotelRankManage)
  } else if (key === '单位管理:单位信息:职级管理:火车票') {
    return (PersonalPower.ManagePermission && PersonalPower.ManagePermission.CompanyManagePermission && PersonalPower.ManagePermission.CompanyManagePermission.CompanyInfoPermission && PersonalPower.ManagePermission.CompanyManagePermission.CompanyInfoPermission.IsEnableTrainRankManage)
  } else if (key === '单位管理:单位信息:职级管理:用车') {
    return (PersonalPower.ManagePermission && PersonalPower.ManagePermission.CompanyManagePermission && PersonalPower.ManagePermission.CompanyManagePermission.CompanyInfoPermission && PersonalPower.ManagePermission.CompanyManagePermission.CompanyInfoPermission.IsEnableCarRankManage)
  } else if (key === '单位管理:单位信息:职级管理:汽车票') {
    return (PersonalPower.ManagePermission && PersonalPower.ManagePermission.CompanyManagePermission && PersonalPower.ManagePermission.CompanyManagePermission.CompanyInfoPermission && PersonalPower.ManagePermission.CompanyManagePermission.CompanyInfoPermission.IsEnableBusRankManage)
  } else if (key === '单位管理:单位信息:职级管理:用餐') {
    return (PersonalPower.ManagePermission && PersonalPower.ManagePermission.CompanyManagePermission && PersonalPower.ManagePermission.CompanyManagePermission.CompanyInfoPermission && PersonalPower.ManagePermission.CompanyManagePermission.CompanyInfoPermission.IsEnableDiningRankManage)
  } else if (key === '单位管理:单位信息:RC管理') {
    return (PersonalPower.ManagePermission && PersonalPower.ManagePermission.CompanyManagePermission && PersonalPower.ManagePermission.CompanyManagePermission.CompanyInfoPermission && PersonalPower.ManagePermission.CompanyManagePermission.CompanyInfoPermission.IsEnableReasonCodeManage)
  } else if (key === '单位管理:单位信息:RC管理:机票最低价') {
    return (PersonalPower.ManagePermission && PersonalPower.ManagePermission.CompanyManagePermission && PersonalPower.ManagePermission.CompanyManagePermission.CompanyInfoPermission && PersonalPower.ManagePermission.CompanyManagePermission.CompanyInfoPermission.IsEnableReasonCodeFlightLowestPrice)
  } else if (key === '单位管理:单位信息:RC管理:机票职级') {
    return (PersonalPower.ManagePermission && PersonalPower.ManagePermission.CompanyManagePermission && PersonalPower.ManagePermission.CompanyManagePermission.CompanyInfoPermission && PersonalPower.ManagePermission.CompanyManagePermission.CompanyInfoPermission.IsEnableReasonCodeFlightManage)
  } else if (key === '单位管理:单位信息:RC管理:酒店职级') {
    return (PersonalPower.ManagePermission && PersonalPower.ManagePermission.CompanyManagePermission && PersonalPower.ManagePermission.CompanyManagePermission.CompanyInfoPermission && PersonalPower.ManagePermission.CompanyManagePermission.CompanyInfoPermission.IsEnableReasonCodeHotelManage)
  } else if (key === '单位管理:单位信息:RC管理:火车票职级') {
    return (PersonalPower.ManagePermission && PersonalPower.ManagePermission.CompanyManagePermission && PersonalPower.ManagePermission.CompanyManagePermission.CompanyInfoPermission && PersonalPower.ManagePermission.CompanyManagePermission.CompanyInfoPermission.IsEnableReasonCodeTrainManage)
  } else if (key === '单位管理:单位信息:RC管理:用车职级') {
    return (PersonalPower.ManagePermission && PersonalPower.ManagePermission.CompanyManagePermission && PersonalPower.ManagePermission.CompanyManagePermission.CompanyInfoPermission && PersonalPower.ManagePermission.CompanyManagePermission.CompanyInfoPermission.IsEnableReasonCodeCarManage)
  } else if (key === '单位管理:单位信息:RC管理:汽车票职级') {
    return (PersonalPower.ManagePermission && PersonalPower.ManagePermission.CompanyManagePermission && PersonalPower.ManagePermission.CompanyManagePermission.CompanyInfoPermission && PersonalPower.ManagePermission.CompanyManagePermission.CompanyInfoPermission.IsEnableReasonCodeBusManage)
  } else if (key === '单位管理:单位信息:RC管理:用餐职级') {
    return (PersonalPower.ManagePermission && PersonalPower.ManagePermission.CompanyManagePermission && PersonalPower.ManagePermission.CompanyManagePermission.CompanyInfoPermission && PersonalPower.ManagePermission.CompanyManagePermission.CompanyInfoPermission.IsEnableReasonCodeDiningManage)
  } else if (key === '单位管理:单位信息:开票信息') {
    return (PersonalPower.ManagePermission && PersonalPower.ManagePermission.CompanyManagePermission && PersonalPower.ManagePermission.CompanyManagePermission.CompanyInfoPermission && PersonalPower.ManagePermission.CompanyManagePermission.CompanyInfoPermission.IsEnableInvoiceManage)
  } else if (key === '单位管理:单位信息:差旅报表') {
    return (PersonalPower.ManagePermission && PersonalPower.ManagePermission.CompanyManagePermission && PersonalPower.ManagePermission.CompanyManagePermission.DataAnalysisReportPermission && PersonalPower.ManagePermission.CompanyManagePermission.DataAnalysisReportPermission.IsEnable)
  } else if (key === '单位管理:单位信息:云账单') {
    return (PersonalPower.ManagePermission && PersonalPower.ManagePermission.CompanyManagePermission && PersonalPower.ManagePermission.CompanyManagePermission.AccountPermission && PersonalPower.ManagePermission.CompanyManagePermission.AccountPermission.IsEnable)
  } else if (key === '单位管理:审批信息') {
    return (PersonalPower.ManagePermission && PersonalPower.ManagePermission.CompanyManagePermission && PersonalPower.ManagePermission.CompanyManagePermission.ApprovalInfoPermission && PersonalPower.ManagePermission.CompanyManagePermission.ApprovalInfoPermission.IsEnable)
  } else if (key === '单位管理:审批信息:审批配置') {
    return (PersonalPower.ManagePermission && PersonalPower.ManagePermission.CompanyManagePermission && PersonalPower.ManagePermission.CompanyManagePermission.ApprovalInfoPermission && PersonalPower.ManagePermission.CompanyManagePermission.ApprovalInfoPermission.IsEnableApprovalConfiguration)
  } else if (key === '单位管理:审批信息:审批白名单') {
    return (PersonalPower.ManagePermission && PersonalPower.ManagePermission.CompanyManagePermission && PersonalPower.ManagePermission.CompanyManagePermission.ApprovalInfoPermission && PersonalPower.ManagePermission.CompanyManagePermission.ApprovalInfoPermission.IsEnableVettingWhite)
  // ============================================================================================
  } else if (key === '因公') {
    return (PersonalPower.BusinessPermission && PersonalPower.BusinessPermission.IsEnable)
  } else if (key === '因公:机票') {
    return (PersonalPower.BusinessPermission && PersonalPower.BusinessPermission.FlightPermission && PersonalPower.BusinessPermission.FlightPermission.IsEnable)
  } else if (key === '因公:机票:国内机票查询') {
    return (PersonalPower.BusinessPermission && PersonalPower.BusinessPermission.FlightPermission && PersonalPower.BusinessPermission.FlightPermission.IsEnableFlightSearch)
  } else if (key === '因公:机票:国内机票预订') {
    return (PersonalPower.BusinessPermission && PersonalPower.BusinessPermission.FlightPermission && PersonalPower.BusinessPermission.FlightPermission.IsEnableFlightBook)
  } else if (key === '因公:机票:国际机票查询') {
    return (PersonalPower.BusinessPermission && PersonalPower.BusinessPermission.FlightPermission && PersonalPower.BusinessPermission.FlightPermission.IsEnableIntlFlightSearch)
  } else if (key === '因公:机票:国际机票预订') {
    return (PersonalPower.BusinessPermission && PersonalPower.BusinessPermission.FlightPermission && PersonalPower.BusinessPermission.FlightPermission.IsEnableIntlFlightBook)
  } else if (key === '因公:国际机票询价单') {
    return (PersonalPower.BusinessPermission && PersonalPower.BusinessPermission.FlightPermission && PersonalPower.BusinessPermission.FlightPermission.IsEnableIntlFlightInquirySheet)
  } else if (key === '因公:酒店') {
    return (PersonalPower.BusinessPermission && PersonalPower.BusinessPermission.HotelPermission && PersonalPower.BusinessPermission.HotelPermission.IsEnable)
  } else if (key === '因公:酒店:国内酒店查询') {
    return (PersonalPower.BusinessPermission && PersonalPower.BusinessPermission.HotelPermission && PersonalPower.BusinessPermission.HotelPermission.IsEnableHotelSearch)
  } else if (key === '因公:酒店:国内酒店预订') {
    return (PersonalPower.BusinessPermission && PersonalPower.BusinessPermission.HotelPermission && PersonalPower.BusinessPermission.HotelPermission.IsEnableHotelBook)
  } else if (key === '因公:酒店:国际酒店查询') {
    return (PersonalPower.BusinessPermission && PersonalPower.BusinessPermission.HotelPermission && PersonalPower.BusinessPermission.HotelPermission.IsEnableIntlHotelSearch)
  } else if (key === '因公:酒店:国际酒店预订') {
    return (PersonalPower.BusinessPermission && PersonalPower.BusinessPermission.HotelPermission && PersonalPower.BusinessPermission.HotelPermission.IsEnableIntlHotelBook)
  } else if (key === '因公:火车票') {
    return (PersonalPower.BusinessPermission && PersonalPower.BusinessPermission.TrainPermission && PersonalPower.BusinessPermission.TrainPermission.IsEnable)
  } else if (key === '因公:火车票:火车票查询') {
    return (PersonalPower.BusinessPermission && PersonalPower.BusinessPermission.TrainPermission && PersonalPower.BusinessPermission.TrainPermission.IsEnableTrainSearch)
  } else if (key === '因公:火车票:火车票预订') {
    return (PersonalPower.BusinessPermission && PersonalPower.BusinessPermission.TrainPermission && PersonalPower.BusinessPermission.TrainPermission.IsEnableTrainBook)
  } else if (key === '因公:用车') {
    return (PersonalPower.BusinessPermission && PersonalPower.BusinessPermission.CarPermission && PersonalPower.BusinessPermission.CarPermission.IsEnable)
  } else if (key === '因公:用车:打车查询') {
    return (PersonalPower.BusinessPermission && PersonalPower.BusinessPermission.CarPermission && PersonalPower.BusinessPermission.CarPermission.IsEnableCarSearch)
  } else if (key === '因公:用车:预订') {
    return (PersonalPower.BusinessPermission && PersonalPower.BusinessPermission.CarPermission && PersonalPower.BusinessPermission.CarPermission.IsEnableCarBook)
  } else if (key === '因公:用车:预约用车查询') {
    return (PersonalPower.BusinessPermission && PersonalPower.BusinessPermission.CarPermission && PersonalPower.BusinessPermission.CarPermission.IsEnableCarSearchBooking)
  } else if (key === '因公:用车:预约用车预订') {
    return (PersonalPower.BusinessPermission && PersonalPower.BusinessPermission.CarPermission && PersonalPower.BusinessPermission.CarPermission.IsEnableCarBookBooking)
  } else if (key === '因公:用车:接送飞机查询') {
    return (PersonalPower.BusinessPermission && PersonalPower.BusinessPermission.CarPermission && PersonalPower.BusinessPermission.CarPermission.IsEnableFlightSearch)
  } else if (key === '因公:用车:接送飞机预订') {
    return (PersonalPower.BusinessPermission && PersonalPower.BusinessPermission.CarPermission && PersonalPower.BusinessPermission.CarPermission.IsEnableFlightBook)
  } else if (key === '因公:用车:接送火车查询') {
    return (PersonalPower.BusinessPermission && PersonalPower.BusinessPermission.CarPermission && PersonalPower.BusinessPermission.CarPermission.IsEnableTrainSearch)
  } else if (key === '因公:用车:接送火车预订') {
    return (PersonalPower.BusinessPermission && PersonalPower.BusinessPermission.CarPermission && PersonalPower.BusinessPermission.CarPermission.IsEnableTrainBook)
  } else if (key === '因公:差旅报表') {
    return (PersonalPower.BusinessPermission && PersonalPower.BusinessPermission.IsEnableTravelReportForm)
  } else if (key === '因公:旅游会务') {
    return (PersonalPower.BusinessPermission && PersonalPower.BusinessPermission.TravelConferencePermission && PersonalPower.BusinessPermission.TravelConferencePermission.IsEnable)
  } else if (key === '因公:用餐') {
    return (PersonalPower.BusinessPermission && PersonalPower.BusinessPermission.DiningPermission && PersonalPower.BusinessPermission.DiningPermission.IsEnable)
  } else if (key === '因公:出差申请单') {
    return (PersonalPower.BusinessPermission && PersonalPower.BusinessPermission.TripApplicationFormPermission && PersonalPower.BusinessPermission.TripApplicationFormPermission.IsEnable)
  } else if (key === '因公:汽车票') {
    return (PersonalPower.BusinessPermission && PersonalPower.BusinessPermission.BusPermission && PersonalPower.BusinessPermission.BusPermission.IsEnable)
  } else if (key === '因公:汽车票:汽车票查询') {
    return (PersonalPower.BusinessPermission && PersonalPower.BusinessPermission.BusPermission && PersonalPower.BusinessPermission.BusPermission.IsEnableBusSearch)
  } else if (key === '因公:汽车票:汽车票预订') {
    return (PersonalPower.BusinessPermission && PersonalPower.BusinessPermission.BusPermission && PersonalPower.BusinessPermission.BusPermission.IsEnableBusBook)
    // ============================================================================================
  } else if (key === '因私') {
    return (PersonalPower.PrivatePermission && PersonalPower.PrivatePermission.IsEnable)
  } else if (key === '因私:国内机票') {
    return (PersonalPower.PrivatePermission && PersonalPower.PrivatePermission.IsEnableFlight)
  } else if (key === '因私:国际机票') {
    return (PersonalPower.PrivatePermission && PersonalPower.PrivatePermission.IsEnableIntlFlight)
  } else if (key === '因私:国际机票询价单') {
    return (PersonalPower.PrivatePermission && PersonalPower.PrivatePermission.IsEnableIntlFlightInquirySheet)
  } else if (key === '因私:国内酒店') {
    return (PersonalPower.PrivatePermission && PersonalPower.PrivatePermission.IsEnableHotel)
  } else if (key === '因私:国际酒店') {
    return (PersonalPower.PrivatePermission && PersonalPower.PrivatePermission.IsEnableIntlHotel)
  } else if (key === '因私:火车票') {
    return (PersonalPower.PrivatePermission && PersonalPower.PrivatePermission.IsEnableTrain)
  } else if (key === '因私:用车') {
    return (PersonalPower.PrivatePermission && PersonalPower.PrivatePermission.IsEnableCar)
  } else if (key === '因私:用车:打车查询') {
    return (PersonalPower.PrivatePermission && PersonalPower.PrivatePermission.CarPermission && PersonalPower.PrivatePermission.CarPermission.IsEnableCarSearch)
  } else if (key === '因私:用餐') {
    return (PersonalPower.PrivatePermission && PersonalPower.PrivatePermission.IsEnableDining)
  } else if (key === '因私:汽车票') {
    return (PersonalPower.PrivatePermission && PersonalPower.PrivatePermission.IsEnableBus)
    // ============================================================================================
  } else if (key === '行程') {
    return (PersonalPower.TripPermission && PersonalPower.TripPermission.IsEnable)
  } else if (key === '行程:关注') {
    return (PersonalPower.TripPermission && PersonalPower.TripPermission.IsEnableAttention)
    // ============================================================================================
  } else if (key === '客服:国内机票') {
    return (PersonalPower.CustomerServiceQAPermission && PersonalPower.CustomerServiceQAPermission.IsEnableFlight)
  } else if (key === '客服:国际机票') {
    return (PersonalPower.CustomerServiceQAPermission && PersonalPower.CustomerServiceQAPermission.IsEnableIntlFlight)
  } else if (key === '客服:国内酒店') {
    return (PersonalPower.CustomerServiceQAPermission && PersonalPower.CustomerServiceQAPermission.IsEnableHotel)
  } else if (key === '客服:国际酒店') {
    return (PersonalPower.CustomerServiceQAPermission && PersonalPower.CustomerServiceQAPermission.IsEnableIntlHotel)
  } else if (key === '客服:火车票') {
    return (PersonalPower.CustomerServiceQAPermission && PersonalPower.CustomerServiceQAPermission.IsEnableTrain)
  } else if (key === '客服:用车') {
    return (PersonalPower.CustomerServiceQAPermission && PersonalPower.CustomerServiceQAPermission.IsEnableCar)
  } else if (key === '客服:用餐') {
    return (PersonalPower.CustomerServiceQAPermission && PersonalPower.CustomerServiceQAPermission.IsEnableDining)
  } else if (key === '客服:汽车票') {
    return (PersonalPower.CustomerServiceQAPermission && PersonalPower.CustomerServiceQAPermission.IsEnableBus)
  } else if (key === '客服:常旅客') {
    return (PersonalPower.CustomerServiceQAPermission && PersonalPower.CustomerServiceQAPermission.IsEnableFrequentTraveler)
  } else if (key === '客服:审批') {
    return (PersonalPower.CustomerServiceQAPermission && PersonalPower.CustomerServiceQAPermission.IsEnableVetting)
  } else if (key === '客服:其他') {
    return (PersonalPower.CustomerServiceQAPermission && PersonalPower.CustomerServiceQAPermission.IsEnableOther)
  } else if (key === '客服:机票预订演示') {
    return (PersonalPower.CustomerServiceQAPermission && PersonalPower.CustomerServiceQAPermission.BookingFlightShowPermission && PersonalPower.CustomerServiceQAPermission.BookingFlightShowPermission.IsEnable)
  } else if (key === '客服:机票预订演示:标题') {
    return (PersonalPower.CustomerServiceQAPermission && PersonalPower.CustomerServiceQAPermission.BookingFlightShowPermission && PersonalPower.CustomerServiceQAPermission.BookingFlightShowPermission.MenuName)
  } else if (key === '客服:机票预订演示:地址') {
    return (PersonalPower.CustomerServiceQAPermission && PersonalPower.CustomerServiceQAPermission.BookingFlightShowPermission && PersonalPower.CustomerServiceQAPermission.BookingFlightShowPermission.Url)
  } else if (key === '客服:酒店预订演示') {
    return (PersonalPower.CustomerServiceQAPermission && PersonalPower.CustomerServiceQAPermission.BookingHotelShowPermission && PersonalPower.CustomerServiceQAPermission.BookingHotelShowPermission.IsEnable)
  } else if (key === '客服:酒店预订演示:标题') {
    return (PersonalPower.CustomerServiceQAPermission && PersonalPower.CustomerServiceQAPermission.BookingHotelShowPermission && PersonalPower.CustomerServiceQAPermission.BookingHotelShowPermission.MenuName)
  } else if (key === '客服:酒店预订演示:地址') {
    return (PersonalPower.CustomerServiceQAPermission && PersonalPower.CustomerServiceQAPermission.BookingHotelShowPermission && PersonalPower.CustomerServiceQAPermission.BookingHotelShowPermission.Url)
  } else if (key === '客服:审批预订演示') {
    return (PersonalPower.CustomerServiceQAPermission && PersonalPower.CustomerServiceQAPermission.ApprovalShowPermission && PersonalPower.CustomerServiceQAPermission.ApprovalShowPermission.IsEnable)
  } else if (key === '客服:审批预订演示:标题') {
    return (PersonalPower.CustomerServiceQAPermission && PersonalPower.CustomerServiceQAPermission.ApprovalShowPermission && PersonalPower.CustomerServiceQAPermission.ApprovalShowPermission.MenuName)
  } else if (key === '客服:审批预订演示:地址') {
    return (PersonalPower.CustomerServiceQAPermission && PersonalPower.CustomerServiceQAPermission.ApprovalShowPermission && PersonalPower.CustomerServiceQAPermission.ApprovalShowPermission.Url)
  } else if (key === '客服:飞书') {
    return (PersonalPower.CustomerServiceQAPermission && PersonalPower.CustomerServiceQAPermission.LarkShowPermission && PersonalPower.CustomerServiceQAPermission.LarkShowPermission.IsEnable)
  } else if (key === '客服:飞书:标题') {
    return (PersonalPower.CustomerServiceQAPermission && PersonalPower.CustomerServiceQAPermission.LarkShowPermission && PersonalPower.CustomerServiceQAPermission.LarkShowPermission.MenuName)
  } else if (key === '客服:飞书:地址') {
    return (PersonalPower.CustomerServiceQAPermission && PersonalPower.CustomerServiceQAPermission.LarkShowPermission && PersonalPower.CustomerServiceQAPermission.LarkShowPermission.Url)
  } else if (key === '客服:钉钉') {
    return (PersonalPower.CustomerServiceQAPermission && PersonalPower.CustomerServiceQAPermission.DingTalkShowPermission && PersonalPower.CustomerServiceQAPermission.DingTalkShowPermission.IsEnable)
  } else if (key === '客服:钉钉:标题') {
    return (PersonalPower.CustomerServiceQAPermission && PersonalPower.CustomerServiceQAPermission.DingTalkShowPermission && PersonalPower.CustomerServiceQAPermission.DingTalkShowPermission.MenuName)
  } else if (key === '客服:钉钉:地址') {
    return (PersonalPower.CustomerServiceQAPermission && PersonalPower.CustomerServiceQAPermission.DingTalkShowPermission && PersonalPower.CustomerServiceQAPermission.DingTalkShowPermission.Url)
  } else if (key === '客服:企业微信') {
    return (PersonalPower.CustomerServiceQAPermission && PersonalPower.CustomerServiceQAPermission.WeChatWorkShowPermission && PersonalPower.CustomerServiceQAPermission.WeChatWorkShowPermission.IsEnable)
  } else if (key === '客服:企业微信:标题') {
    return (PersonalPower.CustomerServiceQAPermission && PersonalPower.CustomerServiceQAPermission.WeChatWorkShowPermission && PersonalPower.CustomerServiceQAPermission.WeChatWorkShowPermission.MenuName)
  } else if (key === '客服:企业微信:地址') {
    return (PersonalPower.CustomerServiceQAPermission && PersonalPower.CustomerServiceQAPermission.WeChatWorkShowPermission && PersonalPower.CustomerServiceQAPermission.WeChatWorkShowPermission.Url)
  // ============================================================================================
  } else if (key === '消息') {
    return PersonalPower.MessagePermission && PersonalPower.MessagePermission.IsEnable
  } else if (key === '消息:审批信息') {
    return PersonalPower.MessagePermission && PersonalPower.MessagePermission.IsEnableApprovalInformation
  } else if (key === '消息:航班动态') {
    return PersonalPower.MessagePermission && PersonalPower.MessagePermission.IsEnableFlightDynamics
    /** **************************************************************************************************** */
  } else if (key === '我的:预付款') {
    return (PersonalPower.MyHomsomPermission && PersonalPower.MyHomsomPermission.IsDisplayPrePayment)
  } else if (key === '我的:旅行豆') {
    return (PersonalPower.MyHomsomPermission && PersonalPower.MyHomsomPermission.IsDisplayTravelBean)
  } else if (key === '我的:抵用券') {
    return (PersonalPower.MyHomsomPermission && PersonalPower.MyHomsomPermission.IsDisplayVoucher)
  } else if (key === '我的:个人钱包') {
    return (PersonalPower.MyHomsomPermission && PersonalPower.MyHomsomPermission.PersonalWalletPermission && PersonalPower.MyHomsomPermission.PersonalWalletPermission.IsEnable)
  } else if (key === '我的:个人钱包:充值') {
    return (PersonalPower.MyHomsomPermission && PersonalPower.MyHomsomPermission.PersonalWalletPermission && PersonalPower.MyHomsomPermission.PersonalWalletPermission.IsEnableRecharge)
  } else if (key === '我的:个人钱包:提现') {
    // return (PersonalPower.MyHomsomPermission && PersonalPower.MyHomsomPermission.PersonalWalletPermission && PersonalPower.MyHomsomPermission.PersonalWalletPermission.IsEnableWithdrawal)
    return false
  } else if (key === '我的:积分商城') {
    return (PersonalPower.MyHomsomPermission && PersonalPower.MyHomsomPermission.IsDisplayMembershipPointMall)
  } else if (key === '我的:授权码订单') {
    return (PersonalPower.MyHomsomPermission && PersonalPower.MyHomsomPermission.OrderPermission && PersonalPower.MyHomsomPermission.OrderPermission.IsEnableAuthorizationCode)
  } else if (key === '我的:集团会员卡') {
    return (PersonalPower.MyHomsomPermission && PersonalPower.MyHomsomPermission.IsDisplayLinkLogin && PersonalPower.MyHomsomPermission.IsDisplayLinkLogin)
  } else if (key === '我的:国内机票') {
    return (PersonalPower.MyHomsomPermission && PersonalPower.MyHomsomPermission.OrderPermission && PersonalPower.MyHomsomPermission.OrderPermission.IsEnableFlight)
  } else if (key === '我的:国内机票是否可退票') {
    return (PersonalPower.MyHomsomPermission && PersonalPower.MyHomsomPermission.OrderPermission && PersonalPower.MyHomsomPermission.OrderPermission.IsEnableFlightRefund)
  } else if (key === '我的:国内机票是否可改期') {
    return (PersonalPower.MyHomsomPermission && PersonalPower.MyHomsomPermission.OrderPermission && PersonalPower.MyHomsomPermission.OrderPermission.IsEnableFlightChange)
  } else if (key === '我的:国内机票在线值机') {
    return (PersonalPower.MyHomsomPermission && PersonalPower.MyHomsomPermission.OrderPermission && PersonalPower.MyHomsomPermission.OrderPermission.IsEnableFlightCheckOnline)
  } else if (key === '我的:国内机票退票') {
    return (PersonalPower.MyHomsomPermission && PersonalPower.MyHomsomPermission.OrderPermission && PersonalPower.MyHomsomPermission.OrderPermission.IsEnableRefund)
  } else if (key === '我的:国内酒店') {
    return (PersonalPower.MyHomsomPermission && PersonalPower.MyHomsomPermission.OrderPermission && PersonalPower.MyHomsomPermission.OrderPermission.IsEnableHotel)
  } else if (key === '我的:国际机票') {
    return (PersonalPower.MyHomsomPermission && PersonalPower.MyHomsomPermission.OrderPermission && PersonalPower.MyHomsomPermission.OrderPermission.IsEnableIntlFlight)
  } else if (key === '我的:国际机票询价单') {
    return (PersonalPower.MyHomsomPermission && PersonalPower.MyHomsomPermission.OrderPermission && PersonalPower.MyHomsomPermission.OrderPermission.IsEnableIntlFlightInquirySheet)
  } else if (key === '我的:国际酒店') {
    return (PersonalPower.MyHomsomPermission && PersonalPower.MyHomsomPermission.OrderPermission && PersonalPower.MyHomsomPermission.OrderPermission.IsEnableIntlHotel)
  } else if (key === '我的:火车票') {
    return (PersonalPower.MyHomsomPermission && PersonalPower.MyHomsomPermission.OrderPermission && PersonalPower.MyHomsomPermission.OrderPermission.IsEnableTrain)
  } else if (key === '我的:火车票退票') {
    return (PersonalPower.MyHomsomPermission && PersonalPower.MyHomsomPermission.OrderPermission && PersonalPower.MyHomsomPermission.OrderPermission.IsEnableTrainRefund)
  } else if (key === '我的:火车票改期') {
    return (PersonalPower.MyHomsomPermission && PersonalPower.MyHomsomPermission.OrderPermission && PersonalPower.MyHomsomPermission.OrderPermission.IsEnableTrainChange)
  // ============================================================================================
  } else if (key === '我的:用车') {
    return (PersonalPower.MyHomsomPermission && PersonalPower.MyHomsomPermission.OrderPermission && PersonalPower.MyHomsomPermission.OrderPermission.IsEnableCar)
  } else if (key === '我的:用餐') {
    return (PersonalPower.MyHomsomPermission && PersonalPower.MyHomsomPermission.OrderPermission && PersonalPower.MyHomsomPermission.OrderPermission.IsEnableDining)
  } else if (key === '我的:汽车票') {
    return (PersonalPower.MyHomsomPermission && PersonalPower.MyHomsomPermission.OrderPermission && PersonalPower.MyHomsomPermission.OrderPermission.IsEnableBus)
  } else if (key === '我的:汽车票改签') {
    return (PersonalPower.MyHomsomPermission && PersonalPower.MyHomsomPermission.OrderPermission && PersonalPower.MyHomsomPermission.OrderPermission.IsEnableBusChange)
  } else if (key === '我的:汽车票退票') {
    return (PersonalPower.MyHomsomPermission && PersonalPower.MyHomsomPermission.OrderPermission && PersonalPower.MyHomsomPermission.OrderPermission.IsEnableBusRefund)
  } else if (key === '我的:我的审批') {
    return (PersonalPower.MyHomsomPermission && PersonalPower.MyHomsomPermission.VettingManagePermission && PersonalPower.MyHomsomPermission.VettingManagePermission.IsEnable)
  } else if (key === '我的:常旅客管理') {
    return (PersonalPower.MyHomsomPermission && PersonalPower.MyHomsomPermission.TravelerManagePermission && PersonalPower.MyHomsomPermission.TravelerManagePermission.IsEnable)
  } else if (key === '我的:常旅客管理:成本中心添加') {
    return (PersonalPower.MyHomsomPermission && PersonalPower.MyHomsomPermission.TravelerManagePermission && PersonalPower.MyHomsomPermission.TravelerManagePermission.IsAllowAddCostCenter)
  } else if (key === '我的:常旅客管理:成本中心编辑') {
    return (PersonalPower.MyHomsomPermission && PersonalPower.MyHomsomPermission.TravelerManagePermission && PersonalPower.MyHomsomPermission.TravelerManagePermission.IsEnableEditCostCenter)
  } else if (key === '我的:常旅客管理:部门编辑') {
    return (PersonalPower.MyHomsomPermission && PersonalPower.MyHomsomPermission.TravelerManagePermission && PersonalPower.MyHomsomPermission.TravelerManagePermission.IsEnableEditDepartment)
  } else if (key === '我的:常旅客管理:BU编辑') {
    return (PersonalPower.MyHomsomPermission && PersonalPower.MyHomsomPermission.TravelerManagePermission && PersonalPower.MyHomsomPermission.TravelerManagePermission.IsEnableEditBusinessUnit)
  } else if (key === '我的:常旅客管理:因公') {
    return (PersonalPower.MyHomsomPermission && PersonalPower.MyHomsomPermission.TravelerManagePermission && PersonalPower.MyHomsomPermission.TravelerManagePermission.IsEnableBusiness)
  } else if (key === '我的:常旅客管理:因公添加') {
    return (PersonalPower.MyHomsomPermission && PersonalPower.MyHomsomPermission.TravelerManagePermission && PersonalPower.MyHomsomPermission.TravelerManagePermission.IsEnableBusinessAdd)
  } else if (key === '我的:常旅客管理:因公非员工添加') {
    return (PersonalPower.MyHomsomPermission && PersonalPower.MyHomsomPermission.TravelerManagePermission && PersonalPower.MyHomsomPermission.TravelerManagePermission.IsEnableBusinessNupAdd)
  } else if (key === '我的:常旅客管理:因公编辑') {
    return (PersonalPower.MyHomsomPermission && PersonalPower.MyHomsomPermission.TravelerManagePermission && PersonalPower.MyHomsomPermission.TravelerManagePermission.IsEnableBusinessEdit)
  } else if (key === '我的:常旅客管理:因公姓名编辑') {
    return (PersonalPower.MyHomsomPermission && PersonalPower.MyHomsomPermission.TravelerManagePermission && PersonalPower.MyHomsomPermission.TravelerManagePermission.IsEnableBusinessTravelNameEdit)
  } else if (key === '我的:常旅客管理:因公导入') {
    return (PersonalPower.MyHomsomPermission && PersonalPower.MyHomsomPermission.TravelerManagePermission && PersonalPower.MyHomsomPermission.TravelerManagePermission.IsEnableBusinessImport)
  } else if (key === '我的:常旅客管理:自定义项添加') {
    return (PersonalPower.MyHomsomPermission && PersonalPower.MyHomsomPermission.TravelerManagePermission && PersonalPower.MyHomsomPermission.TravelerManagePermission.IsAllowAddCustomItem)
  } else if (key === '我的:常旅客管理:因私') {
    return (PersonalPower.MyHomsomPermission && PersonalPower.MyHomsomPermission.TravelerManagePermission && PersonalPower.MyHomsomPermission.TravelerManagePermission.IsEnablePrivate)
  } else if (key === '我的:常旅客管理:因私添加') {
    return (PersonalPower.MyHomsomPermission && PersonalPower.MyHomsomPermission.TravelerManagePermission && PersonalPower.MyHomsomPermission.TravelerManagePermission.IsEnablePrivateAdd)
  } else if (key === '我的:常用联系人管理') {
    return (PersonalPower.MyHomsomPermission && PersonalPower.MyHomsomPermission.ContactManagePermission && PersonalPower.MyHomsomPermission.ContactManagePermission.IsEnable)
  } else if (key === '我的:常用联系人管理:添加') {
    return (PersonalPower.MyHomsomPermission && PersonalPower.MyHomsomPermission.ContactManagePermission && PersonalPower.MyHomsomPermission.ContactManagePermission.IsEnableAdd)
  } else if (key === '我的:常用联系人管理:导入') {
    return (PersonalPower.MyHomsomPermission && PersonalPower.MyHomsomPermission.ContactManagePermission && PersonalPower.MyHomsomPermission.ContactManagePermission.IsEnableImport)
  } else if (key === '我的:我的工具') {
    return (PersonalPower.MyHomsomPermission && PersonalPower.MyHomsomPermission.ToolManagePermission && PersonalPower.MyHomsomPermission.ToolManagePermission.IsEnable)
  } else if (key === '我的:我的工具:发票') {
    return (PersonalPower.MyHomsomPermission && PersonalPower.MyHomsomPermission.ToolManagePermission && PersonalPower.MyHomsomPermission.ToolManagePermission.IsEnableInvoice)// ============================================================================================
  }
}
